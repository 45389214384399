<template>
    <div
        id="header"
        class="fixed top-0 w-full px-2 py-1 z-30 bg-white sm:px-4 shadow-xl"
    >
        <div class="flex items-center justify-between mx-auto">
            <!-- <img
                alt="company logo"
                class="w-auto h-11 sm"
                :src="`/logo/${userStore.logo}header-nobg.png`"
            /> -->
            <span
                class="flex items-center text-2xl pl-16 circular-bold"
                v-if="userStore.isB2C === true"
            >
                <span v-if="$route.name === 'my_cart'">
                    {{ $t('my_unpaid_deliveries') }}</span
                >
                <span v-else-if="$route.name === 'orders'">
                    {{ $t('my_orders') }}
                </span>

                <span v-else-if="$route.name === 'delivery_addresses'">
                    {{ $t('my_addresses') }}</span
                >
                <span v-else-if="$route.name === 'containers'">
                    {{ $t('my_containers') }}</span
                >
                <span v-else-if="$route.name === 'update_order'">
                    {{ $t('update_order_B2C') }}</span
                >
                <span v-else-if="$route.name === 'new_order'">
                    {{ $t('new_order_B2C') }}</span
                >

                <span v-else>
                    {{ $t(pageName) }}
                </span>
            </span>

            <!-- <span
                class="flex items-center text-2xl pl-16"
                v-else-if="$route.name === 'my_account'"
            >
                {{ $t('my_account') }}
            </span> -->
            <span v-else class="flex items-center text-2xl pl-16 circular-bold">
                {{ $t(pageName) }}

                <!-- :class="userStore.userUniverses.length > 1 ? 'mt-14' : ''" -->

                <span
                    v-if="
                        showUniverseSelect === true &&
                        universesOptions.length > 1
                    "
                    class="ml-6 mr-2"
                    style="width: 10em"
                >
                    <Multiselect
                        id="universe"
                        :object="true"
                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                        v-model="selectedUniverses"
                        :placeholder="$t('your_universes')"
                        :searchable="true"
                        mode="multiple"
                        :options="universesOptions"
                        label="name"
                        track-by="name"
                        @select="triggerUniverseFilter(false)"
                        @clear="triggerUniverseFilter(true)"
                    ></Multiselect>
                </span>
                <span
                    v-if="
                        showUniverseSelect === true &&
                        universesOptions.length > 1
                    "
                    class="flex items-center"
                >
                    <span
                        v-for="(univers, index) in selectedUniverses"
                        :key="index"
                    >
                        <!-- <div class="grid grid-cols-12 gap-2 items-center">
                        <div class="col-span-1"> -->
                        <div
                            class="themed-border border-2 rounded-full py-1 mr-1 flex items-center px-3 font-medium"
                        >
                            <span
                                class="overflow-x-auto whitespace-nowrap w-12 text-xs"
                            >
                                {{ univers.name }}
                            </span>
                            <button
                                type="button"
                                class="text-gray-400 bg-transparent hover:bg-transparent hover:text-gray-600 rounded text-sm p-1.5 ml-auto inline-flex items-center"
                                @click="removeFromSelectedUniverses(index)"
                            >
                                <svg
                                    class="w-3 h-3"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                            <!-- </div>
                        </div> -->
                        </div>
                    </span>
                </span>
            </span>
            <div class="flex items-center space-x-1">
                <ul class="hidden space-x-2 md:inline-flex">
                    <li
                        v-show="
                            userStore.current &&
                            userStore.current.type === 'client' &&
                            currentClient &&
                            currentClient.client_type === 'occasional' &&
                            currentClient.payment_type === 'web' &&
                            webPaymentAllowed === true
                        "
                    >
                        <icon-button
                            :title="$t('my_cart')"
                            icon="cart-shopping"
                            @click="myCart()"
                            :notifCount="countUnpaid !== null ? countUnpaid : 0"
                            :withNotificationBadge="true"
                        />
                    </li>
                    <!-- <li>
                        <icon-button
                            :title="$t('notifications')"
                            icon="bell"
                            :withNotificationBadge="true"
                        />
                    </li> -->
                    <li>
                        <icon-button
                            icon="user"
                            @click="myAccount()"
                            :title="$t('my_account')"
                        ></icon-button>
                    </li>
                    <li>
                        <icon-button
                            icon="right-from-bracket"
                            :title="$t('logout')"
                            @click="logout()"
                        ></icon-button>
                    </li>
                    <li>
                        <night-mode-switch />
                    </li>
                    <li>
                        <language-switcher />
                    </li>
                    <li
                        v-if="
                            userStore.current &&
                            userStore.current.type === 'superadmin'
                        "
                        class="theme-color"
                    >
                        <!-- (+user.id === 5 || +user.id === 4 || +user.id === 3) -->
                        <icon-button
                            icon="screwdriver-wrench"
                            :title="$t('biiim_admin_interface')"
                            @click="adminPanel()"
                        ></icon-button>
                    </li>
                </ul>
                <dropdown-menu
                    ref="smallHeaderDD"
                    class="md:hidden inline-flex"
                >
                    >

                    <template #buttonContent>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 8h16M4 16h16"
                            />
                        </svg>
                        <span class="sr-only">{{ $t('open_menu') }}</span>
                    </template>
                    <template #menu>
                        <ul
                            class="text-zinc-700 rounded hover:bg-zinc-100 block px-4 py-2 text-sm dd-menu"
                        >
                            <li
                                v-show="
                                    userStore.current.type === 'client' &&
                                    currentClient &&
                                    currentClient.client_type ===
                                        'occasional' &&
                                    currentClient.payment_type === 'web' &&
                                    webPaymentAllowed === true
                                "
                            >
                                <icon-button
                                    :title="$t('my_cart')"
                                    icon="cart-shopping"
                                    :notifCount="
                                        countUnpaid !== null ? countUnpaid : 0
                                    "
                                    @click="myCart()"
                                    :withNotificationBadge="true"
                                />
                            </li>
                            <!-- <li>
                                <icon-button
                                    :title="$t('notifications')"
                                    icon="bell"
                                    :withNotificationBadge="true"
                                />
                            </li> -->
                            <li>
                                <icon-button
                                    icon="user"
                                    @click="myAccount()"
                                    :title="$t('my_account')"
                                ></icon-button>
                            </li>
                            <li>
                                <icon-button
                                    icon="right-from-bracket"
                                    :title="$t('logout')"
                                    @click="logout()"
                                ></icon-button>
                            </li>

                            <li
                                class="text-zinc-700 hover:bg-zinc-100 block px-4 py-2 text-sm dd-menu"
                            >
                                <night-mode-switch
                                    role="menuitem"
                                    id="menu-item-night"
                                />
                            </li>
                            <li
                                class="text-zinc-700 hover:bg-zinc-100 block py-2 text-sm dd-menu"
                            >
                                <language-switcher
                                    role="menuitem"
                                    id="menu-item-lang"
                                    :inner="true"
                                />
                            </li>
                            <li
                                v-if="
                                    userStore.current &&
                                    userStore.current.type === 'superadmin'
                                "
                                color="theme-color"
                            >
                                <icon-button
                                    icon="screwdriver-wrench"
                                    :title="$t('biiim_admin_interface')"
                                    @click="adminPanel()"
                                ></icon-button>
                            </li>
                        </ul>
                    </template>
                </dropdown-menu>
            </div>
        </div>
    </div>
</template>
<script>
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

import DropdownMenu from '@/components/atoms/DropdownMenu.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useOrderStore } from '@/stores/orderStore'
import { useUniverseStore } from '@/stores/universeStore'
import LanguageSwitcher from './elements/LanguageSwitcher.vue'
import NightModeSwitch from './elements/NightModeSwitch.vue'

export default {
    name: 'MainHeader',
    components: {
        IconButton,
        NightModeSwitch,
        LanguageSwitcher,
        DropdownMenu,
        Multiselect,
    },
    inject: ['eventBus'],
    mounted() {
        if (this.$route.name) {
            this.pageName = this.$route.name
        }
        if (this.userStore.isB2C === true) {
            this.fetchOne(this.userStore.current.idClient).then((res) => {
                if (this.webPaymentAllowed === true) {
                    this.searchUnpaidOrdersByClient({
                        clientId: this.userStore.current.idClient,
                    })
                }
            })
        } else {
            if (
                this.userStore.current &&
                (this.userStore.current.type === 'admin' ||
                    this.userStore.current.type === 'superadmin')
            ) {
                const universeSearch = {
                    sortDirection: 'ASC',
                    sortField: 'Name',
                    perPage: 10000,
                    page: 1,
                    query: [],
                    filter: [],
                }
                const vm = this
                this.searchUniverses(universeSearch).then((res) => {
                    res.data.Records.forEach((universe) => {
                        const name = vm.$t(universe.Name)
                        this.universesOptions.push({
                            name,
                            value: universe.Id,
                        })
                    })
                })
            } else {
                this.universesOptions = this.userStore.userUniverses
            }
            if (
                this.$route.meta.showUniverseSearch &&
                this.$route.meta.showUniverseSearch === true
            ) {
                this.showUniverseSelect = true
                // this.triggerUniverseFilter(false)
            } else {
                this.showUniverseSelect = false
                // faire ça si on veut reset les universes à chaque changement de page qui ne comporte pas le choix dans le header
                // mais si de la liste on vaa sur create et que çaa enlève, quand on revient alors plus de filtre

                //  this.selectedUniverses = []
            }
        }
    },
    watch: {
        $route() {
            if (this.$route.name) {
                this.pageName = this.$route.name
            }
            if (this.userStore.isB2C === true) {
                this.fetchOne(this.userStore.current.idClient).then((res) => {
                    if (this.webPaymentAllowed === true) {
                        this.searchUnpaidOrdersByClient({
                            clientId: res.data.Id,
                        })
                    }
                })
            }

            if (
                this.userStore.isB2C === false &&
                this.$route.meta.showUniverseSearch &&
                this.$route.meta.showUniverseSearch === true
            ) {
                this.showUniverseSelect = true
                this.triggerUniverseFilter(false)
            } else {
                this.showUniverseSelect = false
                // faire ça si on veut reset les universes à chaque changement de page qui ne comporte pas le choix dans le header
                // mais si de la liste on vaa sur create et que çaa enlève, quand on revient alors plus de filtre

                // this.selectedUniverses = []
            }
        },
    },
    updated() {
        if (
            this.userStore.isB2C === false &&
            this.$route.meta.showUniverseSearch &&
            this.$route.meta.showUniverseSearch === true
        ) {
            this.showUniverseSelect = true
        } else {
            // faire ça si on veut reset les universes à chaque changement de page qui ne comporte pas le choix dans le header
            // mais si de la liste on vaa sur create et que çaa enlève, quand on revient alors plus de filtre

            // this.selectedUniverses = []

            this.showUniverseSelect = false
        }
    },
    data() {
        return {
            langs: ['En', 'Fr'],
            open: false,
            pageName: '',
            showUniverseSelect: false,
            // selectedUniverses: [],
            // universesOptions: [],
        }
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapState(useClientStore, { currentClient: 'current' }),
        ...mapState(useOrderStore, ['countUnpaid']),
        ...mapWritableState(useUniverseStore, [
            'universesOptions',
            'selectedUniverses',
        ]),
        webPaymentAllowed() {
            return checkAccess(this.userStore, actionsAccess.orders.webPayment)
        },
    },
    methods: {
        ...mapActions(useOrderStore, ['searchUnpaidOrdersByClient']),
        ...mapActions(useClientStore, ['fetchOne']),
        ...mapActions(useUniverseStore, { searchUniverses: 'search' }),
        triggerUniverseFilter(param) {
            // const busParams = {
            //     main: param,
            //     selected: this.selectedUniverses,
            //     universesOptions: this.universesOptions,
            // }

            this.eventBus.emit('trigger-universe-search', param)
        },
        removeFromSelectedUniverses(index) {
            this.selectedUniverses.splice(index, 1)
            // const busParams = {
            //     main: index,
            //     selected: this.selectedUniverses,
            //     universesOptions: this.universesOptions,
            // }
            this.eventBus.emit('remove-from-selected-universe', index)

            // this.searchCustomUniverses()
        },
        toggle() {
            this.open = !this.open
        },
        logout() {

            this.userStore.$patch({
                current: null,
                isLoggedIn: false,
                isB2C: null,
            })
            this.$cookies.keys().forEach((cookie) => {
                if (
                    cookie !== 'logo' &&
                    cookie !== 'nightMode' &&
                    cookie !== 'locale'
                ) {
                    this.$cookies.remove(cookie)
                }
            })
            this.userStore.logout()
        },
        adminPanel() {
            this.$router.push({ name: 'backoffice' })
        },
        myAccount() {
            this.userStore.fetchOne(this.userStore.current.id)

            this.$router.push({
                name: 'my_account',
                params: { id: this.userStore.current.id },
            })
        },

        myCart() {
            this.$router.push({ name: 'my_cart' })
        },
        changeLocale(newLang) {
            this.$i18n.locale = newLang
            this.$root.$i18n.locale = newLang
            this.userStore.$patch({ locale: newLang })

            this.toggle()
        },
        action(item) {
            if (item === 'logout') this.logout()
        },
    },
    unmounted() {
        this.eventBus.all.clear()
    },
}
</script>

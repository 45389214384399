<template>
    <main
        class="px-4 pt-2 rounded-md h-full"
        style="width: 96vw"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error
                v-if="fetchError"
                :dataType="'payments'"
                :multiTranslation="userStore.isB2C === true ? 2 : 1"
            />
            <div v-else>
                <!-- :class="userStore.userUniverses.length > 1 ? 'mt-14' : ''" -->
                <alert-info
                    v-if="refundErrors"
                    class="bg-red-100 text-red-700"
                    :color="'red'"
                    :canClose="true"
                    @close="refundErrors = null"
                >
                    <template #body>
                        <span
                            v-for="(err, errIndex) in refundErrors"
                            :key="errIndex"
                        >
                            <p class="text-base text-red-700'">
                                {{ `${$t(err.type)} ${$t(err.msg)} ${err.id}` }}
                            </p>
                        </span>
                    </template>
                </alert-info>

                <filtrable-table
                    id="paymentsList"
                    :class="searchingCursor === true ? 'cursor-progress' : ''"
                    :items="payments"
                    :columns="columns"
                    :storeToSort="'payments'"
                    :page="page"
                    :count="count"
                    :loading="loading"
                    :multiActions="[]"
                    @perPageChanged="changePerPage($event)"
                    @updateSort="displaySearchResults($event)"
                    :perPage="perPage"
                    :sortDirection="sortDirection"
                    :sortableFields="sortableFields"
                    :totalPages="totalPages"
                    :searchQuery="searchQuery"
                    :refresh="refresh"
                    @changeSearch="changeSearch()"
                    :itemActions="itemActions"
                    :resetCheckedItems="resetCheckedItems"
                    :canAddNew="false"
                    @pageChanged="setPage($event)"
                    @searched="debounceSearch"
                    @sortDirectionChanged="updateSortDirection($event)"
                    @selected="upFilter($event)"
                    @create="$emit('create')"
                    @clear="clearFilter($event)"
                    @refund="showRefundPayment($event)"
                >
                    <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
                </filtrable-table>
                <modal
                    v-if="showConfirmModal"
                    :headerText="confirmModalHeader"
                    :buttonText="'confirm'"
                    id="deletemodal"
                    :mxWidth="'max-w-2xl'"
                    :buttonAlign="'justify-center'"
                    @closeModal="actionConfirmation(false)"
                    @sendFromModal="actionConfirmation(true)"
                >
                    <template #body>
                        <div class="mx-8">
                            <div class="flex justify-center font-semibold mt-4">
                                {{ $t(confirmModalText) }}
                            </div>

                            <div
                                v-for="(
                                    toConfirm, iToConfirm
                                ) in elementsToConfirm"
                                :key="iToConfirm"
                                class="flex justify-center mt-2"
                            >
                                <ul>
                                    <li
                                        class="flex text-sm items-center whitespace-nowrap"
                                    >
                                        <fa-icon
                                            icon="circle"
                                            class="fa-2xs mr-2"
                                        />
                                        <span
                                            v-if="toConfirm.Client"
                                            class="mr-1"
                                        >
                                            {{ toConfirm.Client }}
                                        </span>
                                        <span v-if="toConfirm.Ref">
                                            {{
                                                ' - ' +
                                                $t('order_ref') +
                                                ': ' +
                                                toConfirm.Ref
                                            }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div
                                class="flex text-xs italic items-center error-msg justify-center mt-4"
                            >
                                <fa-icon
                                    :icon="'triangle-exclamation'"
                                    class="mr-2 fa-md"
                                ></fa-icon>
                                {{ $t(confirmModalText2) }}
                            </div>
                        </div></template
                    ></modal
                >
                <modal
                    v-if="showRefundPaymentModal"
                    :headerText="'refund_modal_header'"
                    :buttonText="'refund_button'"
                    id="refundModal"
                    :mxWidth="'w-2/5'"
                    :buttonAlign="'justify-center'"
                    @closeModal="showRefundPayment(null)"
                    @sendFromModal="refund()"
                >
                    <template #body>
                        <div class="mx-8 mt-4" :key="refreshModal">
                            <!-- <div class="flex justify-center font-semibold mt-4">
                                {{ $t('refund_text') }}
                            </div> -->
                            <div class="mb-6">
                                <label for="amountToRefundCom"
                                    >{{ $t('refund_comment') }} *</label
                                >
                                <input
                                    :id="`amountToRefundCom`"
                                    type="textarea"
                                    v-model="paymentToRefund.refund_com"
                                    class="border rounded px-3 py-2 w-full"
                                />
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.paymentToRefund
                                        .refundComment.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="mb-6">
                                <label for="reason"
                                    >{{ $t('refund_reason') }} *</label
                                >
                                <Multiselect
                                    id="reason"
                                    :object="true"
                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                    v-model="refundReason"
                                    :searchable="false"
                                    :can-clear="false"
                                    :options="reasonOptions"
                                    label="name"
                                    track-by="name"
                                ></Multiselect>
                            </div>
                            <label for="amountToRefund"
                                >{{ $t('amount_to_refund') }} *</label
                            >
                            <input
                                :id="`amountToRefund`"
                                type="number"
                                step="0.01"
                                v-model="amountToRefund"
                                class="border rounded px-3 py-2 w-full"
                            />
                            <div
                                v-if="!amountIsOk"
                                class="text-xs italic mt-1 mb-2"
                            >
                                <div class="error-msg">
                                    {{ $t('refund_higher_than_amount') }}
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.paymentToRefund
                                    .refundedAmount.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="items-center grid grid-cols-4 mt-6">
                                <span
                                    v-for="(
                                        toRefund, iToRefund
                                    ) in paymentToRefund.ocs"
                                    :key="iToRefund"
                                    class="col-span-4"
                                >
                                    <div
                                        class="flex items-center text-xs justify-center mt-4"
                                    >
                                        <input
                                            v-if="
                                                toRefund.status === 'paid' ||
                                                toRefund.status ===
                                                    'partial_refund' ||
                                                (toRefund.status ===
                                                    'refunded' &&
                                                    paymentToRefund.refundable_amount >
                                                        0)
                                            "
                                            :id="`set-toRefund-checkbox-${toRefund.id}`"
                                            type="checkbox"
                                            checked
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300 mr-1"
                                            @click="
                                                setOrderToRefund(toRefund.id)
                                            "
                                        />
                                        <span
                                            v-for="(
                                                pack, indPack
                                            ) in toRefund.packs"
                                            :key="indPack"
                                            class="my-2"
                                        >
                                            <span class="mr-1">
                                                {{
                                                    ` ${pack.quantity} ${$t(
                                                        pack.container
                                                    )} 
                                                    PRIX
                                                    
                                                    ${
                                                        indPack !==
                                                        toRefund.packs.length -
                                                            1
                                                            ? ' • '
                                                            : ''
                                                    } `
                                                }}
                                            </span>
                                        </span>
                                    </div>
                                    <div
                                        v-if="toRefund.driver !== null"
                                        class="flex items-center error-msg text-xs justify-center italic my-2"
                                    >
                                        {{
                                            `${$t('driver')} ${toRefund.driver}`
                                        }}
                                    </div>

                                    <div
                                        v-else
                                        class="flex items-center text-xs justify-center italic my-2"
                                    >
                                        {{ $tc('not_affected', 2) }}
                                    </div>

                                    <div
                                        class="flex items-center text-xs justify-center theme-color font-semibold"
                                    >
                                        {{ $t(toRefund.status) }}
                                    </div>
                                    <div
                                        class="items-center text-xs justify-center grid grid-cols-2 mb-2"
                                    >
                                        <span class="col-span-1">
                                            {{ toRefund.pickUp.date }}<br />
                                            {{ toRefund.pickUp.address }}<br />
                                            {{ toRefund.pickUp.zipCity }}
                                        </span>
                                        <span class="col-span-1">
                                            {{ toRefund.delivery.date }}<br />
                                            {{ toRefund.delivery.address
                                            }}<br />
                                            {{ toRefund.delivery.zipCity }}
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div
                                v-if="!selectedOcsOk"
                                class="text-xs italic mt-1 mb-2"
                            >
                                <div class="error-msg">
                                    {{ $t('refund_must_select_oc') }}
                                </div>
                            </div>
                            <div class="flex justify-center mt-4">
                                <div
                                    class="flex text-sm font-semibold items-center error-msg justify-center mt-4"
                                >
                                    <fa-icon
                                        :icon="'triangle-exclamation'"
                                        class="mr-2 fa-md"
                                    ></fa-icon>
                                    {{ $t('refund_driver_info_text') }}
                                </div>
                            </div>
                        </div></template
                    ></modal
                >
            </div>
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import AlertInfo from '@/components/atoms/AlertInfo.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useDriverStore } from '@/stores/driverStore'
import { usePaymentStore } from '@/stores/paymentStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'RefundsList',
    mixins: [filterList],
    inject: ['eventBus'],
    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
        Multiselect,
        AlertInfo,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            ocsIdsToRefund: [],
            refundErrors: null,
            refreshModal: 0,
            selectedOcsOk: true,
            confirmAction: null,
            paymentToRefund: null,
            showRefundPaymentModal: false,
            showConfirmModal: false,
            amountIsOk: true,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModaltext: 'confirm_modal_text',
            confirmModaltext2: 'confirm_modal_text',
            driverOptions: [],
            dateFilter: [],
            resetCheckedItems: false,
            searchByName: true,
            refresh: 0,
            searchQuery: '',
            amountToRefund: 0,
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            refundsInterval: null,
            sortableFields: [
                {
                    name: this.$t('client'),
                    value:
                        this.searchByName === true
                            ? 'Client.Name'
                            : 'OrderClient.Reference',
                },
                // { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('reference'), value: 'Reference' },
            ],
            refundReason: {
                name: this.$t('requested_by_customer'),
                value: 'requested_by_customer',
            },
            reasonOptions: [
                {
                    name: this.$t('requested_by_customer'),
                    value: 'requested_by_customer',
                },
                { name: this.$t('duplicate_payment'), value: 'duplicate' },
                { name: this.$t('fraudulent'), value: 'fraudulent' },
            ],
        }
    },

    mounted() {
        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        window.scrollTo(0, 0)
        clearInterval(this.refundsInterval)
        this.searchingCursor = true
        this.selectedUniverses = []
        this.payments = null
        this.count = null
        this.totalPages = null
        this.filter = []
        this.searchContainers({
            query: [],
            filter: [],
            sortDirection: 'ASC',
            sortField: 'CreationDate',
            perPage: 100000,
            page: 1,
        }).then(() => {
            this.searchDrivers({
                query: [],
                filter: [],
                sortDirection: 'ASC',
                sortField: 'CreationDate',
                perPage: 100000,
                page: 1,
            }).then(() => {
                const isB2CInterval = setInterval(() => {
                    if (this.userStore.isB2C !== null) {
                        clearInterval(isB2CInterval)
                        const filteredList = this.$cookies.get('refundsFiltered')
                        if (filteredList) {
                            // this.search.push(this.$cookies.get('query'))
                            this.search = filteredList.query
                            // this.searchQuery = filteredList.query[0]
                            //     ? filteredList.query[0].term
                            //     : ''
                            this.sortDirection = filteredList.sortDirection
                            this.filter = filteredList.filter
                            this.sortField = filteredList.sortField
                            this.perPage = filteredList.perPage
                            this.page = filteredList.page

                            this.columns.forEach((col, index) => {
                                if (
                                    col.type === 'select' ||
                                    col.type === 'icon-select'
                                ) {
                                    if (!col.multi) {
                                        this.setRealSelected(col.dbField, index)
                                    } else {
                                        this.setRealSelected(
                                            col.dbField,
                                            index,
                                            true
                                        )
                                    }
                                }
                            })

                            this.setRealSearch(this.search)
                        }
                        // tous filtres de refund + remettre la colonne choix du statut

                        const universesInterval = setInterval(() => {
                            if (this.universesOptions.length > 0) {
                                clearInterval(universesInterval)
                                const universeFilter = {
                                    dbField: 'IdUniverse',
                                    value:
                                        this.selectedUniverses.length > 0
                                            ? this.selectedUniverses
                                            : this.universesOptions,
                                }
                                if (
                                    !this.filter.find(
                                        (f) => f.dbField === 'IdUniverse'
                                    )
                                ) {
                                    this.filter.push(universeFilter)
                                }
                                this.setResults()
                            }
                        }, 10)
                    }
                }, 20)
            })
        })
    },

    unmounted() {
        clearInterval(this.refundsInterval)
        this.paymentStore.$patch({
            all: null,
            count: 0,
            totalPages: 0,
        })
        this.eventBus.all.clear()
    },

    computed: {
        refundsActionsAccess() {
            return actionsAccess.orders.refunds
        },
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
        columns() {
            return [
                {
                    name: 'client',
                    filtrable: true,
                    dbField: 'Client.Name',
                    // isLink: checkAccess(
                    //     this.userStore,
                    //     this.refundsActionsAccess.update
                    // ),
                    // routeName: 'update_order',
                    searchIcon: 'user',
                    type: 'search',
                    sortable: false,

                    // subInfo: true,
                },
                {
                    name: 'payment_ref',
                    filtrable: true,
                    dbField: 'Id',
                    // isLink: checkAccess(
                    //     this.userStore,
                    //     this.refundsActionsAccess.update
                    // ),
                    // routeName: 'update_order',

                    type: 'search',
                    searchIcon: 'hashtag',

                    sortable: false,
                },
                // {
                //     name: 'reference',
                //     filtrable: true,
                //     dbField: 'Reference',
                //     type: 'search',
                //     sortable: true,
                //     hide: this.userStore.isB2C
                //         ? this.userStore.isB2C === false
                //         : this.$cookies.get('is_B2C') === 'false',
                // },
                {
                    name: 'status',
                    translate: true,
                    // type: 'icon-select',
                    dbField: 'Status',

                    filtrable: true,
                    sortable: false,
                    canSearch: true,
                    type: 'select',

                    options: [
                        {
                            name: 'full_ref',
                            value: 'full_refund',
                            dbField: 'Status',
                        },
                        {
                            name: 'paid',
                            value: 'paid',
                            dbField: 'Status',
                        },
                        {
                            name: 'partial_refund',
                            value: 'partial_refund',
                            dbField: 'Status',
                        },
                        {
                            name: 'waiting_for_payment',
                            value: 'waiting',
                            dbField: 'Status',
                        },
                    ],
                },

                {
                    name: 'provider',

                    // type: 'icon-select',
                    dbField: 'Provider',

                    filtrable: true,
                    sortable: false,
                    canSearch: true,
                    type: 'select',

                    options: [
                        {
                            name: 'Stripe',
                            value: 'stripe',
                            dbField: 'Provider',
                        },
                    ],
                },

                {
                    name: 'ocs',
                    filtrable: false,
                    sortable: false,
                    dbField: 'OrderClients',
                },
                {
                    name: 'amount',
                    filtrable: false,
                    sortable: false,
                    dbField: 'Amount',
                },
                {
                    name: 'refundable_amount',
                    filtrable: false,
                    sortable: false,
                    dbField: 'RefundableAmount',
                },
                {
                    name: 'refunded_amount',
                    filtrable: false,
                    sortable: false,
                    dbField: 'RefundedAmount',
                },
                {
                    name: 'refund_com',
                    filtrable: false,

                    dbField: 'RefundComment',

                    sortable: false,
                },
                {
                    name: 'last_modified_by',
                    filtrable: false,

                    dbField: 'lastModifBy',

                    sortable: false,
                },
                // {
                //     name: 'refund_date',
                //     filtrable: false,

                //     dbField: 'RefundDate',

                //     sortable: false,
                // },
                // {
                //     name: 'additional_info',
                //     filtrable: false,
                //     sortable: false,
                //     packages: true,
                //     hide: this.userStore.isB2C
                //         ? this.userStore.isB2C
                //         : this.$cookies.get('is_B2C') === 'true',
                // },
                // {
                //     name: 'packages',
                //     filtrable: false,
                //     sortable: false,
                //     packages: true,
                //     hide: this.userStore.isB2C
                //         ? this.userStore.isB2C === false
                //         : this.$cookies.get('is_B2C') === 'false',
                // },
                {
                    name: 'modification_date',
                    sortable: false,
                    dbField: 'ModificationDate',
                },
            ]
        },

        multiActions() {
            const actions = []
            // if (checkAccess(this.userStore, this.refundsActionsAccess.delete)) {
            //     actions.push({
            //         name: 'delete_selection',
            //         action: 'deleteAll',
            //         icon: 'trash-can',
            //     })
            // }
            if (this.userStore.isB2C === false) {
                // multiActions.push({
                //     name: 'generate_invoice',
                //     action: 'invoice',
                //     icon: 'file-invoice-dollar',
                // })
            }
            return actions
        },
        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.refundsActionsAccess.create)) {
                actions.push({
                    name: 'validate_refund',
                    action: 'refund',
                    icon: 'hand-holding-dollar',
                    hide: false,
                })
            }

            return actions
        },
        ...mapWritableState(usePaymentStore, {
            payments: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useUserStore, usePaymentStore),
    },
    validations() {
        return {
            paymentToRefund: {
                refundComment: {
                    required: requiredIf(function (abc) {
                        return this.paymentToRefund !== null
                    }),
                },

                // Reference: { required },
                refundedAmount: {
                    required: requiredIf(function (abc) {
                        return this.paymentToRefund !== null
                    }),
                    // plus petit que total
                },
            },
        }
    },
    methods: {
        ...mapActions(useClientStore, ['searchContainers']),
        ...mapActions(useDriverStore, { searchDrivers: 'search' }),

        showRefundPayment(payment) {
            if (payment === null) {
                this.setResults()
                this.showRefundPaymentModal = false
                this.amountIsOk = true
                this.selectedOcsOk = true
                this.v$.paymentToRefund.$reset()
                this.paymentToRefund = null
                this.amountToRefund = 0
                this.ocsIdsToRefund = []
            } else {
                clearInterval(this.refundsInterval)

                this.paymentToRefund = this.payments.find(
                    (pay) => pay.id === payment
                )
                this.amountToRefund = this.paymentToRefund.refundable_amount // montant des co cochés
                this.showRefundPaymentModal = true
                this.paymentToRefund.ocs.forEach((oc) => {
                    if (
                        oc.status === 'paid' ||
                        oc.status === 'partial_refund'
                    ) {
                        this.setOrderToRefund(oc.id)
                    }
                })
            }
            this.refreshModal += 1
        },
        setOrderToRefund(ocId) {
            if (this.ocsIdsToRefund.includes(ocId)) {
                this.ocsIdsToRefund.splice(this.ocsIdsToRefund.indexOf(ocId), 1)
            } else {
                this.ocsIdsToRefund.push(ocId)
            }
        },
        async refund() {
            this.amountIsOk = true
            this.selectedOcsOk = true
            this.v$.paymentToRefund.$reset()

            // ajouter reason duplicate, fraudulent, and requested_by_customer
            this.paymentToRefund.ModificationUserId = this.userStore.current.id
            this.paymentToRefund.refundedAmount = this.amountToRefund * 100
            this.paymentToRefund.refundComment = this.paymentToRefund.refund_com
            this.paymentToRefund.reason = this.refundReason.value
            this.paymentToRefund.ocsIdsToRefund = this.ocsIdsToRefund

            this.v$.paymentToRefund.$reset()

            this.amountIsOk =
                this.amountToRefund !== '' &&
                this.amountToRefund <= this.paymentToRefund.refundable_amount
            this.selectedOcsOk = this.ocsIdsToRefund.length > 0
            const isValid = await this.v$.paymentToRefund.$validate()
            if (isValid && this.amountIsOk && this.selectedOcsOk) {
                this.paymentToRefund.refundType =
                    +this.paymentToRefund.amount === +this.amountToRefund ||
                    this.paymentToRefund.refundable_amount -
                        this.amountToRefund ===
                        0
                        ? 'full_refund'
                        : 'partial_refund'

                this.paymentStore
                    .createRefund(this.paymentToRefund)
                    .then((res) => {
                        this.showRefundPayment(null)
                        this.setResults()
                        this.$toast.success(this.$t('refund_success'))
                    })
                    .catch((err) => {
                        this.$toast.error(this.$t('refund_error'))
                        this.showRefundPayment(null)

                        this.refundErrors = err.response.data.entity
                    })
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
        searchCustomUniverses(clear = false) {
            clearInterval(this.refundsInterval)
            let filter = null
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.refundsInterval)
            this.searchCustomUniverses()
        },

        changeSearch() {
            this.searchByName = !this.searchByName
            const searchFilters = JSON.parse(JSON.stringify(this.search))
            searchFilters.forEach((filter, index) => {
                if (filter.dbField === 'Client.Name') {
                    this.search[index].dbField = 'OrderClient.Reference'
                } else if (filter.dbField === 'OrderClient.Reference') {
                    this.search[index].dbField = 'Client.Name'
                }
            })
            this.setResults()
        },

        upFilter(event) {
            clearInterval(this.refundsInterval)
            this.updateFilter(event)
        },

        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.searchingCursor = confirmDelete
            this.showConfirmModal = false
        },

        setPage(isNext) {
            clearInterval(this.refundsInterval)
            this.$cookies.remove('refundsFiltered')

            this.searchingCursor = true
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.refundsInterval)
            this.$cookies.remove('refundsFiltered')

            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page

            // const query = search.query

            this.$cookies.set('refundsFiltered', search)
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.paymentStore.search(search)
            this.searchingCursor = false
            this.refundsInterval = setInterval(() => {
                this.paymentStore.search(search)
            }, 20000)

            this.refresh += 1
        },
        changePerPage(perPage) {
            clearInterval(this.refundsInterval)

            this.$cookies.remove('refundsFiltered')
            this.searchingCursor = true
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            clearInterval(this.refundsInterval)

            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }

            this.displaySearchResults(search)
        },
    },
}
</script>

import OrdersWrapper from '../views/orders/Wrapper.vue'
import CreateOrUpdate from '../views/orders/CreateOrUpdate.vue'
import RecurrentOrders from '../views/orders/RecurrentOrders.vue'
import PaymentResult from '../views/orders/PaymentResult.vue'

import UpdateStep from '../views/orders/UpdateStep.vue'
import OrderStepCorrection from '../views/orders/OrderStepCorrection.vue'
import ContainersList from '../views/orders/ContainersList.vue'
// import ImportOrders from '../views/orders/ImportOrders.vue'
import ImportedOrders from '../views/orders/ImportedOrders.vue'
import UnpaidOrders from '../views/orders/UnpaidOrders.vue'
import Orders from '../views/orders/BecycleList.vue'
import PackageTracking from '../views/orders/PackageTracking.vue'
import Refunds from '../views/orders/RefundsList.vue'

const orders = [
    {
        path: '/my-cart',
        name: 'my_cart',
        component: Orders,
        meta: {
            authorisations: ['FULL_ACCESS', 'ORDERS_FULL', 'ORDERS_LIST'],
        },
    },
    {
        path: '/orders',
        name: 'orders', // 'orders' if old list
        component: OrdersWrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'ORDERS_FULL', 'ORDERS_LIST'],
            showUniverseSearch: true,
        },
        children: [
            {
                path: 'payment-result/:session_id',
                name: 'payment_result',
                component: PaymentResult,
                meta: {
                    authorisations: [],
                    showUniverseSearch: false,
                },
            },

            {
                path: 'unpaid',
                name: 'unpaid_orders',
                component: UnpaidOrders,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'UNPAID_FULL',
                        'UNPAID_LIST',
                    ],
                    showUniverseSearch: true,
                },
            },
            {
                path: 'refunds',
                name: 'refunds',
                component: Refunds,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'REFUNDS_FULL',
                        'REFUNDS_LIST',
                    ],
                    showUniverseSearch: true,
                },
            },
            {
                path: 'new',
                name: 'new_order',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'ORDERS_FULL',
                        'ORDERS_CREATE',
                        'RECURRENTS_FULL',
                        'RECURRENTS_CREATE',
                    ],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'recurrents',
                name: 'recurrent_orders',
                component: RecurrentOrders,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'RECURRENTS_FULL',
                        'RECURRENTS_LIST',
                    ],
                    showUniverseSearch: true,
                },
                children: [
                    {
                        path: 'update/:id',
                        name: 'update_recurrent',
                        component: CreateOrUpdate,
                        meta: {
                            authorisations: [
                                'FULL_ACCESS',
                                'RECURRENTS_FULL',
                                'RECURRENTS_UPDATE',
                            ],
                            showUniverseSearch: false,
                        },
                    },
                ],
            },
            {
                path: ':delivery/track/packages/:orderClient', // :reference',
                name: 'packages_tracking',
                component: PackageTracking,
                meta: {
                    map: true,
                    authorisations: [
                        'FULL_ACCESS',
                        'ORDERS_FULL',
                        'ORDERS_TRACKINGS',
                    ],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'track/package/:packageRef', // :reference',
                name: 'one_package_tracking',
                component: PackageTracking,
                meta: {
                    map: true,
                    authorisations: [
                        'FULL_ACCESS',
                        'ORDERS_FULL',
                        'ORDERS_TRACKINGS',
                    ],
                    showUniverseSearch: false,
                },
            },
            {
                path: 'containers',
                name: 'containers',
                component: ContainersList,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'PACKAGES_FULL',
                        'PACKAGES_LIST',
                    ],
                },
                children: [
                    {
                        path: 'new',
                        name: 'new_container',
                        meta: {
                            authorisations: [
                                'FULL_ACCESS',
                                'PACKAGES_FULL',
                                'PACKAGES_CREATE',
                            ],
                            showUniverseSearch: false,

                            // userStore.userRoles.includes('BIIIM_ADMIN') ||
                            // userStore.userRoles.includes('AREA_CREATE'),
                        },
                    },
                    {
                        path: 'update/:id',
                        name: 'update_container',
                        authorisations: [
                            'FULL_ACCESS',
                            'PACKAGES_FULL',
                            'PACKAGES_UPDATE',
                        ],
                        showUniverseSearch: false,
                    },
                ],
            },
            {
                path: 'update/:id',
                name: 'update_order',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'ORDERS_FULL',
                        'ORDERS_UPDATE',
                        // 'RECURRENTS_FULL',
                        // 'RECURRENTS_UPDATE'
                    ],
                    showUniverseSearch: false,
                },
            },
            {
                path: ':order_ref/update-step/:id',
                name: 'update_step',
                component: UpdateStep,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'ORDERS_FULL',
                        'ORDERS_UPDATE',
                    ],
                    showUniverseSearch: false,
                },
            },
            // {
            //     path: 'import',
            //     name: 'import_orders',
            //     component: ImportOrders,
            // },
            {
                path: 'imported',
                name: 'imported_orders',
                component: ImportedOrders,
                meta: {
                    authorisations: ['FULL_ACCESS', 'ORDERS-IMPORT_LIST'],
                    showUniverseSearch: true,
                },
            },
            {
                path: 'order-step-correction',
                name: 'order_step_correction',
                component: OrderStepCorrection,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'ORDERS_FULL',
                        'ORDERS_UPDATE',
                    ],
                    showUniverseSearch: true,
                },
            },
        ],
    },
]

export default orders

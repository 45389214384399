<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <div v-if="clientId && currentClient && userStore.isB2C === false">
                <back-to
                    @back="back()"
                    :text="
                        $t('delivery_addresses') +
                        ' ' +
                        $t('for') +
                        ' ' +
                        currentClient.company_name
                    "
                />
            </div>
            <!-- <div v-if="clientId && currentClient && isB2C === false">
             
                <div class="mt-20 flex mb-3 items-center">
                    <div class="text-3xl ml-2">
                        {{
                            $t('delivery_addresses') +
                            ' ' +
                            $t('for') +
                            ' ' +
                            currentClient.company_name
                        }}
                    </div>
                </div>
            </div> -->
            <fetch-data-error
                v-if="fetchError"
                :dataType="'delivery_addresses'"
            />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="addressesList"
                :items="addresses"
                :columns="columns"
                :storeToSort="'clients'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearchCustom"
                @deleteAll="deleteAll($event)"
                @sortDirectionChanged="updateSortDirectionCustom($event)"
                @selected="upFilter($event)"
                @create="$emit('createAddress', $route.params.id)"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @delete="deleteOne($event)"
            >
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import filterList from '@/mixins/filterList'
import { mapState, mapStores, mapWritableState } from 'pinia'

import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import BackTo from '@/components/elements/BackTo.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'

export default {
    name: 'AddressList',
    mixins: [filterList],
    inject: ['eventBus'],
    components: {
        FiltrableTable,
        FetchDataError,
        BackTo,
    },
    data() {
        return {
            refresh: 0,
            clientUniverseFilter: [],
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            addressesInterval: null,
            filter: [],

            sortableFields: [
                // { name: this.$t('client'), value: 'Client' },
                { name: this.$t('address_name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('city'), value: 'City' },
                { name: this.$t('address'), value: 'Address1' },
                { name: this.$t('postal_code'), value: 'PostalCode' },
            ],
        }
    },
    watch: {
        addresses() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
        $route(to, from) {
            if (
                !this.$route.params.id &&
                this.userStore.isB2C === false &&
                from.params.id &&
                from.name === 'client_delivery_addresses'
            ) {
                clearInterval(this.addressesInterval)
                this.$cookies.remove('filtered_addresses')

                this.currentClient = null
                this.searchQuery = ''
                this.search = []
                this.sortDirection = 'DESC'
                this.sortField = 'CreationDate'
                this.perPage = 10
                this.page = 1
                this.filter = []

                this.clientUniverseFilter = []
                const universesInterval = setInterval(() => {
                    if (this.universesOptions.length > 0) {
                        clearInterval(universesInterval)
                        if (this.userStore.isB2C === false) {
                            if (
                                !this.filter.find(
                                    (f) => f.dbField === 'IdUniverse'
                                )
                            ) {
                                const universeFilter = {
                                    dbField: 'IdUniverse',
                                    value:
                                        this.selectedUniverses.length > 0
                                            ? this.selectedUniverses
                                            : this.universesOptions,
                                }
                                this.clientUniverseFilter.push(universeFilter)
                            }
                        }

                        const clientsPayload = {
                            query: [],
                            filter: this.clientUniverseFilter,
                            perPage: 100000000000,
                            page: 1,
                            sortField: 'Name',
                            sortDirection: this.sortDirection,
                        }

                        this.clientStore.search(clientsPayload).then(() => {
                            this.setResults()
                        })
                    }
                }, 10)
            }
        },
    },
    created() {
        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        window.scrollTo(0, 0)
        const isB2CInterval = setInterval(() => {
            if (this.userStore.isB2C !== null) {
                clearInterval(isB2CInterval)

                const clientSearchQuery = []
                const filteredList = this.$cookies.get('filtered_addresses')
                if (filteredList) {
                    this.search = filteredList.query
                    this.sortDirection = filteredList.sortDirection
                    this.filter = filteredList.filter
                    this.sortField = filteredList.sortField
                    this.perPage = filteredList.perPage
                    this.page = filteredList.page
                    this.columns.forEach((col, index) => {
                        if (
                            col.type === 'select' ||
                            col.type === 'icon-select'
                        ) {
                            this.setRealSelected(col.dbField, index)
                        }
                    })
                    this.setRealSearch(this.search)
                    const clQuery = this.search.find(
                        (s) => s.dbField === 'client'
                    )
                    if (clQuery) {
                        clientSearchQuery.push({
                            term: clQuery.term,
                            dbField: 'Name',
                        })
                    }
                }
                if (this.$route.params.id) {
                    this.clientStore.fetchOne(this.$route.params.id)
                }

                let clientsPayload = {
                    query: clientSearchQuery,
                    filter: [],
                    perPage: 100000000000,
                    page: 1,
                    sortField: 'Name',
                    sortDirection: this.sortDirection,
                }
                const realSearch = {
                    clientId: this.clientId,
                    query: this.search,
                    filter: this.filter,
                    sortDirection: this.sortDirection,
                    sortField: this.sortField,
                    perPage: this.perPage,
                    page: this.page,
                }
                if (this.userStore.isB2C === false) {
                    const universesInterval = setInterval(() => {
                        if (this.universesOptions.length > 0) {
                            clearInterval(universesInterval)
                            if (this.userStore.isB2C === false) {
                                if (
                                    !this.filter.find(
                                        (f) => f.dbField === 'IdUniverse'
                                    )
                                ) {
                                    const universeFilter = {
                                        dbField: 'IdUniverse',
                                        value:
                                            this.selectedUniverses.length > 0
                                                ? this.selectedUniverses
                                                : this.universesOptions,
                                    }
                                    this.clientUniverseFilter.push(
                                        universeFilter
                                    )
                                }
                            }

                            clientsPayload = {
                                query: clientSearchQuery,
                                filter: this.clientUniverseFilter,
                                perPage: 100000000000,
                                page: 1,
                                sortField: 'Name',
                                sortDirection: this.sortDirection,
                            }

                            if (!this.$route.params.id) {
                                this.clientStore
                                    .search(clientsPayload)
                                    .then(() => {
                                        this.clientStore.fetchAddresses(
                                            realSearch
                                        )
                                        this.addressesInterval = setInterval(
                                            () => {
                                                this.clientStore.fetchAddresses(
                                                    realSearch
                                                )
                                            },
                                            20000
                                        )
                                    })
                            } else {
                                this.clientStore.fetchAddresses(realSearch)
                                this.addressesInterval = setInterval(() => {
                                    this.clientStore.fetchAddresses(realSearch)
                                }, 20000)
                            }
                        }
                    }, 10)
                } else {
                    this.clientStore.fetchAddresses(realSearch)
                    this.addressesInterval = setInterval(() => {
                        this.clientStore.fetchAddresses(realSearch)
                    }, 20000)
                }
            }
        }, 200)
    },

    unmounted() {
        clearInterval(this.addressesInterval)
        this.currentClient = null
        this.eventBus.all.clear()

        // if (this.$route.params.id) {

        //     this.$cookies.remove('filtered_addresses')
        // }
    },
    computed: {
        addressesActionsAccess() {
            return actionsAccess.clients.addresses
        },
        ...mapWritableState(useClientStore, {
            addresses: 'addresses',
            count: 'addressesCount',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            currentClient: 'current',
            loading: 'loading',
        }),
        ...mapState(useUniverseStore, {
            universes: 'all',
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),

        ...mapStores(useClientStore, useUserStore),
        clientId() {
            return this.userStore.isB2C === true &&
                this.userStore.current.idClient
                ? this.userStore.current.idClient
                : this.$route.params.id
                ? this.$route.params.id
                : this.currentClient && this.currentClient.id
                ? this.currentClient.id
                : null
        },
        columns() {
            return [
                {
                    name: 'name',
                    isLink: checkAccess(
                        this.userStore,
                        this.addressesActionsAccess.update
                    ),
                    routeName: 'update_address',

                    filtrable: true,
                    dbField: 'Name',
                    type: 'search',
                    sortable: true,
                    hide: false,
                },
                {
                    name: 'client',
                    filtrable: !this.$route.params.id,
                    dbField: 'client',
                    type: !this.$route.params.id ? 'search' : '',
                    sortable: !this.$route.params.id,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'postal_code',
                    filtrable: true,
                    dbField: 'PostalCode',
                    type: 'search',
                    sortable: true,
                    hide: false,
                },
                {
                    name: 'city',
                    filtrable: true,
                    dbField: 'City',
                    type: 'search',
                    sortable: true,
                    hide: false,
                },
                {
                    name: 'address',
                    filtrable: true,
                    dbField: 'Address1',
                    type: 'search',
                    sortable: true,
                    hide: false,
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                    hide: false,
                },
            ]
        },
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.addressesActionsAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(this.userStore, this.addressesActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.addressesActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }

            return actions
        },
    },

    methods: {
        searchCustomUniverses(clear = false) {
            clearInterval(this.addressesInterval)

            this.clientUniverseFilter = []
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                this.clientUniverseFilter.push({
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                })
            } else {
                this.clientUniverseFilter.push({
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                })
            }
            this.setResults()
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.addressesInterval)
            this.searchCustomUniverses()
        },
        upFilter(event) {
            clearInterval(this.addressesInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.addressesActionsAccess.create
            )
        },
        back() {
            // const backPath = this.$router.options.history.state.back
            this.$router.push({ name: 'clients' })
            this.$emit('back', 'clients')
        },
        goToUpdateOne(id) {
            this.$emit('goToUpdateAddress', id)
        },
        async deleteOne(id) {
            this.searchingCursor = true
            const response = await this.clientStore.deleteOneDeliveryAddress(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('address_delete_ok'))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('address_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.clientStore.deleteOneDeliveryAddress(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
            this.setResults()
        },

        setPage(isNext) {
            this.searchingCursor = true

            // clearInterval(this.addressesInterval)
            // this.$cookies.remove('filtered_addresses')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.addressesInterval)
            this.$cookies.remove('filtered_addresses')
            this.search = search.query
            search.clientId = this.clientId
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField =
                search.sortField === 'client' ? '' : search.sortField
            this.perPage = search.perPage
            this.page = search.page

            let clientsPayload = {
                query: [],
                filter: this.clientUniverseFilter,
                perPage: 100000000000,
                page: 1,
                sortField: 'Name',
                sortDirection: this.sortDirection,
            }
            this.search.forEach((s) => {
                if (s.dbField === 'client') {
                    clientsPayload = {
                        query: [{ term: s.term, dbField: 'Name' }],
                        filter: [],
                        perPage: 100000000000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: this.sortDirection,
                    }
                }
            })
            this.setRealSearch(this.search)

            if (!this.clientId) {
                this.clientStore.search(clientsPayload).then(() => {
                    this.clientStore.fetchAddresses(search).then(() => {
                        this.searchingCursor = false

                        this.refresh += 1
                        this.addressesInterval = setInterval(() => {
                            this.clientStore.fetchAddresses(search)
                        }, 20000)
                    })
                })
            } else {
                this.clientStore.fetchAddresses(search).then(() => {
                    this.searchingCursor = false

                    this.refresh += 1
                    this.addressesInterval = setInterval(() => {
                        this.clientStore.fetchAddresses(search)
                    }, 20000)
                })
            }

            this.$cookies.set('filtered_addresses', search)
        },
        changePerPage(perPage) {
            // this.searchingCursor = true

            // clearInterval(this.addressesInterval)
            this.$cookies.remove('filtered_addresses')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },
        updateSortDirectionCustom(sort) {
            this.searchingCursor = true

            this.page = 1

            this.sortDirection = sort.direction
            this.sortField = sort.field === 'client' ? '' : sort.field
            this.setResults()
        },

        // updateSortField(sortField) {
        //     this.sortField = sortField === 'client' ? '' : sortField
        //     this.setResults()
        // },
        debounceSearchCustom(query) {
            this.searchingCursor = true

            this.page = 1

            this.search.forEach((s, index) => {
                if (s.dbField !== 'client' && s.dbField === query.dbField) {
                    this.search.splice(index, 1)
                }
            })
            this.search.push(query)

            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.setResults()
            }, 400)
        },
        setResults() {
            const search = {
                clientId: this.clientId,
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField === 'client' ? '' : this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>

<template>
    <main
        :class="isMobile === true ? '' : 'mt-12'"
        class="rounded-md darky overflow-hidden"
    >
        <div
            v-if="mapLoaded === false"
            class="items-center justify-center absolute text-xl pt-12"
            style="width: 100%; height: 100vh"
        >
            <fa-icon class="fa-lg mr-2" icon="spinner" spin-pulse></fa-icon>
            {{ $t('map_loading') }}
        </div>

        <div
            id="wrapper"
            :class="
                processing === true || calculationInProgress === true
                    ? 'cursor-wait'
                    : ''
            "
        >
            <div
                id="map"
                class="z-1"
                :style="isMobile === true ? 'height: 100vh' : 'height: 95vh'"
                @click="canReset === true ? resetDraggedElements() : ''"
                :class="
                    processing === true || calculationInProgress === true
                        ? 'cursor-wait'
                        : ''
                "
            ></div>

            <!-- TODO mettre 95 pour tous??? -->
            <div id="custom-panel" class="flex w-full justify-end pl-2 pr-2">
                <!-- style="padding-right: 3.3rem" -->
                <!-- <div class="grid grid-cols-12"> -->
                <span
                    v-if="
                        (processing === true ||
                            calculationInProgress === true) &&
                        $route.meta.isTour === false &&
                        canAccess === true
                    "
                    id="wait-screen"
                    class="themed-bg tour-opti ml-1 border-2 rounded whitespace-nowrap flex justify-center font-bold items-center h-32"
                >
                    <span
                        class="my-auto z-30 whitespace-nowrap flex justify-center font-bold items-center text-base"
                        :class="notRecalc ? 'mx-6 ' : 'mx-2 '"
                    >
                        <fa-icon
                            class="fa-md mr-2"
                            icon="spinner"
                            spin-pulse
                        ></fa-icon>
                        {{
                            $t(
                                notRecalc
                                    ? 'fetching_data'
                                    : 'recalculation_in_progress',
                            )
                        }}
                    </span>
                </span>
                <span
                    :key="refresh"
                    v-if="current"
                    style="line-height: inherit"
                    class="over-map flex border-2 rounded h-32 ml-1"
                >
                    <div
                        class="in-over-map m-3 p-2 text-zinc-400 border-2 rounded h-24 overflow-y-auto text-xs"
                    >
                        <div
                            class="mx-1 text-sm font-semibold whitespace-nowrap"
                        >
                            {{ current.Name }}
                        </div>
                        <div
                            v-if="current.DateBegin"
                            class="mx-1 whitespace-nowrap"
                        >
                            {{
                                $t('start') +
                                ' ' +
                                new Date(current.DateBegin).toLocaleString(
                                    $i18n.locale,
                                    {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    },
                                ) +
                                ' - '
                            }}
                            {{
                                $t('end') +
                                ' ' +
                                new Date(current.DateEnd).toLocaleString(
                                    $i18n.locale,
                                    {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    },
                                )
                            }}
                        </div>
                        <div
                            class="mx-1 whitespace-nowrap"
                            v-if="$route.meta.isTour === true"
                        >
                            {{ $t(current.Status) }}
                        </div>
                        <div class="mx-1 whitespace-nowrap">
                            {{ current.NbSteps + ' ' + $t('steps') + ' / ' }}
                            {{
                                current.NbTours +
                                ' ' +
                                $tc('tour_tours', current.NbTours <= 1 ? 1 : 2)
                            }}
                            <span v-if="current.NbStepsUnaffected">
                                {{
                                    ' / ' +
                                    current.NbStepsUnaffected +
                                    ' ' +
                                    $tc('not_affected', unaffectedCount)
                                }}
                            </span>
                        </div>
                        <div class="mx-1 whitespace-nowrap">
                            {{
                                $t('duration') +
                                ' ' +
                                formatDuration(current.Duration) +
                                ' / '
                            }}
                            {{
                                $t('distance_nokm') +
                                ' ' +
                                formatDistance(current.Distance) +
                                ' km'
                            }}
                        </div>
                    </div></span
                >
                <span
                    :key="refresh"
                    v-if="displayTopBlock === true"
                    class="over-map flex text-zinc-400 border-l-2 border-t-2 border-b-2 rounded-l h-32"
                >
                    <div
                        v-if="
                            $route.meta.isTour === false && canAccess === true
                        "
                        class="mt-3 px-2 h-24"
                        :class="
                            processing === true ||
                            calculationInProgress === true
                                ? 'cursor-wait'
                                : 'cursor-pointer '
                        "
                    >
                        <div
                            class="mx-1 pt-2"
                            v-if="
                                $route.meta.daily === false &&
                                canIntegrateSimulation === true
                            "
                        >
                            <button
                                @click="validateSimulation()"
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                :disabled="$route.meta.daily === true"
                                class="themed-button whitespace-nowrap ml-1 mb-2 w-full text-xs opacity-90 transition duration-200 focus:shadow-sm focus:ring-opacity-50 text-zinc-200 px-2 py-2.5 rounded shadow-sm hover:shadow-md font-semibold text-center inline-block"
                            >
                                {{
                                    $t(
                                        $route.meta.daily === false
                                            ? 'integrate_simulation'
                                            : 'relaunch',
                                    )
                                }}
                            </button>
                        </div>

                        <div
                            class="mx-1 pt-2"
                            v-else-if="$route.meta.daily === true"
                        >
                            <button
                                @click="showAllTrace()"
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-not-allowed'
                                "
                                disabled="true"
                                class="themed-button whitespace-nowrap ml-1 mb-2 w-full text-xs opacity-90 transition duration-200 focus:shadow-sm focus:ring-opacity-50 text-zinc-200 px-2 py-2.5 rounded shadow-sm hover:shadow-md font-semibold text-center inline-block"
                            >
                                <fa-icon
                                    style="color: #e4e4e7 !important"
                                    :icon="'flag-checkered'"
                                    class="fa-sm mr-1"
                                ></fa-icon>
                                {{ $t('display_finished') }}
                            </button>
                        </div>
                        <div class="mx-1">
                            <button
                                :title="$t('add_tour')"
                                @click="
                                    $route.meta.daily === false
                                        ? addTourCard()
                                        : addCalendarCard()
                                "
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                class="opacity-80 w-full ml-1 flex justify-center bg-zinc-900 hover:bg-black transition duration-200 focus:shadow-sm focus:ring-opacity-50 text-zinc-200 px-2 py-2.5 rounded text-xs shadow-sm hover:shadow-md font-semibold text-center"
                            >
                                <fa-icon
                                    style="
                                        border-color: #e4e4e7 !important;
                                        color: #e4e4e7 !important;
                                        padding: 0.1095rem;
                                        border-width: 1px;
                                    "
                                    :icon="'plus'"
                                    class="fa-sm rounded-full"
                                ></fa-icon>
                            </button>
                        </div>
                    </div>
                </span>
                <span
                    :key="refresh"
                    v-if="displayTopBlock === true"
                    class="over-map slider flex text-zinc-400 border-r-2 border-t-2 border-b-2 rounded-r h-32"
                >
                    <div
                        v-if="unaffectedSteps && unaffectedSteps.length"
                        :id="`tour-unaffected`"
                        @click="showUnaffectedSteps(true)"
                        :class="
                            processing === true ||
                            calculationInProgress === true
                                ? 'cursor-wait'
                                : 'cursor-pointer '
                        "
                        class="in-over-map m-3 text-zinc-400 border-2 rounded h-24"
                        @mouseenter="
                            hoveredCardBorder(`tour-unaffected`, '#9DBAD4')
                        "
                        @mouseleave="
                            showUnaffected === false
                                ? hoveredCardBorder(
                                      `tour-unaffected`,
                                      '#9DBAD4',
                                      false,
                                  )
                                : ''
                        "
                        :style="`border-color: ${
                            showUnaffected == true ? '#9DBAD4' : ''
                        };`"
                    >
                        <span :id="`main-card-unaffected`" class="mx-auto">
                            <span
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                class="z-50 mt-2 flex justify-end pr-2"
                                @click.stop="resetUnaffectedMarkers(true)"
                                :title="$t('refresh')"
                            >
                                <div
                                    class="rounded-full h-5 w-5"
                                    style="color: #e4e4e7; background: #6aa84f"
                                >
                                    <fa-icon
                                        class="fa-xs p-1"
                                        :icon="'redo'"
                                    ></fa-icon>
                                </div>
                            </span>
                            <div class="text-xs px-3 mt-1">
                                <!-- alignement du carré -->
                                <span class="flex items-center">
                                    <span
                                        v-if="showUnaffected === true"
                                        class="rounded-sm h-3 w-3 mr-2"
                                        :style="`background: #9DBAD4;`"
                                    ></span>
                                    <span class="whitespace-nowrap pb-2">
                                        {{
                                            unaffectedCount +
                                            ' ' +
                                            $tc(
                                                'not_affected',
                                                unaffectedCount,
                                            )
                                        }}<br />
                                    </span>
                                </span>
                                <span
                                    v-if="unaffectedHasIncompleteSteps"
                                    class="error-msg font-semibold whitespace-nowrap flex items-center"
                                    @click.stop="toCorrection()"
                                >
                                    <fa-icon
                                        :title="$t('steps_errors')"
                                        icon="warning"
                                        class="ko-link fa-lg mr-1"
                                    />

                                    {{ $t('steps_coordinates_errors_1') }}<br />
                                    {{ $t('steps_coordinates_errors_2') }}
                                </span>
                            </div>
                        </span>
                    </div>
                    <!-- @click="
                            draggedElement === null
                                ? showTour(tour)
                                : onDrop(null, `steps-tour-${tour.id}`, tour.id)
                        " -->
                    <div
                        v-for="(tour, index) in tourCards"
                        :key="index"
                        :id="`tour-${tour.id}`"
                        @click="
                            draggedElements.length === 0
                                ? showTour(tour)
                                : onDrop(null, `steps-tour-${tour.id}`, tour.id)
                        "
                        :class="
                            processing === true ||
                            calculationInProgress === true
                                ? 'cursor-wait'
                                : 'cursor-pointer '
                        "
                        class="in-over-map m-3 text-zinc-400 border-2 rounded h-24"
                        @mouseenter="
                            hoveredCardBorder(
                                `tour-${tour.id}`,
                                tour.color.main.hex,
                            )
                        "
                        @mouseleave="
                            tour.show === false
                                ? hoveredCardBorder(
                                      `tour-${tour.id}`,
                                      tour.color.main.hex,
                                      false,
                                  )
                                : ''
                        "
                        :style="`border-color: ${
                            tour.show ? tour.color.main.hex : ''
                        };`"
                    >
                        <span :id="`main-card-${tour.id}`" class="mx-auto">
                            <div class="mt-2 grid text-xs grid-cols-4 px-3">
                                <!-- alignement du carré -->
                                <span class="col-span-3 flex items-center">
                                    <!-- <span
                                        v-if="tour.show"
                                        class="rounded-sm h-3 w-3 mr-2"
                                        :style="`background: ${tour.color.main.hex};`"
                                    ></span> -->
                                    <span class="overflow-hidden truncate">
                                        {{ tour.driver.name }}<br />
                                    </span>
                                </span>
                                <span
                                    :style="`color: ${
                                        tour.show
                                            ? tour.color.main.hex
                                            : '#a1a1aa'
                                    };`"
                                    class="col-span-1 z-50 flex justify-end"
                                    v-if="tour.vehicle.type !== ''"
                                    ><fa-icon
                                        v-if="
                                            tour.vehicle.type === 'car' ||
                                            tour.vehicle.type === 'ship' ||
                                            tour.vehicle.type === 'truck' ||
                                            tour.vehicle.type ===
                                                'person-biking'
                                        "
                                        :icon="tour.vehicle.type"
                                        class="fa-md"
                                    ></fa-icon>

                                    <img
                                        v-else
                                        svg-inline
                                        :src="`/biiimIcons/${tour.vehicle.type}.svg`"
                                        style="
                                            background-color: unset;
                                            filter: invert(0.7);
                                        "
                                        :class="
                                            tour.vehicle.type === 'van'
                                                ? 'w-4'
                                                : 'w-7'
                                        "
                                    />
                                </span>
                                <!-- <span
                                    :class="
                                        processing === true ||
                                        calculationInProgress === true
                                            ? 'cursor-wait'
                                            : 'cursor-pointer '
                                    "
                                    class="col-span-1 z-50 flex justify-end"
                                    @click.stop="showEditTourModal(tour)"
                                >
                                    <div
                                        v-if="
                                            $route.meta.isTour === false &&
                                            canAccess === true
                                        "
                                        class="rounded-full h-5 w-5"
                                        :style="`background: ${
                                            tour.show
                                                ? tour.color.main.hex
                                                : '#a1a1aa'
                                        }; color:  ${
                                            tour.show
                                                ? tour.color.secondary.hex
                                                : '#e4e4e7'
                                        }  !important`"
                                    >
                                        <fa-icon
                                            class="fa-xs p-1"
                                            :icon="'pen'"
                                        ></fa-icon>
                                    </div>
                                </span> -->
                            </div>

                            <div class="mt-1 grid text-xs grid-cols-4 px-3">
                                <span
                                    class="col-span-3 flex text-xs italic flex-wrap"
                                >
                                    <!-- <span
                                        :style="`color: ${
                                            tour.show
                                                ? tour.color.main.hex
                                                : '#a1a1aa'
                                        };`"
                                        class="pr-2"
                                        v-if="tour.vehicle.type !== ''"
                                        ><fa-icon
                                            v-if="
                                                tour.vehicle.type === 'car' ||
                                                tour.vehicle.type === 'ship' ||
                                                tour.vehicle.type === 'truck' ||
                                                tour.vehicle.type ===
                                                    'person-biking'
                                            "
                                            :icon="tour.vehicle.type"
                                            class="fa-md"
                                        ></fa-icon>

                                        <img
                                            v-else
                                            svg-inline
                                            :src="`/biiimIcons/${tour.vehicle.type}.svg`"
                                            style="
                                                background-color: unset;
                                                filter: invert(0.7);
                                            "
                                            :class="
                                                tour.vehicle.type === 'van'
                                                    ? 'w-4'
                                                    : 'w-7'
                                            "
                                        />
                                    </span> -->
                                    <span class="overflow-hidden truncate">
                                        {{ tour.vehicle.name }}
                                    </span>
                                </span>

                                <!-- <span
                                    v-if="
                                        $route.meta.isTour === false &&
                                        canAccess === true
                                    "
                                    class="col-span-1 col-start-4 z-50 flex justify-end"
                                    :class="
                                        processing === true ||
                                        calculationInProgress === true
                                            ? 'cursor-wait'
                                            : 'cursor-pointer '
                                    "
                                    @click.stop="deleteTour(tour.id, index)"
                                >
                                    <div
                                        class="rounded-full h-5 w-5"
                                        :style="`background: ${
                                            tour.show
                                                ? tour.color.main.hex
                                                : '#a1a1aa'
                                        }; color: ${
                                            tour.show
                                                ? tour.color.secondary.hex
                                                : '#e4e4e7'
                                        }  !important`"
                                    >
                                        <fa-icon
                                            class="fa-xs p-1"
                                            :icon="'trash-can'"
                                        ></fa-icon></div
                                ></span> -->
                            </div>

                            <div class="text-xs italic px-3 mt-1">
                                <span class="mb-1">
                                    {{
                                        tour.pts +
                                        ' - ' +
                                        formatDistance(tour.distance) +
                                        ' km - ' +
                                        formatDuration(tour.duration)
                                    }}
                                    <!-- ' - ' +
                                        tour.weight + -->
                                </span>
                            </div>
                            <div
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                            >
                                <div
                                    class="flex items-center py-1 mx-2"
                                    :class="
                                        $route.meta.daily === false
                                            ? 'justify-end'
                                            : 'justify-between'
                                    "
                                >
                                    <span class="flex items-center">
                                        <span
                                            :title="$t('real_driver_tour')"
                                            class="cursor-pointer"
                                        >
                                            <trace-icon
                                                @traceClicked="showTrace(index)"
                                                class="mr-1 z-50"
                                                :bgColor="
                                                    tour.showTrace
                                                        ? tour.color.main.hex
                                                        : '#68686A'
                                                "
                                            ></trace-icon>
                                        </span>

                                        <span
                                            :title="$t('software_calc_tour')"
                                            class="cursor-pointer"
                                        >
                                            <in-progress-icon
                                                @progressClicked="
                                                    showTour(tour)
                                                "
                                                class="mr-1 z-50"
                                                :bgColor="
                                                    tour.show
                                                        ? tour.color.main.hex
                                                        : '#68686A'
                                                "
                                            ></in-progress-icon> </span
                                        ><span
                                            :title="$t('delivered_order')"
                                            class="cursor-pointer"
                                        >
                                            <check-icon
                                                class="mr-1 z-50"
                                                :bgColor="
                                                    tour.showFinished
                                                        ? tour.color.main.hex
                                                        : '#68686A'
                                                "
                                                @finishedClicked="
                                                    showFinished(index)
                                                "
                                            >
                                            </check-icon>
                                        </span>
                                    </span>
                                    <span class="flex items-center justify-end">
                                        <span
                                            v-if="
                                                !tour.steps.infos[0] ||
                                                tour.steps.infos[0].Status ===
                                                    'affected' ||
                                                tour.steps.infos[0].Status ===
                                                    'new' ||
                                                userStore.current.type ===
                                                    'superadmin'
                                            "
                                            class="mr-1"
                                            @click.stop="
                                                showEditTourModal(tour)
                                            "
                                        >
                                            <div
                                                v-if="
                                                    $route.meta.isTour ===
                                                        false &&
                                                    canAccess === true
                                                "
                                                class="rounded-full h-5 w-5"
                                                style="
                                                    background: #68686a;
                                                    color: #e4e4e7 !important;
                                                "
                                            >
                                                <fa-icon
                                                    class="fa-xs p-1"
                                                    :icon="'pen'"
                                                ></fa-icon>
                                            </div>
                                        </span>
                                        <span
                                            v-if="
                                                $route.meta.isTour === false &&
                                                canAccess === true
                                            "
                                            @click.stop="
                                                deleteTour(tour.id, null)
                                            "
                                        >
                                            <div
                                                class="rounded-full h-5 w-5"
                                                style="
                                                    background: #68686a;
                                                    color: #e4e4e7 !important;
                                                "
                                            >
                                                <fa-icon
                                                    class="fa-xs p-1"
                                                    :icon="'trash-can'"
                                                ></fa-icon></div
                                        ></span>
                                    </span>
                                </div>
                            </div>
                        </span>
                    </div>
                </span>
                <span
                    :class="
                        processing === true || calculationInProgress === true
                            ? 'cursor-wait'
                            : 'cursor-pointer '
                    "
                    class="over-map text-zinc-400 justify-center border-2 rounded h-32 items-center flex"
                    @click="displayTopBlock = !displayTopBlock"
                >
                    <fa-icon
                        :icon="
                            displayTopBlock === false
                                ? 'angles-left'
                                : 'angles-right'
                        "
                        class="fa-md py-10 px-1"
                    ></fa-icon>
                </span>

                <span
                    @click="setGoogleMapStyle()"
                    class="over-map text-zinc-400 mt-11 justify-center ml-1 rounded-sm h-10 w-10 px-2 items-center flex cursor-pointer"
                    style="margin-right: 2px"
                    :title="$t('hide_show_google_pois')"
                >
                    <fa-icon :icon="'arrows-to-eye'" class="fa-lg"></fa-icon>
                </span>
            </div>
            <br />
            <div
                id="custom-panel-2"
                class="flex w-full overflow-hidden justify-end mr-2 pl-2 h-screen"
            >
                <div
                    :key="refresh"
                    v-if="displayBottomBlock === true"
                    class="light-over-map flex overflow-hidden"
                >
                    <div
                        v-for="(tourToShow, i) in toursToShow"
                        :key="i"
                        class="light-in-over-map text-xs text-zinc-900 border-2 rounded mb-2 mx-1 overflow-y-visible"
                        style="color: black !important"
                        :id="`panel-${tourToShow.id}`"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop="
                            onDrop(
                                $event,
                                `steps-tour-${tourToShow.id}`,
                                tourToShow.id,
                                tourToShow.steps.infos.length - 1,
                            )
                        "
                    >
                        <!-- height: 479px; -->
                        <!-- min-w-[15%]  -->
                        <span v-if="tourToShow.open === true">
                            <div
                                class="mt-2 grid text-xs grid-cols-4 px-2"
                                style="color: black !important"
                            >
                                <span
                                    :class="
                                        processing === true ||
                                        calculationInProgress === true
                                            ? 'cursor-wait'
                                            : 'cursor-pointer '
                                    "
                                    class="col-start-5 col-span-1 z-50 flex pr-2 mt-2 justify-end"
                                    @click="
                                        ;(tourToShow.open = !tourToShow.open) &
                                            openPanel(tourToShow)
                                    "
                                >
                                    <span
                                        class="rounded-full h-6 w-6 flex"
                                        :style="`background: ${tourToShow.color.main.hex}; color: ${tourToShow.color.secondary.hex} !important`"
                                    >
                                        <fa-icon
                                            icon="xmark"
                                            class="my-auto mx-auto"
                                        ></fa-icon>
                                    </span>
                                </span>
                            </div>

                            <div
                                class="mt-1 grid text-xs grid-cols-4 px-2 mb-1 items-center"
                                style="color: black !important"
                            >
                                <!-- alignement du carré -->

                                <span
                                    class="col-start-2 col-span-2 whitespace-nowrap font-semibold"
                                >
                                    {{
                                        tourToShow.id !== 'unaffected'
                                            ? tourToShow.driver.name
                                            : $tc(
                                                  'not_affected',
                                                  unaffectedCount,
                                              )
                                    }}
                                </span>
                                <span
                                    v-if="
                                        tourToShow.id !== 'unaffected' &&
                                        $route.meta.isTour === false &&
                                        canAccess === true &&
                                        (!tourToShow.steps.infos[0] ||
                                            tourToShow.steps.infos[0].Status ===
                                                'affected' ||
                                            tourToShow.steps.infos[0].Status ===
                                                'new' ||
                                            userStore.current.type ===
                                                'superadmin')
                                    "
                                    :class="
                                        processing === true ||
                                        calculationInProgress === true
                                            ? 'cursor-wait'
                                            : 'cursor-pointer '
                                    "
                                    class="col-span-1 z-50 flex pr-2 justify-end"
                                    @click.stop="showEditTourModal(tourToShow)"
                                >
                                    <span
                                        class="rounded-full h-6 w-6"
                                        :style="`background:#71717a; color: #e4e4e7 !important`"
                                    >
                                        <fa-icon
                                            :icon="'pen'"
                                            class="fa-sm p-1.5"
                                        ></fa-icon>
                                    </span>
                                </span>
                            </div>
                            <div
                                v-if="tourToShow.id !== 'unaffected'"
                                class="mt-1 grid text-xs grid-cols-4 px-2 mb-1 items-center"
                                style="color: black !important"
                            >
                                <span
                                    class="col-start-2 flex items-center mb-1 justify-center text-xs italic col-span-2"
                                >
                                    <span
                                        :style="`color: ${
                                            tourToShow.show
                                                ? tourToShow.color.main.hex
                                                : '#a1a1aa'
                                        };`"
                                        class="pr-2"
                                        v-if="tourToShow.vehicle.type !== ''"
                                        ><fa-icon
                                            v-if="
                                                tourToShow.vehicle.type ===
                                                    'car' ||
                                                tourToShow.vehicle.type ===
                                                    'ship' ||
                                                tourToShow.vehicle.type ===
                                                    'truck' ||
                                                tourToShow.vehicle.type ===
                                                    'person-biking'
                                            "
                                            :icon="tourToShow.vehicle.type"
                                            class="fa-md"
                                        ></fa-icon>

                                        <img
                                            v-else
                                            svg-inline
                                            :src="`/biiimIcons/${tourToShow.vehicle.type}.svg`"
                                            style="background-color: unset"
                                            :class="
                                                tourToShow.vehicle.type ===
                                                'van'
                                                    ? 'w-4'
                                                    : 'w-7'
                                            "
                                        />
                                    </span>
                                    {{ tourToShow.vehicle.name }}
                                </span>
                                <span
                                    v-if="
                                        $route.meta.isTour === false &&
                                        canAccess === true
                                    "
                                    :class="
                                        processing === true ||
                                        calculationInProgress === true
                                            ? 'cursor-wait'
                                            : 'cursor-pointer '
                                    "
                                    class="col-span-1 z-50 flex pr-2 justify-end"
                                    @click.stop=""
                                >
                                    <span
                                        class="rounded-full h-6 w-6"
                                        :style="`background:#71717a; color: #e4e4e7 !important`"
                                    >
                                        <fa-icon
                                            :icon="'trash-can'"
                                            class="fa-sm p-1.5"
                                            @click.prevent="
                                                deleteTour(tourToShow.id, i)
                                            "
                                        ></fa-icon>
                                    </span>
                                </span>
                            </div>
                            <div
                                class="px-2 items-center mb-3"
                                style="color: black !important"
                                v-if="tourToShow.id !== 'unaffected'"
                            >
                                <span
                                    class="italic text-xs flex-wrap font-semibold"
                                >
                                    {{
                                        tourToShow.pts +
                                        ' - ' +
                                        formatDistance(tourToShow.distance) +
                                        ' km - ' +
                                        formatDuration(tourToShow.duration)
                                    }}
                                    <!-- tourToShow.weight +
                                        ' - ' + -->
                                </span>
                            </div>
                            <span
                                v-if="tourToShow.id !== 'unaffected'"
                                class="flex justify-center mb-2 items-center"
                            >
                                <span
                                    :title="$t('real_driver_tour')"
                                    class="cursor-pointer"
                                >
                                    <trace-icon
                                        @traceClicked="showTrace(i, true)"
                                        class="mr-2 z-50"
                                        :bgColor="
                                            tourToShow.showTrace
                                                ? tourToShow.color.main.hex
                                                : '#68686A'
                                        "
                                    ></trace-icon>
                                </span>

                                <span
                                    :title="$t('software_calc_tour')"
                                    class="cursor-pointer"
                                >
                                    <in-progress-icon
                                        @progressClicked="showTour(tourToShow)"
                                        class="mr-2 z-50"
                                        :bgColor="
                                            tourToShow.show
                                                ? tourToShow.color.main.hex
                                                : '#68686A'
                                        "
                                    ></in-progress-icon> </span
                                ><span
                                    :title="$t('delivered_order')"
                                    class="cursor-pointer"
                                >
                                    <check-icon
                                        class="mr-2 z-50"
                                        :bgColor="
                                            tourToShow.showFinished
                                                ? tourToShow.color.main.hex
                                                : '#68686A'
                                        "
                                        @finishedClicked="showFinished(i, true)"
                                    >
                                    </check-icon>
                                </span>
                            </span>
                            <div
                                v-if="tourToShow.id !== 'unaffected'"
                                class="overflow-y-auto h-[74%] z-50"
                            >
                                <ol
                                    v-for="(step, index) in tourToShow.steps
                                        .infos"
                                    :id="`sortable-list-${index}`"
                                    :key="index"
                                    @dragover.prevent="
                                        handleDrag(index, tourToShow.id)
                                    "
                                    :draggable="
                                        $route.meta.isTour === false &&
                                        canAccess === true &&
                                        index !== 0 &&
                                        index !==
                                            tourToShow.steps.infos.length - 1 &&
                                        canBeDragged(
                                            tourToShow.driver,
                                            step.OrderStepEnd.IdUniverse,
                                            step.Status,
                                        )
                                    "
                                    :class="` relative border-l ml-6 ${
                                        $route.meta.isTour === false &&
                                        canAccess === true &&
                                        processing === false
                                            ? 'cursor-grab'
                                            : ($route.meta.isTour === false &&
                                                    canAccess === true &&
                                                    processing === true) ||
                                                calculationInProgress === true
                                              ? 'cursor-wait'
                                              : 'cursor-default'
                                    }`"
                                    :style="`border-color: ${tourToShow.color.main.hex}; background:rgba(236, 240, 241, 0.85) !important;`"
                                    @dragstart="
                                        startDrag(
                                            $event,
                                            step.OrderStepEnd.Id,
                                            tourToShow.id,
                                            i,
                                            null,
                                            index,
                                        )
                                    "
                                >
                                    <!-- TODO le dernier step ne doit pas etre draggable
                                 TODO mettre un indicateur de là ou va être droppé le step
                                 TODO -->
                                    <li
                                        v-show="
                                            index === 0 &&
                                            (step.Status !== 'executed' ||
                                                tourToShow.showFinished ===
                                                    true)
                                        "
                                        class="mb-1 ml-6"
                                        :style="`background: rgba(236, 240, 241, 0.85) !important; `"
                                    >
                                        <span
                                            :style="`background: ${tourToShow.color.main.hex}; color: black !important`"
                                            class="flex absolute -left-3 justify-center items-center w-6 h-6 rounded-full"
                                            ><fa-icon
                                                v-if="
                                                    step.Status === 'executed'
                                                "
                                                icon="check"
                                            ></fa-icon>
                                            <fa-icon
                                                v-else-if="
                                                    index !==
                                                        tourToShow.steps.infos
                                                            .length -
                                                            1 &&
                                                    index !== 0 &&
                                                    !canBeDragged(
                                                        tourToShow.driver,
                                                        step.OrderStepEnd
                                                            .IdUniverse,
                                                        step.Status,
                                                    )
                                                "
                                                icon="lock"
                                            ></fa-icon>

                                            <span v-else>{{
                                                index + 1
                                            }}</span></span
                                        >
                                        <!-- :style="`border-color: ${tourToShow.color.main.hex}; color: black !important`" -->
                                        <span
                                            :style="`border-color: ${tourToShow.color.main.hex}; color: black !important`"
                                            class="block max-w-full mr-3 rounded border p-3"
                                            @dragover.prevent="
                                                handleDrag(index, tourToShow.id)
                                            "
                                            :draggable="
                                                $route.meta.isTour === false &&
                                                canAccess === true &&
                                                index !== 0 &&
                                                index !==
                                                    tourToShow.steps.infos
                                                        .length -
                                                        1 &&
                                                canBeDragged(
                                                    tourToShow.driver,
                                                    step.OrderStepEnd
                                                        .IdUniverse,
                                                    step.Status,
                                                )
                                            "
                                        >
                                            <div class="flex justify-between">
                                                <strong class="text-xs mr-1">
                                                    <span
                                                        v-if="
                                                            step.Status ===
                                                            'executed'
                                                        "
                                                    >
                                                        <fa-icon
                                                            icon="check"
                                                            class="mr-1"
                                                        ></fa-icon>
                                                        {{
                                                            step.OrderStepBegin
                                                                .AddressName
                                                        }}</span
                                                    ><span v-else>
                                                        {{
                                                            index +
                                                            1 +
                                                            ' - ' +
                                                            step.OrderStepBegin
                                                                .AddressName
                                                        }}
                                                    </span>
                                                </strong>
                                                <!-- TODO ICI POUR METTRE LES BONNES HEURE -->
                                                <!-- si l'index du step est 0 mettre date begin ou new 
                                                si l'index est le dernier date begin ou new  -->
                                                <span v-if="index === 0">
                                                    <span
                                                        v-if="step.DateReal"
                                                        class="flex justify-end items-center"
                                                    >
                                                        <!-- <fa-icon
                                                            :icon="'clock'"
                                                        >
                                                        </fa-icon> -->
                                                        <img
                                                            :alt="
                                                                $t('finished')
                                                            "
                                                            svg-inline
                                                            src="/biiimIcons/check.svg"
                                                            class="mr-1"
                                                        />
                                                        <span
                                                            :title="
                                                                $t(
                                                                    'real_pickup_time',
                                                                )
                                                            "
                                                            class="mr-1"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateBegin,
                                                                )}  `
                                                            }}
                                                        </span>
                                                        <!-- <strong
                                                            class="text-blue-500"
                                                            :title="
                                                                $t(
                                                                    'real_deliv_time'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                ` ${formatStepTime(
                                                                    step.DateReal
                                                                )}`
                                                            }}
                                                        </strong> -->
                                                    </span>
                                                    <span
                                                        v-else-if="
                                                            step.DateBeginNew
                                                        "
                                                        class="flex justify-end items-center"
                                                    >
                                                        <img
                                                            :alt="
                                                                $t(
                                                                    'in_progress',
                                                                )
                                                            "
                                                            svg-inline
                                                            :src="`/biiimIcons/${
                                                                isOnTime(
                                                                    step.DateBegin,
                                                                    step.DateBeginNew,
                                                                )
                                                                    ? 'real-time-clock.svg'
                                                                    : 'clock_red.svg'
                                                            }`"
                                                            class="mr-1"
                                                        />
                                                        <span
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time_begin',
                                                                )
                                                            "
                                                            class="mr-1"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateBegin,
                                                                )} -   `
                                                            }}</span
                                                        >
                                                        <strong
                                                            :class="`${
                                                                isOnTime(
                                                                    step.DateBegin,
                                                                    step.DateBeginNew,
                                                                )
                                                                    ? 'success-msg'
                                                                    : 'error-msg'
                                                            }`"
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time_new_begin',
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                ` ${formatStepTime(
                                                                    step.DateBeginNew,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="flex justify-end items-center"
                                                        :title="
                                                            $t(
                                                                'software_calculated_time_begin',
                                                            )
                                                        "
                                                    >
                                                        <img
                                                            :alt="
                                                                $t(
                                                                    'in_progress',
                                                                )
                                                            "
                                                            svg-inline
                                                            src="/biiimIcons/real-time-clock.svg
                                                                    "
                                                            class="mr-1"
                                                        />
                                                        <span class="mr-1">
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateBegin,
                                                                )} -  `
                                                            }}
                                                        </span>
                                                        <strong
                                                            class="success-msg"
                                                        >
                                                            {{
                                                                ` ${formatStepTime(
                                                                    step.DateBegin,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                </span>
                                                <span
                                                    v-else-if="
                                                        index ===
                                                        tourToShow.steps.infos
                                                            .length -
                                                            1
                                                    "
                                                >
                                                    <span
                                                        v-if="step.DateReal"
                                                        class="flex justify-end items-center"
                                                    >
                                                        <!-- <fa-icon
                                                            :icon="'clock'"
                                                        >
                                                        </fa-icon> -->
                                                        <img
                                                            :alt="
                                                                $t('finished')
                                                            "
                                                            svg-inline
                                                            src="/biiimIcons/check.svg"
                                                            class="mr-1"
                                                        />
                                                        <span
                                                            :title="
                                                                $t(
                                                                    'real_delivery_time',
                                                                )
                                                            "
                                                            class="mr-1"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEnd,
                                                                )}  `
                                                            }}
                                                        </span>
                                                        <!-- <strong
                                                            class="text-blue-500"
                                                            :title="
                                                                $t(
                                                                    'real_deliv_time'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                ` ${formatStepTime(
                                                                    step.DateReal
                                                                )}`
                                                            }}
                                                        </strong> -->
                                                    </span>
                                                    <span
                                                        v-else-if="
                                                            step.DateEndEnd
                                                        "
                                                        class="flex justify-end items-center"
                                                    >
                                                        <img
                                                            :alt="
                                                                $t(
                                                                    'in_progress',
                                                                )
                                                            "
                                                            svg-inline
                                                            :src="`/biiimIcons/${
                                                                isOnTime(
                                                                    step.DateEnd,
                                                                    step.DateEndNew,
                                                                )
                                                                    ? 'real-time-clock.svg'
                                                                    : 'clock_red.svg'
                                                            }`"
                                                            class="mr-1"
                                                        />
                                                        <span
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time',
                                                                )
                                                            "
                                                            class="mr-1"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEnd,
                                                                )} -   `
                                                            }}</span
                                                        >
                                                        <strong
                                                            :class="`${
                                                                isOnTime(
                                                                    step.DateEnd,
                                                                    step.DateEndNew,
                                                                )
                                                                    ? 'success-msg'
                                                                    : 'error-msg'
                                                            }`"
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time_new',
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                ` ${formatStepTime(
                                                                    step.DateEndNew,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="flex justify-end items-center"
                                                        :title="
                                                            $t(
                                                                'software_calculated_time',
                                                            )
                                                        "
                                                    >
                                                        <img
                                                            :alt="
                                                                $t(
                                                                    'in_progress',
                                                                )
                                                            "
                                                            svg-inline
                                                            src="/biiimIcons/real-time-clock.svg
                                                                    "
                                                            class="mr-1"
                                                        />
                                                        <span class="mr-1">
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEnd,
                                                                )} -  `
                                                            }}
                                                        </span>
                                                        <strong
                                                            class="success-msg"
                                                        >
                                                            {{
                                                                ` ${formatStepTime(
                                                                    step.DateEnd,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                </span>
                                                <span v-else-if="step.DateEnd">
                                                    <span
                                                        v-if="step.DateReal"
                                                        class="flex justify-end items-center"
                                                    >
                                                        <!-- <fa-icon
                                                            :icon="'clock'"
                                                        >
                                                        </fa-icon> -->
                                                        <img
                                                            :alt="
                                                                $t('finished')
                                                            "
                                                            svg-inline
                                                            src="/biiimIcons/check.svg"
                                                            class="mr-1"
                                                        />
                                                        <span
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time',
                                                                )
                                                            "
                                                            class="mr-1"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEnd,
                                                                )} - `
                                                            }}
                                                        </span>
                                                        <strong
                                                            class="text-blue-500"
                                                            :title="
                                                                $t(
                                                                    'real_deliv_time',
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                ` ${formatStepTime(
                                                                    step.DateReal,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                    <span
                                                        v-else-if="
                                                            step.DateEndNew
                                                        "
                                                        class="flex justify-end items-center"
                                                    >
                                                        <img
                                                            :alt="
                                                                $t(
                                                                    'in_progress',
                                                                )
                                                            "
                                                            svg-inline
                                                            :src="`/biiimIcons/${
                                                                isOnTime(
                                                                    step.DateEnd,
                                                                    step.DateEndNew,
                                                                )
                                                                    ? 'real-time-clock.svg'
                                                                    : 'clock_red.svg'
                                                            }`"
                                                            class="mr-1"
                                                        />
                                                        <span
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time',
                                                                )
                                                            "
                                                            class="mr-1"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEnd,
                                                                )} -   `
                                                            }}</span
                                                        >
                                                        <strong
                                                            :class="`${
                                                                isOnTime(
                                                                    step.DateEnd,
                                                                    step.DateEndNew,
                                                                )
                                                                    ? 'success-msg'
                                                                    : 'error-msg'
                                                            }`"
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time_new',
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                ` ${formatStepTime(
                                                                    step.DateEndNew,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="flex justify-end items-center"
                                                        :title="
                                                            $t(
                                                                'software_calculated_time',
                                                            )
                                                        "
                                                    >
                                                        <img
                                                            :alt="
                                                                $t(
                                                                    'in_progress',
                                                                )
                                                            "
                                                            svg-inline
                                                            src="/biiimIcons/real-time-clock.svg
                                                                    "
                                                            class="mr-1"
                                                        />
                                                        <span class="mr-1">
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEnd,
                                                                )} -  `
                                                            }}
                                                        </span>
                                                        <strong
                                                            class="success-msg"
                                                        >
                                                            {{
                                                                ` ${formatStepTime(
                                                                    step.DateEnd,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                </span>
                                            </div>

                                            <div
                                                class="italic text-xs flex mt-1 justify-start"
                                            >
                                                <div
                                                    class="whitespace-nowrap"
                                                    style="
                                                        color: black !important;
                                                    "
                                                >
                                                    {{
                                                        `${step.OrderStepBegin.Address1} - ${step.OrderStepBegin.PostalCode} 
                                                        ${step.OrderStepBegin.City}`
                                                    }}
                                                </div>
                                            </div>
                                            <hr
                                                class="mt-2 mb-2 regular"
                                                v-if="
                                                    (step.OrderStepBegin
                                                        .DistanceFromLastStep &&
                                                        step.OrderStepBegin
                                                            .DurationFromLastStep) ||
                                                    (step.WaitingTime &&
                                                        +step.WaitingTime !==
                                                            0) ||
                                                    step.OrderStepBegin.Moment
                                                " />
                                            <span
                                                class="flex items-center justify-between"
                                            >
                                                <span
                                                    v-if="
                                                        step.OrderStepBegin
                                                            .DistanceFromLastStep &&
                                                        step.OrderStepBegin
                                                            .DurationFromLastStep
                                                    "
                                                    class="whitespace-nowrap mb-2 mr-1 flex items-center"
                                                    :title="
                                                        $t(
                                                            'dur_n_dist_last_step',
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :alt="
                                                            $t(
                                                                'dur_n_dist_last_step',
                                                            )
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/kilometre.svg
                                                                    "
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        formatDistance(
                                                            step.OrderStepBegin
                                                                .DistanceFromLastStep,
                                                        ) +
                                                        ' km - ' +
                                                        formatDuration(
                                                            step.OrderStepBegin
                                                                .DurationFromLastStep,
                                                        )
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        step.WaitingTime &&
                                                        +step.WaitingTime !== 0
                                                    "
                                                    class="whitespace-nowrap mb-2 flex items-center"
                                                    :title="$t('waiting_time')"
                                                >
                                                    <img
                                                        :alt="
                                                            $t('waiting_time')
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/temps-attente.svg
                                                                    "
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        formatDuration(
                                                            +step.WaitingTime +
                                                                +step.WaitingTimeExtra,
                                                        )
                                                    }}
                                                </span>
                                            </span>
                                            <span
                                                v-if="
                                                    step.OrderStepBegin.Moment
                                                "
                                                style="color: black !important"
                                                class="text-xs items-center flex whitespace-nowrap"
                                                :title="$t('opening_hours')"
                                            >
                                                <img
                                                    :alt="$t('opening_hours')"
                                                    svg-inline
                                                    src="/biiimIcons/clock.svg
                                                                    "
                                                    class="mr-1"
                                                />
                                                {{
                                                    `${
                                                        step.OrderStepBegin
                                                            .Moment !==
                                                        'between'
                                                            ? $t(
                                                                  step
                                                                      .OrderStepBegin
                                                                      .Moment,
                                                              )
                                                            : ''
                                                    }`
                                                }}
                                                {{
                                                    step.OrderStepBegin
                                                        .Moment === 'from'
                                                        ? formatStepTime(
                                                              step
                                                                  .OrderStepBegin
                                                                  .FromDateTime,
                                                          )
                                                        : step.OrderStepBegin
                                                                .Moment ===
                                                            'between'
                                                          ? formatStepTime(
                                                                step
                                                                    .OrderStepBegin
                                                                    .FromDateTime,
                                                            ) + ' - '
                                                          : ' '
                                                }}
                                                {{
                                                    step.OrderStepBegin
                                                        .Moment === 'before' ||
                                                    step.OrderStepBegin
                                                        .Moment === 'between'
                                                        ? formatStepTime(
                                                              step
                                                                  .OrderStepBegin
                                                                  .BeforeDateTime,
                                                          )
                                                        : ' '
                                                }}
                                            </span>
                                            <hr
                                                class="mt-2 regular"
                                                v-if="
                                                    (step.OrderStepBegin
                                                        .DistanceFromLastStep &&
                                                        step.OrderStepBegin
                                                            .DurationFromLastStep) ||
                                                    (step.WaitingTime &&
                                                        +step.WaitingTime !==
                                                            0) ||
                                                    step.OrderStepBegin.Moment
                                                " />

                                            <div
                                                class="flex mt-2 items-center justify-center"
                                            >
                                                <!-- <div
                                                    v-if="
                                                        index !== 0 &&
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center mr-2 flex"
                                                    @click="
                                                        changePosition(
                                                            tourToShow,
                                                            step.OrderStepBegin
                                                                .Id,
                                                            'first',
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        :title="
                                                            $t('start_with')
                                                        "
                                                    >
                                                        <biiim-icon
                                                            :iconClass="'biiim-icon'"
                                                            iconSrc="/biiimIcons/chevron-double-up.svg"
                                                            :title="
                                                                $t('start_with')
                                                            "
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></biiim-icon>
                                                       
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="
                                                        index !== 0 &&
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center mr-2 flex"
                                                    @click="
                                                        changePosition(
                                                            tourToShow,
                                                            step.OrderStepBegin
                                                                .Id,

                                                            index,
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        :title="$t('one_up')"
                                                    >
                                                        <fa-icon
                                                            class="fa-sm p-1.5"
                                                            :icon="'chevron-up'"
                                                            :title="
                                                                $t('one_up')
                                                            "
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="
                                                        index <
                                                            tourToShow.steps
                                                                .infos.length -
                                                                1 &&
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center mr-2 flex"
                                                    @click="
                                                        changePosition(
                                                            tourToShow,
                                                            step.OrderStepEnd
                                                                .Id,
                                                            index + 2,
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        :title="$t('one_down')"
                                                    >
                                                        <fa-icon
                                                            :icon="'chevron-down'"
                                                            :title="
                                                                $t('one_down')
                                                            "
                                                            class="fa-md p-1.5"
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="
                                                        index <
                                                            tourToShow.steps
                                                                .infos.length -
                                                                1 &&
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center mr-2 flex"
                                                    @click="
                                                        changePosition(
                                                            tourToShow,
                                                            step.OrderStepBegin
                                                                .Id,
                                                            'last',
                                                            i,
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        :title="$t('end_with')"
                                                    >
                                                        <biiim-icon
                                                            :iconClass="'biiim-icon'"
                                                            iconSrc="/biiimIcons/chevron-double-down.svg"
                                                            :title="
                                                                $t('end_with')
                                                            "
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></biiim-icon>
                                                        
                                                    </span>
                                                </div> -->
                                                <div
                                                    v-if="
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center flex"
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        @click.stop="
                                                            showEditAddressModal(
                                                                step.OrderStepBegin,
                                                                index,
                                                                tourToShow.color,
                                                            )
                                                        "
                                                    >
                                                        <!-- @click="editStep()" -->
                                                        <fa-icon
                                                            :icon="'pen'"
                                                            :title="$t('edit')"
                                                            class="fa-sm p-1.5"
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></fa-icon
                                                    ></span>
                                                </div></div
                                        ></span>
                                    </li>
                                    <li
                                        v-show="
                                            (step.IdOrderStepEnd !== '0' ||
                                                index ===
                                                    tourToShow.steps.infos
                                                        .length -
                                                        1) &&
                                            (step.OrderStepEnd.Status !==
                                                'executed' ||
                                                tourToShow.showFinished ===
                                                    true)
                                        "
                                        class="mb-1 ml-6"
                                        :style="`background: rgba(236, 240, 241, 0.85) !important; `"
                                        :draggable="
                                            index <
                                                tourToShow.steps.infos.length -
                                                    1 &&
                                            $route.meta.isTour === false &&
                                            canAccess === true &&
                                            canBeDragged(
                                                tourToShow.driver,
                                                step.OrderStepEnd.IdUniverse,
                                                step.Status,
                                            )
                                        "
                                    >
                                        <span
                                            :style="`background: ${tourToShow.color.main.hex}; color: black !important`"
                                            class="flex absolute -left-3 justify-center items-center w-6 h-6 rounded-full"
                                            ><fa-icon
                                                v-if="
                                                    step.OrderStepEnd.Status ===
                                                    'executed'
                                                "
                                                icon="check"
                                            ></fa-icon>
                                            <fa-icon
                                                v-else-if="
                                                    index !==
                                                        tourToShow.steps.infos
                                                            .length -
                                                            1 &&
                                                    index !== 0 &&
                                                    !canBeDragged(
                                                        tourToShow.driver,
                                                        step.OrderStepEnd
                                                            .IdUniverse,
                                                        step.Status,
                                                    )
                                                "
                                                icon="lock"
                                            ></fa-icon
                                            ><span v-else>
                                                {{
                                                    indexToDisplay(
                                                        tourToShow.steps.infos,
                                                        index,
                                                        tourToShow.showFinished,
                                                    )
                                                }}</span
                                            ></span
                                        >
                                        <span
                                            :style="
                                                setDragStyle(
                                                    index,
                                                    tourToShow.color.main.hex,
                                                    tourToShow.steps.infos
                                                        .length - 1,
                                                )
                                            "
                                            @dragover.prevent="
                                                handleDrag(index, tourToShow.id)
                                            "
                                            :draggable="
                                                $route.meta.isTour === false &&
                                                canAccess === true &&
                                                index !== 0 &&
                                                index <
                                                    tourToShow.steps.infos
                                                        .length -
                                                        1 &&
                                                canBeDragged(
                                                    tourToShow.driver,
                                                    step.OrderStepEnd
                                                        .IdUniverse,
                                                    step.Status,
                                                )
                                            "
                                            class="block max-w-full mr-3 rounded border p-3"
                                        >
                                            <div class="flex justify-between">
                                                <strong class="text-xs mr-1">
                                                    <span
                                                        v-if="
                                                            step.OrderStepEnd
                                                                .Status ===
                                                            'executed'
                                                        "
                                                    >
                                                        <fa-icon
                                                            icon="check"
                                                            class="mr-1"
                                                        ></fa-icon>
                                                        {{
                                                            step.OrderStepEnd
                                                                .AddressName
                                                        }}</span
                                                    ><span v-else>
                                                        {{
                                                            indexToDisplay(
                                                                tourToShow.steps
                                                                    .infos,
                                                                index,
                                                                tourToShow.showFinished,
                                                            ) +
                                                            ' - ' +
                                                            step.OrderStepEnd
                                                                .AddressName
                                                        }}
                                                    </span>
                                                    <span
                                                        v-if="
                                                            step.OrderStepEnd
                                                                .Type !== null
                                                        "
                                                        :style="`color: ${tourToShow.color.main.hex}`"
                                                        class="mx-1"
                                                    >
                                                        <fa-icon
                                                            :icon="
                                                                step
                                                                    .OrderStepEnd
                                                                    .Type ===
                                                                'pickup'
                                                                    ? 'house'
                                                                    : 'flag-checkered'
                                                            "
                                                            :title="
                                                                $t(
                                                                    step
                                                                        .OrderStepEnd
                                                                        .Type +
                                                                        '_label',
                                                                )
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                </strong>
                                                <span v-if="step.DateEnd">
                                                    <span
                                                        v-if="step.DateReal"
                                                        class="flex justify-end items-center"
                                                    >
                                                        <!-- <fa-icon
                                                            :icon="'clock'"
                                                        >
                                                        </fa-icon> -->
                                                        <img
                                                            :alt="
                                                                $t('finished')
                                                            "
                                                            svg-inline
                                                            src="/biiimIcons/check.svg"
                                                            class="mr-1"
                                                        />
                                                        <span
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time',
                                                                )
                                                            "
                                                            class="mr-1"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEnd,
                                                                )} - `
                                                            }}
                                                        </span>
                                                        <strong
                                                            :title="
                                                                $t(
                                                                    'real_deliv_time',
                                                                )
                                                            "
                                                            class="text-blue-500"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateReal,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                    <span
                                                        v-else-if="
                                                            step.DateEndNew
                                                        "
                                                        class="flex justify-end items-center"
                                                    >
                                                        <img
                                                            :alt="
                                                                $t(
                                                                    'in_progress',
                                                                )
                                                            "
                                                            svg-inline
                                                            :src="`/biiimIcons/${
                                                                isOnTime(
                                                                    step.DateEnd,
                                                                    step.DateEndNew,
                                                                )
                                                                    ? 'real-time-clock.svg'
                                                                    : 'clock_red.svg'
                                                            }`"
                                                            class="mr-1"
                                                        />
                                                        <span
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time',
                                                                )
                                                            "
                                                            class="mr-1"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEnd,
                                                                )} - `
                                                            }}
                                                        </span>
                                                        <strong
                                                            :class="`${
                                                                isOnTime(
                                                                    step.DateEnd,
                                                                    step.DateEndNew,
                                                                )
                                                                    ? 'success-msg'
                                                                    : 'error-msg'
                                                            }`"
                                                            :title="
                                                                $t(
                                                                    'software_calculated_time_new',
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEndNew,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="flex justify-end items-center"
                                                        :title="
                                                            $t(
                                                                'software_calculated_time',
                                                            )
                                                        "
                                                    >
                                                        <img
                                                            :alt="
                                                                $t(
                                                                    'in_progress',
                                                                )
                                                            "
                                                            svg-inline
                                                            src="/biiimIcons/real-time-clock.svg
                                                                    "
                                                            class="mr-1"
                                                        />

                                                        {{
                                                            `${formatStepTime(
                                                                step.DateEnd,
                                                            )} - `
                                                        }}
                                                        <strong
                                                            class="success-msg ml-1"
                                                        >
                                                            {{
                                                                `${formatStepTime(
                                                                    step.DateEnd,
                                                                )}`
                                                            }}
                                                        </strong>
                                                    </span>
                                                </span>
                                            </div>

                                            <div
                                                class="italic text-xs flex mt-1 justify-start"
                                            >
                                                <div
                                                    class="whitespace-nowrap"
                                                    style="
                                                        color: black !important;
                                                    "
                                                >
                                                    {{
                                                        `${step.OrderStepEnd.Address1} - ${step.OrderStepEnd.PostalCode} 
                                                        ${step.OrderStepEnd.City}`
                                                    }}
                                                </div>
                                            </div>
                                            <hr
                                                class="mt-2 mb-2 regular"
                                                v-if="
                                                    (step.WaitingTime &&
                                                        +step.WaitingTime !==
                                                            0) ||
                                                    step.OrderStepEnd.Moment
                                                " />
                                            <span
                                                class="flex items-center justify-between"
                                            >
                                                <!-- v-if="
                                                        step.OrderStepEnd
                                                            .DistanceFromLastStep &&
                                                        step.OrderStepEnd
                                                            .DurationFromLastStep
                                                    " -->
                                                <span
                                                    class="whitespace-nowrap flex mb-2 mr-1 items-center"
                                                    :title="
                                                        $t(
                                                            'dur_n_dist_last_step',
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :alt="
                                                            $t(
                                                                'dur_n_dist_last_step',
                                                            )
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/kilometre.svg
                                                                    "
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        formatDistance(
                                                            step.Distance,
                                                        ) +
                                                        ' km - ' +
                                                        formatDuration(
                                                            step.Duration,
                                                        )
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        step.WaitingTime &&
                                                        +step.WaitingTime !== 0
                                                    "
                                                    class="whitespace-nowrap flex mb-2 items-center"
                                                    :title="$t('waiting_time')"
                                                >
                                                    <img
                                                        :alt="
                                                            $t('waiting_time')
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/temps-attente.svg
                                                                    "
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        formatDuration(
                                                            +step.WaitingTime +
                                                                +step.WaitingTimeExtra,
                                                        )
                                                    }}
                                                </span>
                                            </span>

                                            <div
                                                v-if="step.OrderStepEnd.Moment"
                                                style="color: black !important"
                                                :title="$t('opening_hours')"
                                                class="text-xs items-center flex whitespace-nowrap"
                                            >
                                                <img
                                                    :alt="$t('opening_hours')"
                                                    svg-inline
                                                    src="/biiimIcons/clock.svg
                                                                    "
                                                    class="mr-1"
                                                />
                                                {{
                                                    `${
                                                        step.OrderStepEnd
                                                            .Moment !==
                                                        'between'
                                                            ? $t(
                                                                  step
                                                                      .OrderStepEnd
                                                                      .Moment,
                                                              )
                                                            : ''
                                                    }`
                                                }}
                                                {{
                                                    step.OrderStepEnd.Moment ===
                                                    'from'
                                                        ? formatStepTime(
                                                              step.OrderStepEnd
                                                                  .FromDateTime,
                                                          )
                                                        : step.OrderStepEnd
                                                                .Moment ===
                                                            'between'
                                                          ? formatStepTime(
                                                                step
                                                                    .OrderStepEnd
                                                                    .FromDateTime,
                                                            ) + ' - '
                                                          : ' '
                                                }}
                                                {{
                                                    step.OrderStepEnd.Moment ===
                                                        'before' ||
                                                    step.OrderStepEnd.Moment ===
                                                        'between'
                                                        ? formatStepTime(
                                                              step.OrderStepEnd
                                                                  .BeforeDateTime,
                                                          )
                                                        : ' '
                                                }}
                                            </div>
                                            <!-- (step.OrderStepEnd
                                                .DistanceFromLastStep &&
                                                step.OrderStepEnd
                                                    .DurationFromLastStep) || -->
                                            <hr
                                                class="mt-2 regular"
                                                v-if="
                                                    (step.WaitingTime &&
                                                        +step.WaitingTime !==
                                                            0) ||
                                                    step.OrderStepEnd.Moment
                                                " />

                                            <div
                                                class="flex mt-2 items-center justify-center"
                                            >
                                                <!-- A ajouter au if si dernière peut être placée en premier
                                             ||
                                                            (index === 0 &&
                                                                step.IdOrderStepEnd !==
                                                                    '0'))-->
                                                <div
                                                    v-if="
                                                        index !== 0 &&
                                                        index !==
                                                            tourToShow.steps
                                                                .infos.length -
                                                                1 &&
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center mr-2 flex"
                                                    @click="
                                                        changePosition(
                                                            tourToShow,
                                                            step.OrderStepEnd
                                                                .Id,
                                                            'first',
                                                            i,
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        :title="
                                                            $t('start_with')
                                                        "
                                                    >
                                                        <biiim-icon
                                                            :iconClass="'biiim-icon'"
                                                            iconSrc="/biiimIcons/chevron-double-up.svg"
                                                            :title="
                                                                $t('start_with')
                                                            "
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></biiim-icon>
                                                        <!-- <fa-icon
                                                            class="fa-sm p-1.5"
                                                            :icon="'angles-up'"
                                                            :title="
                                                                $t('start_with')
                                                            "
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></fa-icon> -->
                                                    </span>
                                                </div>
                                                <!-- a ajoute rau if si les etapes fin/debiuit peuvent etre cahngées de place
                                                 ||
                                                            (index === 0 &&
                                                                step.IdOrderStepEnd !==
                                                                    '0'))-->
                                                <div
                                                    v-if="
                                                        index !== 0 &&
                                                        index !==
                                                            tourToShow.steps
                                                                .infos.length -
                                                                1 &&
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center mr-2 flex"
                                                    @click="
                                                        changePosition(
                                                            tourToShow,
                                                            step.OrderStepEnd
                                                                .Id,

                                                            index,
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        :title="$t('one_up')"
                                                    >
                                                        <fa-icon
                                                            class="fa-sm p-1.5"
                                                            :icon="'chevron-up'"
                                                            :title="
                                                                $t('one_up')
                                                            "
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="
                                                        index <
                                                            tourToShow.steps
                                                                .infos.length -
                                                                1 &&
                                                        index <
                                                            tourToShow.steps
                                                                .infos.length -
                                                                2 &&
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center mr-2 flex"
                                                    @click="
                                                        changePosition(
                                                            tourToShow,
                                                            step.OrderStepEnd
                                                                .Id,
                                                            index + 2,
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        :title="$t('one_down')"
                                                    >
                                                        <fa-icon
                                                            :icon="'chevron-down'"
                                                            :title="
                                                                $t('one_down')
                                                            "
                                                            class="fa-md p-1.5"
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="
                                                        index <
                                                            tourToShow.steps
                                                                .infos.length -
                                                                1 &&
                                                        index <
                                                            tourToShow.steps
                                                                .infos.length -
                                                                2 &&
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center mr-2 flex"
                                                    @click="
                                                        changePosition(
                                                            tourToShow,
                                                            step.OrderStepEnd
                                                                .Id,
                                                            'last',
                                                            i,
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        :title="$t('end_with')"
                                                    >
                                                        <biiim-icon
                                                            :iconClass="'biiim-icon '"
                                                            iconSrc="/biiimIcons/chevron-double-down.svg"
                                                            :title="
                                                                $t('end_with')
                                                            "
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></biiim-icon>
                                                        <!-- <fa-icon
                                                            :icon="'angles-down'"
                                                            :title="
                                                                $t('end_with')
                                                            "
                                                            class="fa-sm p-1.5"
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></fa-icon> -->
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="
                                                        index !==
                                                            tourToShow.steps
                                                                .infos.length -
                                                                1 &&
                                                        index !== 0 &&
                                                        !canBeDragged(
                                                            tourToShow.driver,
                                                            step.OrderStepEnd
                                                                .IdUniverse,
                                                            step.Status,
                                                        )
                                                    "
                                                    class="item-center mr-2 flex italic text-xs"
                                                >
                                                    {{ $t('not_editable') }}
                                                </div>

                                                <div
                                                    v-if="
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true &&
                                                        canBeDragged(
                                                            tourToShow.driver,
                                                            step.OrderStepEnd
                                                                .IdUniverse,
                                                            step.Status,
                                                        )
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center mr-2 flex"
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        @click.stop="
                                                            showEditAddressModal(
                                                                step.OrderStepEnd,
                                                                index,
                                                                tourToShow.color,
                                                            )
                                                        "
                                                    >
                                                        <!-- @click="editStep()" -->
                                                        <fa-icon
                                                            :icon="'pen'"
                                                            :title="$t('edit')"
                                                            class="fa-sm p-1.5"
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></fa-icon
                                                    ></span>
                                                </div>
                                                <div
                                                    v-if="
                                                        index <
                                                            tourToShow.steps
                                                                .infos.length -
                                                                1 &&
                                                        $route.meta.isTour ===
                                                            false &&
                                                        canAccess === true &&
                                                        canBeDragged(
                                                            tourToShow.driver,
                                                            step.OrderStepEnd
                                                                .IdUniverse,
                                                            step.Status,
                                                        )
                                                    "
                                                    :class="
                                                        processing === true ||
                                                        calculationInProgress ===
                                                            true
                                                            ? 'cursor-wait'
                                                            : 'cursor-pointer '
                                                    "
                                                    class="item-center flex"
                                                >
                                                    <span
                                                        class="rounded-full h-6 w-6"
                                                        style="
                                                            background: #a1a1aa;
                                                        "
                                                        @click.stop="
                                                            unaffectStep(
                                                                step
                                                                    .OrderStepEnd
                                                                    .Id,
                                                            )
                                                        "
                                                    >
                                                        <fa-icon
                                                            :icon="'xmark'"
                                                            :title="
                                                                $t('unaffect')
                                                            "
                                                            class="fa-sm p-1.5"
                                                            style="
                                                                color: #f4f4f5 !important;
                                                            "
                                                        ></fa-icon
                                                    ></span>
                                                </div></div
                                        ></span>
                                    </li>
                                </ol>
                            </div>
                            <div v-else class="overflow-y-auto h-[74%]">
                                <!-- TODO affihcer les unaffected 2/ 2 -->
                                <ol
                                    v-for="(step, index) in unaffectedSteps"
                                    :id="`sortable-list-${index}`"
                                    :key="index"
                                    :draggable="
                                        $route.meta.isTour === false &&
                                        canAccess === true &&
                                        (!step.incomplete ||
                                            step.incomplete === false)
                                    "
                                    :class="`relative border-l ml-6 ${
                                        $route.meta.isTour === false &&
                                        canAccess === true &&
                                        processing === false
                                            ? 'cursor-grab'
                                            : ($route.meta.isTour === false &&
                                                    canAccess === true &&
                                                    processing === true) ||
                                                calculationInProgress === true
                                              ? 'cursor-wait'
                                              : 'cursor-default'
                                    }`"
                                    :style="`border-color: ${tourToShow.color.main.hex}; background:rgba(236, 240, 241, 0.85) !important;`"
                                >
                                    <li
                                        class="mb-1 ml-6"
                                        :style="`background: rgba(236, 240, 241, 0.85) !important; `"
                                        :draggable="
                                            $route.meta.isTour === false &&
                                            canAccess === true &&
                                            (!step.incomplete ||
                                                step.incomplete === false)
                                        "
                                        @dragstart="
                                            startDrag(
                                                $event,
                                                step,
                                                tourToShow.id,
                                                i,
                                                step.length
                                                    ? step[0].IdOrder
                                                    : step.IdOrder,
                                                index,
                                            )
                                        "
                                    >
                                        <span
                                            :style="`background: ${tourToShow.color.main.hex}; color: black !important`"
                                            class="flex absolute -left-3 justify-center items-center w-6 h-6 rounded-full"
                                            >{{ index + 1 }}</span
                                        >
                                        <span
                                            v-if="!step.length"
                                            :style="`border-color: ${tourToShow.color.main.hex}; color: black !important`"
                                            class="block max-w-full mr-3 mb-2 rounded border p-3"
                                        >
                                            <div class="flex justify-between">
                                                <strong class="text-xs mr-1">
                                                    {{
                                                        i +
                                                        1 +
                                                        ' - ' +
                                                        step.AddressName
                                                    }}

                                                    <span
                                                        :style="`color: ${tourToShow.color.main.hex}`"
                                                        class="mx-1"
                                                    >
                                                        <fa-icon
                                                            :icon="
                                                                step.Type ===
                                                                'pickup'
                                                                    ? 'house'
                                                                    : 'flag-checkered'
                                                            "
                                                            :title="
                                                                $t(
                                                                    step.Type +
                                                                        '_label',
                                                                )
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                </strong>
                                            </div>

                                            <div
                                                class="italic text-xs flex mt-1 justify-start"
                                            >
                                                <div
                                                    class="whitespace-nowrap"
                                                    style="
                                                        color: black !important;
                                                    "
                                                >
                                                    {{
                                                        `${step.Address1} - ${step.PostalCode} 
                                                        ${step.City}`
                                                    }}
                                                </div>
                                            </div>
                                            <hr
                                                class="mt-2 mb-2 regular"
                                                v-if="
                                                    (step.DistanceFromLastStep &&
                                                        step.DurationFromLastStep) ||
                                                    step.Moment
                                                "
                                            />
                                            <div class="flex items-center mb-2">
                                                <span
                                                    v-if="
                                                        step.DistanceFromLastStep &&
                                                        step.DurationFromLastStep
                                                    "
                                                    class="whitespace-nowrap flex mr-2 items-center"
                                                    :title="
                                                        $t(
                                                            'dur_n_dist_last_step',
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :alt="
                                                            $t(
                                                                'dur_n_dist_last_step',
                                                            )
                                                        "
                                                        svg-inline
                                                        src="/biiimIcons/kilometre.svg
                                                                    "
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        formatDistance(
                                                            step.DistanceFromLastStep,
                                                        ) +
                                                        ' km - ' +
                                                        formatDuration(
                                                            step.DurationFromLastStep,
                                                        )
                                                    }}
                                                </span>
                                            </div>

                                            <div
                                                v-if="step.Moment"
                                                style="color: black !important"
                                                class="text-xs items-center flex whitespace-nowrap"
                                                :title="$t('opening_hours')"
                                            >
                                                <img
                                                    :alt="$t('opening_hours')"
                                                    svg-inline
                                                    src="/biiimIcons/clock.svg
                                                                    "
                                                    class="mr-1"
                                                />
                                                {{
                                                    `${
                                                        step.Moment !==
                                                        'between'
                                                            ? $t(step.Moment)
                                                            : ''
                                                    }`
                                                }}
                                                {{
                                                    step.Moment === 'from'
                                                        ? formatStepTime(
                                                              step.FromDateTime,
                                                          )
                                                        : step.Moment ===
                                                            'between'
                                                          ? formatStepTime(
                                                                step.FromDateTime,
                                                            ) + ' - '
                                                          : ' '
                                                }}
                                                {{
                                                    step.Moment === 'before' ||
                                                    step.Moment === 'between'
                                                        ? formatStepTime(
                                                              step.BeforeDateTime,
                                                          )
                                                        : ' '
                                                }}
                                            </div>
                                            <hr
                                                class="mt-2 mb-2 regular"
                                                v-if="
                                                    (step.DistanceFromLastStep &&
                                                        step.DurationFromLastStep) ||
                                                    step.Moment
                                                "
                                            />
                                            <div
                                                v-if="
                                                    $route.meta.isTour ===
                                                        false &&
                                                    canAccess === true
                                                "
                                                class="warning-msg mb-1 font-semibold whitespace-nowrap cursor-pointer"
                                                @click.stop="
                                                    toUpdateOrder(step.IdOrder)
                                                "
                                            >
                                                <fa-icon
                                                    :title="$t('steps_errors')"
                                                    icon="warning"
                                                    class="warning-msg fa-lg mr-1"
                                                />

                                                {{ $t('solo_step') }}
                                                {{ $t('check_order') }}
                                            </div>

                                            <div
                                                v-if="
                                                    step.incomplete &&
                                                    step.incomplete === true &&
                                                    $route.meta.isTour ===
                                                        false &&
                                                    canAccess === true
                                                "
                                                class="error-msg mt-1 font-semibold whitespace-nowrap cursor-pointer"
                                                @click.stop="toUpdateStep(step)"
                                            >
                                                <fa-icon
                                                    :title="$t('steps_errors')"
                                                    icon="warning"
                                                    class="ko-link fa-lg mr-1"
                                                />

                                                {{
                                                    $t('step_coordinates_error')
                                                }}
                                            </div>
                                        </span>
                                        <span
                                            v-else
                                            v-for="(stp, i) in step"
                                            :key="i"
                                            :style="`border-color: ${tourToShow.color.main.hex}; color: black !important`"
                                            class="block max-w-full mr-3 mb-2 rounded border p-3"
                                        >
                                            <div class="flex justify-between">
                                                <strong class="text-xs mr-1">
                                                    {{
                                                        (i === 0
                                                            ? index + 1
                                                            : index + 1 + "'") +
                                                        ' - ' +
                                                        stp.AddressName
                                                    }}
                                                    <span
                                                        :style="`color: ${tourToShow.color.main.hex}`"
                                                        class="mx-1"
                                                    >
                                                        <fa-icon
                                                            :icon="
                                                                stp.Type ===
                                                                'pickup'
                                                                    ? 'house'
                                                                    : 'flag-checkered'
                                                            "
                                                            :title="
                                                                $t(
                                                                    stp.Type +
                                                                        '_label',
                                                                )
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                </strong>
                                            </div>

                                            <div
                                                class="italic text-xs flex mt-1 justify-start"
                                            >
                                                <div
                                                    class="whitespace-nowrap"
                                                    style="
                                                        color: black !important;
                                                    "
                                                >
                                                    {{
                                                        `${stp.Address1} - ${stp.PostalCode} 
                                                        ${stp.City}`
                                                    }}
                                                </div>
                                            </div>
                                            <hr
                                                class="mt-2 mb-2 regular"
                                                v-if="
                                                    (stp.DistanceFromLastStep &&
                                                        stp.DurationFromLastStep) ||
                                                    stp.Moment
                                                "
                                            />

                                            <span
                                                v-if="
                                                    stp.DistanceFromLastStep &&
                                                    stp.DurationFromLastStep
                                                "
                                                class="whitespace-nowrap mb-2 flex mr-2 items-center"
                                                :title="
                                                    $t('dur_n_dist_last_step')
                                                "
                                            >
                                                <img
                                                    :alt="
                                                        $t(
                                                            'dur_n_dist_last_step',
                                                        )
                                                    "
                                                    svg-inline
                                                    src="/biiimIcons/kilometre.svg
                                                                    "
                                                    class="mr-1"
                                                />
                                                {{
                                                    formatDistance(
                                                        stp.DistanceFromLastStep,
                                                    ) +
                                                    ' km - ' +
                                                    formatDuration(
                                                        stp.DurationFromLastStep,
                                                    )
                                                }}
                                            </span>

                                            <div
                                                v-if="stp.Moment"
                                                style="color: black !important"
                                                class="text-xs items-center flex whitespace-nowrap"
                                                :title="$t('opening_hours')"
                                            >
                                                <img
                                                    :alt="$t('opening_hours')"
                                                    svg-inline
                                                    src="/biiimIcons/clock.svg
                                                                    "
                                                    class="mr-1"
                                                />
                                                {{
                                                    `${
                                                        stp.Moment !== 'between'
                                                            ? $t(stp.Moment)
                                                            : ''
                                                    }`
                                                }}
                                                {{
                                                    stp.Moment === 'from'
                                                        ? formatStepTime(
                                                              stp.FromDateTime,
                                                          )
                                                        : stp.Moment ===
                                                            'between'
                                                          ? formatStepTime(
                                                                stp.FromDateTime,
                                                            ) + ' - '
                                                          : ' '
                                                }}
                                                {{
                                                    stp.Moment === 'before' ||
                                                    stp.Moment === 'between'
                                                        ? formatStepTime(
                                                              stp.BeforeDateTime,
                                                          )
                                                        : ' '
                                                }}
                                            </div>
                                            <hr
                                                class="mt-2 mb-2 regular"
                                                v-if="
                                                    (stp.DistanceFromLastStep &&
                                                        stp.DurationFromLastStep) ||
                                                    stp.Moment
                                                "
                                            />
                                            <div
                                                v-if="
                                                    stp.incomplete &&
                                                    stp.incomplete === true &&
                                                    $route.meta.isTour ===
                                                        false &&
                                                    canAccess === true
                                                "
                                                class="error-msg mt-1 font-semibold whitespace-nowrap cursor-pointer"
                                                @click.stop="toUpdateStep(stp)"
                                            >
                                                <fa-icon
                                                    :title="$t('steps_errors')"
                                                    icon="warning"
                                                    class="ko-link fa-lg mr-1"
                                                />

                                                {{
                                                    $t('step_coordinates_error')
                                                }}
                                            </div>
                                        </span>
                                    </li>
                                </ol>
                            </div>
                        </span>
                    </div>
                </div>
                <div>
                    <!-- TODO la pastille reste why?? -->
                    <span
                        v-if="displayBottomBlock === true"
                        class="over-map h-[73%] flex justify-end border-2 rounded overscroll-x-contain overflow-auto"
                    >
                        <!-- style="height: 550px" -->
                        <ol class="relative">
                            <li
                                v-for="(tourToShow, i) in toursToShow"
                                :key="i"
                                @click="
                                    ;(tourToShow.open = !tourToShow.open) &
                                        openPanel(tourToShow)
                                "
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                class="my-4 ml-6"
                            >
                                <span
                                    class="flex absolute -left-3 justify-center items-center w-6 h-6 rounded-full"
                                    :class="`${tourToShow.color.main.bg} hover:${tourToShow.color.secondary.bg}`"
                                >
                                    <fa-icon
                                        icon="circle-arrow-left"
                                        class="fa-md py-4 px-4"
                                        style="color: black"
                                    ></fa-icon>
                                </span>
                                <br />
                            </li>
                        </ol>
                    </span>
                    <div
                        v-if="toursToShow.length > 0"
                        :class="
                            processing === true ||
                            calculationInProgress === true
                                ? 'cursor-wait'
                                : 'cursor-pointer '
                        "
                        class="row bg-black over-map text-zinc-400 justify-center border-2 rounded h-6 items-center flex"
                        @click="displayBottomBlock = !displayBottomBlock"
                    >
                        <fa-icon
                            :icon="
                                displayBottomBlock === false
                                    ? 'angles-down'
                                    : 'angles-up'
                            "
                            class="fa-md py-4 px-4"
                        ></fa-icon>
                    </div>
                </div>
            </div>
            <modal
                v-if="editTourModal"
                :buttonText="'save'"
                :buttonAlign="'justify-center'"
                id="editTourModal"
                :creating="
                    processing === true || calculationInProgress === true
                "
                @closeModal="closeEditTourModal()"
                :buttonStyle="`background-color:${tourToUpdateForm.color.main.hex} !important;`"
                @sendFromModal="submitEditTourModal(tourToUpdateForm.id)"
            >
                <template #body>
                    <div
                        class="pt-3 flex justify-end pr-3"
                        :class="
                            processing === true ||
                            calculationInProgress === true
                                ? 'cursor-wait'
                                : 'cursor-pointer '
                        "
                    >
                        <span
                            class="rounded-full h-6 w-6 item-center flex"
                            @click.prevent="closeEditTourModal()"
                            :style="`background: ${tourToUpdateForm.color.main.hex}; color: ${tourToUpdateForm.color.secondary.hex} !important `"
                        >
                            <fa-icon
                                icon="xmark"
                                class="my-auto mx-auto"
                            ></fa-icon>
                        </span>
                    </div>
                    <div>
                        {{ tourToUpdateForm.driver.name }}
                    </div>

                    <div
                        class="italic flex items-center mb-1 justify-center"
                        v-if="tourToUpdateForm.vehicle.type !== ''"
                    >
                        <fa-icon
                            v-if="
                                tourToUpdateForm.vehicle.type === 'car' ||
                                tourToUpdateForm.vehicle.type === 'ship' ||
                                tourToUpdateForm.vehicle.type === 'truck' ||
                                tourToUpdateForm.vehicle.type ===
                                    'person-biking'
                            "
                            :icon="tourToUpdateForm.vehicle.type"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        <img
                            v-else
                            svg-inline
                            :src="`/biiimIcons/${tourToUpdateForm.vehicle.type}.svg`"
                            class="mr-2 darky icon-img"
                            :class="
                                tourToUpdateForm.vehicle.type === 'van'
                                    ? 'w-4'
                                    : 'w-7 '
                            "
                        />{{ tourToUpdateForm.vehicle.name }}
                    </div>

                    <div
                        class="italic text-xs flex-wrap mx-2 mb-6 font-semibold"
                    >
                        {{
                            tourToUpdateForm.pts +
                            ' - ' +
                            formatDistance(tourToUpdateForm.distance) +
                            ' km - ' +
                            formatDuration(tourToUpdateForm.duration)
                        }}
                    </div>
                    <!-- ' - ' +
                            tourToUpdateForm.weight + -->
                    <div class="px-6 flex font-medium">
                        {{ $t('change_start_time') }}
                    </div>
                    <div class="my-3 px-6">
                        <div class="flex items-center mr-4">
                            <input
                                id="2-radio"
                                type="radio"
                                v-model="timeDetermination"
                                value="software"
                                checked
                                name="inline-radio-group"
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                :style="`border: 1px solid ${tourToUpdateForm.color.main.hex} !important;`"
                            />
                            <!-- class="h-4 w-4 border border-gray-300 bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" -->
                            <label
                                for="2-radio"
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                class="ml-2 text-xs"
                                >{{ $t('time_determined_by_software') }}</label
                            >
                        </div>
                        <div class="flex items-center mr-4 pt-2">
                            <input
                                id="radio"
                                type="radio"
                                v-model="timeDetermination"
                                value="driver"
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                name="inline-radio-group"
                                :style="`border: 1px solid ${tourToUpdateForm.color.main.hex} !important;`"
                            />
                            <label
                                for="radio"
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                class="ml-2 text-xs"
                                >{{ $t('time_determined_by_driver') }}</label
                            >
                        </div>
                        <div class="flex items-center mr-4 pt-2">
                            <input
                                id="3-radio"
                                type="radio"
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                v-model="timeDetermination"
                                value="manual"
                                name="inline-radio-group"
                                :style="`border: 1px solid ${tourToUpdateForm.color.main.hex} !important;`"
                            />
                            <label
                                for="3-radio"
                                :class="
                                    processing === true ||
                                    calculationInProgress === true
                                        ? 'cursor-wait'
                                        : 'cursor-pointer '
                                "
                                class="ml-2 text-xs"
                                >{{ $t('time_determined_custom') }}</label
                            >
                        </div>
                    </div>
                    <div
                        v-if="timeDetermination === 'manual'"
                        class="mx-4 items-center flex flex-row"
                    >
                        <div class="flex mt-2 px-2 max-w-full w-full">
                            <div
                                class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                            >
                                <fa-icon
                                    :icon="'clock'"
                                    class="fa-sm"
                                ></fa-icon>
                            </div>
                            <input
                                type="datetime-local"
                                v-model="tourTime"
                                :min="minDate"
                                :max="maxDate"
                                class="border input-custom-round w-full max-w-full px-3 py-2"
                            />
                        </div>
                    </div>
                    <div class="flex mt-3 px-6 font-medium">
                        {{ $t('assign_driver') }}
                    </div>

                    <div class="mb-2 ms-width items-center flex flex-row">
                        <div class="flex mt-2 px-6 max-w-full w-full">
                            <div
                                class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                            >
                                <fa-icon :icon="'user'" class="fa-sm"></fa-icon>
                            </div>
                            <Multiselect
                                class="h-10 border-t border-l border-b rounded-l px-3 py-2 darky"
                                label="name"
                                v-model="tourToUpdateForm.driver.id"
                                :key="refreshMs"
                                :options="driverOptions"
                                track-by="name"
                                :searchable="true"
                                :allow-empty="true"
                                :can-clear="true"
                            ></Multiselect>
                        </div>

                        <!-- @select="setAddressInfo(deliveryAddressId)" -->
                    </div>
                    <div class="flex px-6 font-medium">
                        {{ $t('assign_other_driver') }}
                    </div>

                    <div class="mb-2 ms-width items-center flex flex-row">
                        <div class="flex mt-2 px-6 max-w-full w-full">
                            <div
                                class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                            >
                                <fa-icon
                                    :icon="'user-plus'"
                                    class="fa-sm"
                                ></fa-icon>
                            </div>
                            <Multiselect
                                class="h-10 px-3 py-2 darky"
                                label="name"
                                track-by="name"
                                :key="refreshMs"
                                v-model="tourToUpdateForm.co_driver.id"
                                :options="driverOptions"
                                :searchable="true"
                                :allow-empty="true"
                                :can-clear="true"
                            ></Multiselect>
                        </div>
                    </div>
                    <!-- @select="setAddressInfo(deliveryAddressId)" -->
                    <div class="flex px-6 font-medium">
                        {{ $t('assign_vehicle') }}
                    </div>

                    <div class="mb-2 ms-width items-center flex flex-row">
                        <div class="flex mt-1 px-6 max-w-full w-full">
                            <div
                                class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                            >
                                <fa-icon
                                    :icon="'truck-fast'"
                                    class="fa-sm"
                                ></fa-icon>
                            </div>
                            <!-- v-model="deliveryAddressId"
                            :options="addressOptions" -->
                            <Multiselect
                                class="h-10 px-3 py-2 darky"
                                label="name"
                                :key="refreshMs"
                                track-by="name"
                                :options="vehicleOptions"
                                v-model="tourToUpdateForm.vehicle.id"
                                :searchable="true"
                                :allow-empty="true"
                                :can-clear="true"
                            ></Multiselect>
                        </div>
                        <!-- @select="setAddressInfo(deliveryAddressId)" -->
                    </div>
                </template>
            </modal>
            <modal
                v-if="editAddressModal"
                :buttonText="'save'"
                :buttonAlign="'justify-center'"
                id="editAddressModal"
                :secondSubmitText="'save_and_relaunch'"
                :secondSubmit="!$route.meta.daily"
                :creating="
                    processing === true || calculationInProgress === true
                "
                @closeModal="closeEditAddressModal()"
                :buttonStyle="`background-color:${stepToUpdateForm.color.main.hex} !important;`"
                :secondButtonStyle="`background-color:${stepToUpdateForm.color.main.hex} !important;`"
                @sendFromModal="updateStp($event)"
            >
                <template #body>
                    <div
                        class="pt-3 flex justify-end pr-3"
                        :class="
                            processing === true ||
                            calculationInProgress === true
                                ? 'cursor-wait'
                                : 'cursor-pointer '
                        "
                    >
                        <span
                            class="rounded-full h-6 w-6 item-center flex"
                            @click.prevent="closeEditAddressModal()"
                            :style="`background: ${stepToUpdateForm.color.main.hex}; color: ${stepToUpdateForm.color.secondary.hex} !important `"
                        >
                            <fa-icon
                                icon="xmark"
                                class="my-auto mx-auto"
                            ></fa-icon>
                        </span>
                    </div>
                    <div class="font-medium text-xl">
                        {{ stepToUpdateForm.title }}
                    </div>

                    <div class="px-6 py-3 flex text-base font-medium">
                        {{ $t('update_address') }}
                    </div>

                    <label class="text-xs py-1 ml-6 flex justify-start"
                        >{{ $t('name') + ' *' }}
                    </label>
                    <div class="mb-2 ms-width items-center flex flex-row">
                        <div class="flex px-6 max-w-full w-full">
                            <div
                                class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                            >
                                <fa-icon :icon="'user'" class="fa-sm"></fa-icon>
                            </div>
                            <input
                                type="text"
                                autocomplete="name"
                                @blur="v$.stepToUpdateForm.Name.$touch"
                                class="border input-custom-round w-full max-w-full px-3 py-2"
                                v-model="stepToUpdateForm.Name"
                            />
                        </div>
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.stepToUpdateForm.Name.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <label class="text-xs py-1 ml-6 flex justify-start"
                        >{{ $t('address') + ' *' }}
                    </label>

                    <div class="mb-2 ms-width items-center flex flex-row">
                        <div class="flex px-6 max-w-full w-full">
                            <div
                                class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                            >
                                <fa-icon
                                    :icon="'house'"
                                    class="fa-sm"
                                ></fa-icon>
                            </div>
                            <input
                                type="text"
                                id="autocomplete"
                                @blur="v$.stepToUpdateForm.Address1.$touch"
                                class="border input-custom-round w-full max-w-full px-3 py-2"
                                v-model="stepToUpdateForm.Address1"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.stepToUpdateForm.Address1
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <label class="text-xs py-1 ml-6 flex justify-start"
                        >{{ $t('address') }}
                    </label>
                    <div class="mb-2 ms-width items-center flex flex-row">
                        <div class="flex px-6 max-w-full w-full">
                            <div
                                class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                            >
                                <fa-icon
                                    :icon="'house'"
                                    class="fa-sm"
                                ></fa-icon>
                            </div>
                            <input
                                type="text"
                                class="border input-custom-round w-full max-w-full px-3 py-2"
                                v-model="stepToUpdateForm.Address2"
                            />
                        </div>
                    </div>
                    <!-- @select="setAddressInfo(deliveryAddressId)" -->
                    <div class="grid grid-cols-2">
                        <span class="col-span-1">
                            <label class="text-xs py-1 ml-6 flex justify-start"
                                >{{ $t('postal_code') + ' *' }}
                            </label>

                            <div
                                class="mb-2 ms-width items-center flex flex-row"
                            >
                                <div class="flex px-6 max-w-full w-full">
                                    <div
                                        class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                                    >
                                        <fa-icon
                                            :icon="'city'"
                                            class="fa-sm"
                                        ></fa-icon>
                                    </div>
                                    <input
                                        type="text"
                                        autocomplete="postal-code"
                                        @blur="
                                            v$.stepToUpdateForm.PostalCode.$touch()
                                        "
                                        class="border input-custom-round w-full max-w-full px-3 py-2"
                                        v-model="stepToUpdateForm.PostalCode"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.stepToUpdateForm
                                        .PostalCode.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </span>
                        <span class="col-span-1">
                            <label class="text-xs py-1 ml-6 flex justify-start"
                                >{{ $t('city') + ' *' }}
                            </label>
                            <div class="flex px-6 max-w-full w-full">
                                <input
                                    type="text"
                                    autocomplete="city"
                                    @blur="v$.stepToUpdateForm.City.$touch"
                                    class="border rounded w-full max-w-full px-3 py-2"
                                    v-model="stepToUpdateForm.City"
                                />
                            </div>

                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.stepToUpdateForm.City
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </span>
                    </div>
                    <label class="text-xs py-1 ml-6 flex justify-start"
                        >{{ $t('on') + ' *' }}
                    </label>

                    <div class="mb-2 ms-width items-center flex flex-row">
                        <div class="flex px-6 max-w-full w-full">
                            <div
                                class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                            >
                                <fa-icon
                                    :icon="'calendar'"
                                    class="fa-sm"
                                ></fa-icon>
                            </div>
                            <input
                                type="date"
                                :min="date.toISOString().substr(0, 10)"
                                v-model="stepToUpdateForm.date"
                                @blur="v$.stepToUpdateForm.date.$touch"
                                class="border input-custom-round w-full max-w-full px-3 py-2"
                            />
                        </div>
                    </div>

                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.stepToUpdateForm.date.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>

                    <div class="grid grid-cols-3 items-center">
                        <span class="col-span-1">
                            <div class="flex px-6 max-w-full w-full">
                                <Multiselect
                                    class="h-10 rounded px-3 py-2 w-full darky"
                                    v-model="stepToUpdateForm.Moment"
                                    :options="stepMomentOptions"
                                    label="name"
                                    track-by="name"
                                    :allow-empty="false"
                                    :can-clear="false"
                                ></Multiselect>
                            </div>

                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.stepToUpdateForm.Moment
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </span>
                        <span
                            v-if="
                                stepToUpdateForm.Moment === 'between' ||
                                stepToUpdateForm.Moment === 'from'
                            "
                            :class="
                                stepToUpdateForm.Moment === 'between'
                                    ? 'col-span-1'
                                    : 'col-span-2'
                            "
                        >
                            <div
                                class="mb-2 ms-width items-center flex flex-row"
                            >
                                <div class="flex mt-1 px-6 max-w-full w-full">
                                    <div
                                        class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                                    >
                                        <fa-icon
                                            :icon="'clock'"
                                            class="fa-sm"
                                        ></fa-icon>
                                    </div>
                                    <input
                                        @blur="
                                            v$.stepToUpdateForm.fromTime.$touch
                                        "
                                        v-model="stepToUpdateForm.fromTime"
                                        @input="badTimeRange = false"
                                        type="time"
                                        class="border input-custom-round w-full max-w-full px-3 py-2"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.stepToUpdateForm.fromTime
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </span>
                        <span
                            v-if="
                                stepToUpdateForm.Moment === 'between' ||
                                stepToUpdateForm.Moment === 'before'
                            "
                            :class="
                                stepToUpdateForm.Moment === 'between'
                                    ? 'col-span-1'
                                    : 'col-span-2'
                            "
                        >
                            <div
                                class="mb-2 ms-width items-center flex flex-row"
                            >
                                <div class="flex mt-1 px-6 max-w-full w-full">
                                    <div
                                        class="w-12 flex items-center justify-center border-t border-l border-b rounded-l"
                                    >
                                        <fa-icon
                                            :icon="'clock'"
                                            class="fa-sm"
                                        ></fa-icon>
                                    </div>
                                    <input
                                        @blur="
                                            v$.stepToUpdateForm.beforeTime.$touch()
                                        "
                                        v-model="stepToUpdateForm.beforeTime"
                                        @input="badTimeRange = false"
                                        type="time"
                                        class="border input-custom-round w-full max-w-full px-3 py-2"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.stepToUpdateForm
                                        .beforeTime.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="text-xs italic mt-1 mb-2" v-if="badTimeRange">
                        <div class="error-msg">
                            {{ $t('bad_time_range') }}
                        </div>
                    </div>
                    <span
                        class="flex mt-1 italic text-xs items-center justify-center"
                    >
                        {{ $t('required_fields') }}
                    </span>
                    <div class="flex items-center justify-center mt-4">
                        <input
                            id="ref-add-update-checkbox"
                            type="checkbox"
                            v-model="stepToUpdateForm.UpdateRefAddress"
                            class="w-4 h-4 bg-gray-100 rounded border-gray-300"
                        />
                        <label
                            for="ref-add-update-checkbox"
                            class="ml-2 text-sm font-medium"
                            >{{ $t('update_ref_address') }}</label
                        >
                    </div>

                    <!-- @select="setAddressInfo(deliveryAddressId)" -->
                </template>
            </modal>
            <modal
                v-if="showDeleteConfirmModal"
                :headerText="deleteModalHeader"
                :buttonText="'confirm'"
                id="deletemodal"
                :mxWidth="'max-w-2xl'"
                :buttonAlign="'justify-center'"
                @closeModal="userDeleteConfirmation(false)"
                @sendFromModal="userDeleteConfirmation(true)"
            >
                <template #body>
                    <div class="mx-8">
                        <div class="flex justify-center mt-8">
                            {{ $t(deleteModalText) }}
                        </div>
                    </div></template
                ></modal
            >
            <tour-opti-warning-modal
                v-if="showConfirmTourOptiModal"
                :confirmModalText="confirmModalText"
                @tourOptiConfirmation="actionConfirmation($event)"
            ></tour-opti-warning-modal>
            <modal
                v-if="showErrorModal"
                :headerText="'unable_to_integrate_simulation'"
                :buttonText="'got_it'"
                :canCancel="false"
                id="errorModal"
                :mxWidth="''"
                :buttonAlign="'justify-center'"
                @closeModal="showErrorModal = false"
                @sendFromModal="showErrorModal = false"
            >
                <template #body>
                    <div class="mx-8 grid grid-cols-8 mt-4">
                        <div class="col-span-1">
                            <fa-icon
                                :icon="'exclamation-triangle'"
                                class="fa-2x text-red-500"
                            ></fa-icon>
                        </div>
                        <div class="col-span-7">
                            <div class="flex justify-center">
                                {{ $t('sim_integration_error_text_1') }}
                            </div>
                            <div class="flex justify-center font-semibold mt-2">
                                {{ $t('sim_integration_error_text_2') }}
                            </div>
                            <div class="flex justify-center mt-6">
                                {{ $t('contact_biiim') }}
                            </div>
                        </div>
                    </div></template
                ></modal
            >
        </div>
    </main>
</template>
<script>
import gmapStyles from '@/mixins/gmapStyles'
import googleScriptLoader from '@/mixins/googleScript'
import stepsCorrections from '@/mixins/mapViewCorrection'
import slider from '@/mixins/slider'
import { useDriverStore } from '@/stores/driverStore'
import { useSimulationStore } from '@/stores/simulationStore'
import { useVehicleStore } from '@/stores/vehicleStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

import BiiimIcon from '@/components/atoms/BiiimIcon.vue'
import Modal from '@/components/atoms/Modal.vue'
import CheckIcon from '@/components/elements/CheckIcon.vue'
import InProgressIcon from '@/components/elements/InProgressIcon.vue'
import TourOptiWarningModal from '@/components/elements/TourOptiWarningModal.vue'
import TraceIcon from '@/components/elements/TraceIcon.vue'
import converter from '@/mixins/converter'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useOrderStore } from '@/stores/orderStore'
import { useTourStore } from '@/stores/tourStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
)
const timeIsValid = helpers.regex(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)

const dateIsValid = helpers.regex(
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
)

let polygons = []
const lines = []
let markers = []
// let map = null

export default {
    name: 'MapView',
    components: {
        Modal,
        Multiselect,
        InProgressIcon,
        TraceIcon,
        CheckIcon,
        BiiimIcon,
        TourOptiWarningModal,
    },
    inject: ['eventBus'],
    mixins: [
        slider,
        googleScriptLoader,
        gmapStyles,
        converter,
        stepsCorrections,
    ],
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            tourOrigin: null,
            oldIndex: null,
            showConfirmTourOptiModal: false,
            confirmModalText: 'confirm_modal_text',
            confirmAction: null,

            minDate: `${new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000,
            )
                .toISOString()
                .substr(0, 11)}00:00`,
            maxDate: `${new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000,
            )
                .toISOString()
                .substr(0, 11)}23:59`,
            colorToUseForClickedMarkerIndex: 0,
            canReset: true,
            notExecutedSteps: [],
            showErrorModal: false,
            notRecalc: false,
            shownFinished: [],
            shownTraces: [],
            universesForDriverSearch: [],
            openedTours: [],
            shownTours: [],
            refreshMs: 0,
            showDeleteConfirmModal: false,
            confirmDelete: null,
            deleteModalHeader: '',
            deleteModalText: '',
            driverPosInt: null,
            dailyInterval: null,
            showUnaffected: false,
            tourTime: '',
            calculationInProgress: false,
            mapLoaded: false,
            date: new Date(),
            badTimeRange: false,
            editTourModal: false,
            editAddressModal: false,
            processing: false,
            toInnerIndex: null,
            driverOptions: [],
            vehicleOptions: [],
            timeDeterminationOptions: [
                { id: 'radio', value: 'driver' },
                { id: '2-radio', value: 'software' },
                { id: '3-radio', value: 'manual' },
            ],
            stepMomentOptions: [
                { name: this.$t('between'), value: 'between' },
                { name: this.$t('before'), value: 'before' },
                { name: this.$t('from'), value: 'from' },
            ],
            timeDetermination: 'software',
            refresh: 0,
            draggedElements: [],
            // tourCards: [],
            toursToShow: [],
            tourToUpdateForm: {},
            stepToUpdateForm: {},
            displayTopBlock: true,
            displayBottomBlock: false,
            universeFilter: {},
        }
    },
    validations() {
        return {
            // tourToUpdateForm: {
            //     IdDriver: { required },
            //     IdVehicle: { required },
            // ajouter l'input de time required if le choix c'est manual
            // },
            stepToUpdateForm: {
                Name: { required },
                Address1: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },

                City: { required },
                Moment: { required },
                fromTime: {
                    required,
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                beforeTime: {
                    required,
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                date: {
                    required,
                    dateIsValid: helpers.withMessage(
                        this.$t('bad_date_format'),
                        dateIsValid,
                    ),
                },
            },
        }
    },

    created() {
        this.toursToShow = []
        this.displayTopBlock = true
        this.displayBottomBlock = false
        this.hidePOIS = true

        polygons = []
        markers = []
        this.map = null
    },
    mounted() {
        clearInterval(this.dailyInterval)
        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        this.showErrorModal = false
        this.universeFilter = {}
        const universesInterval = setInterval(() => {
            if (this.universesOptions.length > 0) {
                clearInterval(universesInterval)
                this.universeFilter = {
                    dbField: 'TourByUniverse',
                    value:
                        this.selectedUniverses.length > 0
                            ? this.selectedUniverses
                            : this.universesOptions,
                }

                this.universesForDriverSearch = []

                // TODO V2RIFIER QUE 9A FONCTIONNE
                this.universeFilter.value.forEach((universe) => {
                    this.universesForDriverSearch.push(universe.value)
                })
                if (
                    this.$route.meta.isTour === false &&
                    this.canAccess === true
                ) {
                    const availableVehiclesAndDriversInterval = setInterval(
                        () => {
                            if (this.current !== null) {
                                clearInterval(
                                    availableVehiclesAndDriversInterval,
                                )
                                // this.userStore.userUniverses.forEach((universe) => {
                                //     this.universesForDriverSearch.push(universe.value)
                                // })
                                this.availableDriversForDate({
                                    date: this.current.DateBegin,
                                    universes: this.universesForDriverSearch,
                                }).then((res) => {
                                    if (res && res.status === 200 && res.data) {
                                        res.data.forEach((driver) => {
                                            const name = `${driver.FirstName} ${driver.LastName}`
                                            const option = {
                                                name,
                                                value: +driver.Id,
                                            }
                                            this.driverOptions.push(option)
                                        })
                                    }
                                })

                                this.availableVehiclesForDate(
                                    this.current.DateBegin,
                                ).then((res) => {
                                    res.data.forEach((vehicle) => {
                                        const name = `${
                                            vehicle.Name
                                        } - ${this.$t(vehicle.Type)}`
                                        const option = {
                                            name,
                                            value: +vehicle.Id,
                                        }
                                        this.vehicleOptions.push(option)
                                    })
                                })
                            }
                        },
                        1000,
                    )
                }

                this.loadGoogleScript()
                const googleInterval = setInterval(() => {
                    if (google) {
                        this.mapLoaded = true
                        clearInterval(googleInterval)

                        this.map = new google.maps.Map(
                            document.getElementById('map'),
                            {
                                zoom: 11,
                                center: {
                                    lat: this.userStore.current.env.latitude,
                                    lng: this.userStore.current.env.longitude,
                                },
                                //   gestureHandling: "cooperative",
                                mapTypeControl: true,
                                mapTypeControlOptions: {
                                    style: google.maps.MapTypeControlStyle
                                        .DROPDOWN_MENU,
                                    position:
                                        google.maps.ControlPosition.LEFT_CENTER,
                                },
                                zoomControl: true,
                                zoomControlOptions: {
                                    position:
                                        google.maps.ControlPosition.LEFT_BOTTOM,
                                },
                                scaleControl: true,
                                streetViewControl: true,
                                streetViewControlOptions: {
                                    position:
                                        google.maps.ControlPosition.LEFT_BOTTOM,
                                },
                            },
                        )
                        // this.map.setOptions({ styles: this.styles.hide }) // hides google markers / pois
                        this.setGoogleMapStyle(false)

                        // START ADD BACK BUTTON
                        // if (this.isMobile === true) {
                        const backButton = document.createElement('button')
                        backButton.classList.add(
                            'font-semibold',
                            'flex',
                            'items-center',
                            // 'rounded',
                            'text-lg',
                            'py-2',
                            'theme-color',
                            'px-4',
                            'ml-5',
                            'mt-8',
                        )
                        const back = `${this.$t('back')}`
                        const btnContent = document.createTextNode(back)
                        backButton.appendChild(btnContent)
                        this.map.controls[
                            google.maps.ControlPosition.LEFT_CENTER
                        ].push(backButton)
                        backButton.addEventListener('click', (e) => {
                            this.back()
                        })
                        // }
                        // END BACK BUTTON

                        const childInterval = setInterval(() => {
                            if (document.getElementById('map')) {
                                if (
                                    document
                                        .getElementById('map')
                                        .getElementsByTagName('div')[0]
                                ) {
                                    const mapDiv = document
                                        .getElementById('map')
                                        .getElementsByTagName('div')[0]
                                    if (
                                        document.getElementById(
                                            'custom-panel',
                                        ) &&
                                        document.getElementById(
                                            'custom-panel-2',
                                        )
                                    ) {
                                        const panelTop =
                                            document.getElementById(
                                                'custom-panel',
                                            )
                                        const panelBottom =
                                            document.getElementById(
                                                'custom-panel-2',
                                            )

                                        clearInterval(childInterval)

                                        mapDiv.appendChild(panelTop)

                                        mapDiv.appendChild(panelBottom)
                                    }
                                }
                            }
                        }, 10)
                        this.setSlider()
                        // this.setUnaffectedMarkers()
                        if (this.$route.meta.daily === true) {
                            this.fetchDaily({
                                openedTours: this.openedTours,
                                shownTours: this.shownTours,
                                shownFinished: this.shownFinished,
                                shownTraces: this.shownTraces,
                                filter: this.universeFilter,
                            }).then(() => {
                                this.dailyInterval = setInterval(
                                    () => {
                                        this.fetchDaily({
                                            openedTours: this.openedTours,
                                            shownTours: this.shownTours,
                                            shownFinished: this.shownFinished,
                                            shownTraces: this.shownTraces,
                                            filter: this.universeFilter,
                                        })
                                        // this.fetchDailyUnaffected(this.current.Id).then(() =>
                                        //     this.setUnaffectedMarkers()
                                        // )
                                    },
                                    2 * 60 * 1000,
                                )
                            })
                        }
                        if (this.$route.name !== 'simulation_view') {
                            const driversInterval = setInterval(() => {
                                clearInterval(driversInterval)
                                this.setDriversMarkers()
                                this.driverPosInt = setInterval(
                                    () => {
                                        this.setDriversMarkers()
                                    },
                                    2 * 60 * 1000,
                                )
                                //     // metre en place le reset + refetch des positions dans un interval
                                //     // mettre en place aussi pour la map tours
                            }, 200)
                        }
                        if (this.$route.name === 'tour_view') {
                            this.fetchOneTour({
                                id: this.$route.params.id,
                                filter: this.universeFilter,
                            })
                        }

                        if (this.$route.name === 'simulation_view') {
                            this.fetchOneSimulation({
                                id: this.$route.params.id,
                                filter: this.universeFilter,
                            })
                        }
                    }
                }, 1000)
            }
        }, 10)
    },
    computed: {
        ...mapState(useDriverStore, {
            drivers: 'all',
            driversLastPos: 'driversLastPositions',
            driversTraces: 'traces',
        }),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
        ...mapStores(useUserStore),
        ...mapWritableState(useSimulationStore, {
            currentSim: 'current',
            simCards: 'tours',
            simUnaffected: 'unaffected',
            countSimUnaffected: 'countSimUnaffected',
            simUnaffectedHasIncompleteSteps: 'unaffectedHasIncompleteSteps',
        }),
        ...mapWritableState(useTourStore, {
            currentTour: 'current',
            calendarCards: 'tours',
            calendarUnaffected: 'unaffected',
            countCalendarUnaffected: 'countCalendarUnaffected',

            daily: 'daily',
            dailyCards: 'dailyTours',
            dailyUnaffected: 'dailyUnaffected',
            countDailyUnaffected: 'countDailyUnaffected',
            showStoreUnaffected: 'showStoreUnaffected',
            openStoreUnaffected: 'openStoreUnaffected',
            dailyUnaffectedHasIncompleteSteps:
                'dailyUnaffectedHasIncompleteSteps',
            tourUnaffectedHasIncompleteSteps:
                'tourUnaffectedHasIncompleteSteps',
        }),
        current() {
            return this.$route.name === 'tour_view'
                ? this.currentTour
                : this.$route.name === 'daily_view'
                  ? this.daily
                  : this.currentSim
        },
        unaffectedHasIncompleteSteps() {
            return this.$route.name === 'tour_view'
                ? this.tourUnaffectedHasIncompleteSteps
                : this.$route.name === 'daily_view'
                  ? this.dailyUnaffectedHasIncompleteSteps
                  : this.simUnaffectedHasIncompleteSteps
        },
        tourCards() {
            const tours =
                this.$route.name === 'tour_view'
                    ? this.calendarCards
                    : this.$route.name === 'daily_view'
                      ? this.dailyCards
                      : this.simCards
            if (tours) {
                tours.forEach((tour) => {
                    if (tour.show === true) {
                        const ind = this.toursToShow.findIndex(
                            (tr) => tr.id === tour.id,
                        )
                        if (ind === -1) {
                            this.toursToShow.push(tour)
                        }
                    }
                })
            }
            return tours
        },
        unaffectedSteps() {
            return this.$route.name === 'tour_view'
                ? this.calendarUnaffected
                : this.$route.name === 'daily_view'
                  ? this.dailyUnaffected
                  : this.simUnaffected
        },
        unaffectedCount() {
            return this.$route.name === 'tour_view'
                ? this.countCalendarUnaffected
                : this.$route.name === 'daily_view'
                  ? this.countDailyUnaffected
                  : this.countSimUnaffected
        },
        isMobile() {
            return (
                (navigator.userAgentData &&
                    navigator.userAgentData.mobile &&
                    navigator.userAgentData.mobile === true) ||
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent,
                )
            )
        },
        canAccess() {
            if (this.$route.name === 'daily_view') {
                return checkAccess(this.userStore, actionsAccess.daily.update)
            }
            if (this.$route.name === 'simulation_view') {
                return checkAccess(
                    this.userStore,
                    actionsAccess.simulations.update,
                )
            }

            return false
        },
        canIntegrateSimulation() {
            if (this.$route.name === 'simulation_view') {
                return checkAccess(
                    this.userStore,
                    actionsAccess.simulations.integrate,
                )
            }

            return false
        },
    },
    watch: {
        // 'unaffectedSteps.length': function () {
        // },
        showStoreUnaffected() {
            if (this.showStoreUnaffected === true) {
                this.showUnaffected = true
                this.showUnaffectedSteps()
            } else this.showUnaffected = false
        },
        // openStoreUnaffected() {
        //     if (this.openStoreUnaffected === true) {
        //         const unaffectedIndex = this.toursToShow.findIndex(
        //             (tr) => tr.id === 'unaffected'
        //         )
        //         this.toursToShow[unaffectedIndex].open = true
        //         console.log(this.toursToShow[unaffectedIndex])
        //         this.openPanel(this.toursToShow[unaffectedIndex])

        //         // this.showUnaffectedSteps()
        //     }
        // },
        'toursToShow.length': function () {
            if (this.toursToShow.length > 0) {
                this.toursToShow.forEach((shownTour) => {
                    this.openPanel(shownTour)
                })
            }
        },
        'dailyUnaffected.length': function () {
            if (this.dailyUnaffected && this.dailyUnaffected.length === 0) {
                // const unaffectedIndex = this.toursToShow.findIndex(
                //     (trToShow) => trToShow.id === 'unaffected'
                // )
                // if (unaffectedIndex) {
                //     this.toursToShow.splice(unaffectedIndex, 1)
                // }
                this.resetUnaffectedMarkers()
                this.showUnaffected = false
                this.showStoreUnaffected = false
            }
        },
        displayTopBlock() {
            if (this.tourCards) {
                this.tourCards.forEach((trCrd) => {
                    if (trCrd.show === true) {
                        const setBorderColorInterval = setInterval(() => {
                            if (document.getElementById(`tour-${trCrd.id}`)) {
                                clearInterval(setBorderColorInterval)
                                document.getElementById(
                                    `tour-${trCrd.id}`,
                                ).style.borderColor = trCrd.color.main.hex
                            }
                        }, 10)
                    }
                })
            }
        },
        displayBottomBlock() {
            this.toursToShow.forEach((shownTour) => {
                this.openPanel(shownTour)
            })
        },
        timeDetermination() {
            this.timeDeterminationOptions.forEach((radio) => {
                this.radioStyle(radio.id, radio.value)
            })
        },
    },
    methods: {
        setDragStyle(index, color, lastIndex) {
            let style = `border-color: ${color}; color: black !important`
            if (this.toInnerIndex === index && index !== lastIndex) {
                style = `border: 3px dashed ${color}; color: black !important ` //  background: ${color} !important;
            }

            return style
        },
        handleDrag(index, destination) {
            if (
                index !== this.oldIndex ||
                (index === this.oldIndex && destination === this.tourOrigin)
            ) {
                this.toInnerIndex = index
            }
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.searchingCursor = confirmDelete
            this.showConfirmTourOptiModal = false
        },
        canBeDragged(driver, stepUniverse, status) {
            if (
                (status === 'new' || status === 'affected') &&
                ((driver &&
                    driver.IdUniverse &&
                    this.universeFilter.value.find(
                        (univ) => +univ.value === +driver.IdUniverse,
                    ) &&
                    stepUniverse === undefined) ||
                    stepUniverse === undefined ||
                    this.universeFilter.value.find(
                        (univ) => +univ.value === +stepUniverse,
                    ))
            ) {
                return true
            }

            return false
        },

        ...mapActions(useSimulationStore, [
            'validate',
            'fetchOneSimulation',
            'moveStepToOtherTour',
            'moveManyStepsToOtherTour',
            'relaunch',
            'addTourToSimulation',
            // 'changeStepPositionInTour',
            'deleteTourInSimulation',
        ]),
        ...mapActions(useTourStore, [
            'addTourToDaily',
            'changeStepPositionInTour',
            'fetchDaily',
            'fetchOneTour',
            'deleteDaily',
            'fetchDailyUnaffected',
            'moveDailySteps',
            'moveManyDailySteps',
            'affectToTour',
        ]),
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
            availableDriversForDate: 'availableDriversForDate',
            fetchLastPosition: 'fetchLastPosition',
            allDriversLastPositions: 'allDriversLastPositions',
            getTrace: 'getDriverTrace',
        }),
        ...mapActions(useOrderStore, ['updateStep', 'fetchStep', 'fetchOne']),

        ...mapActions(useVehicleStore, {
            availableVehiclesForDate: 'availableVehiclesForDate',
            searchVehicles: 'search',
        }),
        searchCustomUniverses(clear = false) {
            if (this.$route.meta.map && this.$route.meta.map === true) {
                this.universeFilter = {}
                clearInterval(this.dailyInterval)
                this.universesForDriverSearch = []

                if (clear === true || this.selectedUniverses.length === 0) {
                    this.universeFilter = {
                        dbField: 'TourByUniverse',
                        value: this.universesOptions,
                    }
                } else {
                    this.universeFilter = {
                        dbField: 'TourByUniverse',
                        value: this.selectedUniverses,
                    }
                }
                this.universeFilter.value.forEach((universe) => {
                    this.universesForDriverSearch.push(universe.value)
                })
                this.refetch()
                if (
                    this.$route.meta.isTour === false &&
                    this.canAccess === true
                ) {
                    const availableVehiclesAndDriversInterval = setInterval(
                        () => {
                            if (this.current !== null) {
                                this.driverOptions = []
                                clearInterval(
                                    availableVehiclesAndDriversInterval,
                                )
                                // this.userStore.userUniverses.forEach((universe) => {
                                //     this.universesForDriverSearch.push(universe.value)
                                // })
                                this.availableDriversForDate({
                                    date: this.current.DateBegin,
                                    universes: this.universesForDriverSearch,
                                }).then((res) => {
                                    if (res && res.status === 200 && res.data) {
                                        res.data.forEach((driver) => {
                                            const name = `${driver.FirstName} ${driver.LastName}`
                                            const option = {
                                                name,
                                                value: +driver.Id,
                                            }
                                            this.driverOptions.push(option)
                                        })
                                    }
                                })
                            }
                        },
                        1000,
                    )
                }
            }
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.dailyInterval)

            this.searchCustomUniverses()
        },
        isOnTime(start, end) {
            return start >= end
        },
        indexToDisplay(steps, index) {
            let toDisplay = index + 2
            let needIndexRecalc = false
            steps.forEach((stp) => {
                if (
                    stp.Status === 'executed' ||
                    stp.OrderStepEnd.Status === 'executed'
                ) {
                    needIndexRecalc = true
                }
            })
            if (needIndexRecalc === true) {
                let indexToDisplay = 0
                for (let i = 1; i <= index; i += 1) {
                    indexToDisplay += 1
                }

                toDisplay = indexToDisplay
            }
            return toDisplay
        },
        showFinished(tourIndex, inner = false) {
            this.processing = true
            this.notRecalc = true
            if (inner === true) {
                tourIndex = this.tourCards.findIndex(
                    (tr) => tr.id === this.toursToShow[tourIndex].id,
                )
            }
            const currentTour = this.tourCards[tourIndex]
            currentTour.showFinished = !currentTour.showFinished
            // const traceDate = now.replaceAll('-', '')
            const shownFinishedIndex = this.shownFinished.findIndex(
                (trace) => trace === currentTour.id,
            )

            if (currentTour.showFinished === true) {
                if (shownFinishedIndex === -1) {
                    this.shownFinished.push(currentTour.id)

                    const finishedMarkers = []
                    const label = {
                        text: '\ue86c',
                        fontFamily: 'Material Icons',
                        color: '#000000',
                        fontSize: '18px',
                    }
                    const symbol = {
                        path: 'M 12 0 c -4.198 0 -8 3.403 -8 7.602 c 0 4.198 3.469 9.21 8 16.398 c 4.531 -7.188 8 -12.2 8 -16.398 c 0 -4.199 -3.801 -7.602 -8 -7.602 z z',
                        scale: 1.2,
                        strokeColor: '#808080',
                        strokeWeight: 1,
                        fillColor: '#808080',
                        fillOpacity: 1,
                        anchor: new google.maps.Point(10, 22),
                        labelOrigin: new google.maps.Point(12, 8),
                        labelInBackground: false,
                    }
                    // tracer les lignes
                    // this.tourCards[tourIndex].driver.traces = traces

                    const dottedSymbol = {
                        path: 'M 0,1 0,1', // 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        scale: 6,
                        strokeColor: '#808080',
                        fillOpacity: 0,
                        strokeWeight: 4,
                    }
                    const path = []
                    if (this.tourCards[tourIndex].ExecGeoJSON) {
                        this.tourCards[
                            tourIndex
                        ].ExecGeoJSON.coordinates.forEach((coor) => {
                            path.push({
                                lat: +coor[1],
                                lng: +coor[0],
                            })
                        })
                        // Create the polyline, passing the symbol in the 'icons' property.
                        // Give the line an opacity of 0.
                        // Repeat the symbol at intervals of 20 pixels to create the dashed effect.
                        const line = {
                            path,
                            strokeOpacity: 0,
                            icons: [
                                // {
                                //     icon: dottedSymbol,
                                //     offset: '50%',
                                //     repeat: '100px',
                                // },
                                {
                                    icon: dottedSymbol,
                                    offset: '0',
                                    repeat: '8px',
                                },
                            ],
                        }
                        const polyline = new google.maps.Polyline(line)
                        polyline.setMap(this.map)
                        lines.push({ [`FINISHED-${currentTour.id}`]: polyline })
                    }

                    // FIN traceer les lognes
                    currentTour.steps.infos.forEach((step) => {
                        if (step.Status === 'executed') {
                            finishedMarkers.push({
                                position: {
                                    lat: +step.OrderStepBegin.Latitude,
                                    lng: +step.OrderStepBegin.Longitude,
                                },
                                title: `${step.OrderStepBegin.AddressName} - ${
                                    currentTour.driver.name
                                } - ${new Date(step.DateReal).toLocaleString(
                                    this.$i18n.locale,
                                    {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    },
                                )}`,
                                icon: symbol,
                                label,
                                optimized: false,
                                animation: google.maps.Animation.DROP,
                                clickable: false,
                                draggable: false,
                            })
                        }
                        if (step.OrderStepEnd.Status === 'executed') {
                            finishedMarkers.push({
                                position: {
                                    lat: +step.OrderStepEnd.Latitude,
                                    lng: +step.OrderStepEnd.Longitude,
                                },
                                title: `${step.OrderStepEnd.AddressName} - ${
                                    currentTour.driver.name
                                } - ${new Date(step.DateReal).toLocaleString(
                                    this.$i18n.locale,
                                    {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    },
                                )}`,
                                icon: symbol,
                                label,
                                optimized: false,
                                animation: google.maps.Animation.DROP,
                                clickable: false,
                                draggable: false,
                            })
                        }
                    })
                    finishedMarkers.forEach((marker, index) => {
                        const mark = new google.maps.Marker(marker)
                        mark.setMap(this.map)
                        markers.push({
                            [`FINISHED-${currentTour.id}`]: mark,
                        })
                    })
                    this.processing = false

                    this.notRecalc = false
                    // })
                }
            } else if (currentTour.showFinished === false) {
                if (shownFinishedIndex > -1) {
                    this.shownFinished.splice(shownFinishedIndex, 1)
                }
                markers.forEach((markerArray) => {
                    if (markerArray[`FINISHED-${currentTour.id}`]) {
                        markerArray[`FINISHED-${currentTour.id}`].setVisible(
                            false,
                        )
                        markerArray[`FINISHED-${currentTour.id}`].setMap(null)
                        markers = markers.filter((mark) => mark !== markerArray)
                    }
                })
                lines.forEach((lineArray, index) => {
                    if (lineArray[`FINISHED-${currentTour.id}`]) {
                        lineArray[`FINISHED-${currentTour.id}`].setVisible(
                            false,
                        )
                        lineArray[`FINISHED-${currentTour.id}`].setMap(this.map)
                        lineArray[`FINISHED-${currentTour.id}`].setMap(null)
                        lines.splice(index, 1)
                    }
                })

                this.processing = false
                this.notRecalc = false
            }
        },
        animateCircle(line) {
            let count = 0

            const traceInt = window.setInterval(() => {
                count = (count + 1) % 200

                const icons = line.get('icons')

                icons[0].offset = `${count / 2}%`
                line.set('icons', icons)
                if (icons[0].offset >= '90%') {
                    clearInterval(traceInt)
                    icons[0].offset = `0%`
                    line.set('icons', icons)
                }
            }, 20)
        },
        // TODO voir le comportement avec les multisteps
        // TODO corriger le store
        showTrace(tourIndex, inner = false) {
            this.processing = true
            this.notRecalc = true
            if (inner === true) {
                tourIndex = this.tourCards.findIndex(
                    (tr) => tr.id === this.toursToShow[tourIndex].id,
                )
            }
            const currentTour = this.tourCards[tourIndex]
            currentTour.showTrace = !currentTour.showTrace
            const now = new Date().toISOString().slice(0, 10)
            const traceDate = now.replaceAll('-', '')
            const shownTraceIndex = this.shownTraces.findIndex(
                (trace) => trace === currentTour.id,
            )

            if (currentTour.showTrace === true) {
                if (shownTraceIndex === -1) {
                    this.shownTraces.push(currentTour.id)
                    let traces = []
                    this.getTrace({
                        id: currentTour.id,
                        date: traceDate, // traceDate
                    }).then((res) => {
                        if (res && res.status.code === 200) {
                            if (res.data) traces = res.data.Route
                            this.tourCards[tourIndex].driver.traces = traces

                            const dashedSymbol = {
                                path: 'M 0,-1 0,1', // 'M 0,-1 0,1',
                                // strokeOpacity: 1,
                                // scale: 4,
                                strokeColor: currentTour.color.main.hex,
                                strokeOpacity: 1.0,
                                fillOpacity: 0,
                                strokeWeight: 2,
                            }
                            const circle = {
                                path: google.maps.SymbolPath.CIRCLE, // 'M 0,2 0,1', // 'M 0,-1 0,1',
                                // strokeOpacity: 1,
                                scale: 4,
                                strokeColor: currentTour.color.main.hex,
                                fillColor: currentTour.color.main.hex,

                                strokeOpacity: 1.0,
                                fillOpacity: 1,
                                strokeWeight: 2, // 2,
                            }
                            const path = []
                            let lastTracePos = null
                            this.tourCards[tourIndex].driver.traces.forEach(
                                (trace) => {
                                    const routes = JSON.parse(trace.Route)
                                    routes.coordinates.forEach((coor, ind) => {
                                        path.push({
                                            lat: coor[1],
                                            lng: coor[0],
                                        })
                                        if (
                                            ind ===
                                            routes.coordinates.length - 1
                                        ) {
                                            lastTracePos = {
                                                lat: coor[1],
                                                lng: coor[0],
                                                date: trace.End,
                                            }
                                        }
                                    })
                                },
                            )
                            // Create the polyline, passing the symbol in the 'icons' property.
                            // Give the line an opacity of 0.
                            // Repeat the symbol at intervals of 20 pixels to create the dashed effect.
                            const line = {
                                path,
                                strokeOpacity: 0,
                                icons: [
                                    {
                                        icon: circle,
                                        offset: '20%',
                                        // repeat: '100px',
                                    },
                                    {
                                        icon: dashedSymbol,
                                        offset: '0',
                                        repeat: '10px',
                                    },
                                ],
                            }
                            const polyline = new google.maps.Polyline(line)
                            polyline.setMap(this.map)
                            this.animateCircle(polyline)
                            lines.push({
                                [`TRACE-${currentTour.id}`]: polyline,
                            })

                            const symbol = {
                                path: 'M 12 0 c -4.198 0 -8 3.403 -8 7.602 c 0 4.198 3.469 9.21 8 16.398 c 4.531 -7.188 8 -12.2 8 -16.398 c 0 -4.199 -3.801 -7.602 -8 -7.602 z z',
                                scale: 1.3,
                                strokeWeight: 0.5,
                                fillOpacity: 1,

                                fillColor: currentTour.color.main.hex,
                                anchor: new google.maps.Point(10, 22),
                                labelOrigin: new google.maps.Point(12, 8),
                                labelInBackground: false,
                            }
                            const mark = new google.maps.Marker({
                                position: {
                                    lat: +lastTracePos.lat,
                                    lng: +lastTracePos.lng,
                                },
                                title: `${currentTour.driver.name} - ${new Date(
                                    lastTracePos.date,
                                ).toLocaleString(this.$i18n.locale, {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}`,
                                icon: symbol,
                                label: {
                                    text: '\ue55a',
                                    fontFamily: 'Material Icons',
                                    color: '#000000',
                                    fontSize: '20px',
                                },

                                optimized: false,
                                animation: google.maps.Animation.DROP,
                                clickable: false,
                                draggable: false,
                            })
                            mark.setMap(this.map)
                            markers.push({
                                [`TRACE-${currentTour.id}`]: mark,
                            })
                        } else {
                            this.$toast.info(this.$t('no_driver_trace'))
                        }

                        this.notRecalc = false
                        this.processing = false
                    })
                }
            } else if (currentTour.showTrace === false) {
                if (shownTraceIndex > -1) {
                    this.shownTraces.splice(shownTraceIndex, 1)
                }
                markers.forEach((markerArray) => {
                    if (markerArray[`TRACE-${currentTour.id}`]) {
                        markerArray[`TRACE-${currentTour.id}`].setVisible(false)
                        markerArray[`TRACE-${currentTour.id}`].setMap(null)
                        markers = markers.filter((mark) => mark !== markerArray)
                    }
                })
                lines.forEach((lineArray, index) => {
                    if (lineArray[`TRACE-${currentTour.id}`]) {
                        lineArray[`TRACE-${currentTour.id}`].setVisible(false)
                        lineArray[`TRACE-${currentTour.id}`].setMap(this.map)
                        lineArray[`TRACE-${currentTour.id}`].setMap(null)
                        lines.splice(index, 1)
                    }
                })
                this.processing = false
                this.notRecalc = false
            }

            // this.getTrace({id: currentTour.driver.id, date: })
        },
        showAllTraces() {},

        // showFinished(driverId) {},

        setDriversMarkers() {
            this.allDriversLastPositions().then((res) => {
                if (res && res.status === 200) {
                    this.driversLastPos.forEach((lastPos) => {
                        markers.forEach((markerArray) => {
                            if (markerArray[`D-${lastPos.IdDriver}`]) {
                                markerArray[`D-${lastPos.IdDriver}`].setVisible(
                                    false,
                                )
                                markerArray[`D-${lastPos.IdDriver}`].setMap(
                                    null,
                                )
                                markers = markers.filter(
                                    (mark) => mark !== markerArray,
                                )
                            }
                        })

                        const str = `${lastPos.FirstName} ${lastPos.LastName}`
                        const acronym = str
                            .split(/\s/)
                            .reduce(
                                (response, word) =>
                                    (response += word.slice(0, 1)),
                                '',
                            )
                        const mark = new google.maps.Marker({
                            position: {
                                lat: +lastPos.Latitude,
                                lng: +lastPos.Longitude,
                            },
                            title: `${this.$t('driver')} ${lastPos.FirstName} ${
                                lastPos.LastName
                            }`,
                            label: {
                                text: acronym,
                                fontWeight: 'bold',
                                fontSize: '9',
                            },
                            clickable: false,
                        })
                        mark.setMap(this.map)
                        markers.push({
                            [`D-${lastPos.IdDriver}`]: mark,
                        })
                    })
                }
            })
        },
        resetUnaffectedMarkers(setMarkers = false) {
            const unafDetail = this.toursToShow.find(
                (tour) => tour.id === 'unaffected',
            )
            if (unafDetail !== undefined) {
                this.toursToShow.splice(this.toursToShow.indexOf(unafDetail), 1)
            }

            this.unaffectedSteps.forEach((una) => {
                if (Array.isArray(una)) {
                    markers.forEach((markerArray, index) => {
                        if (markerArray[una[0].IdOrder]) {
                            markerArray[una[0].IdOrder].setVisible(false)
                            markerArray[una[0].IdOrder].setMap(this.map)

                            markerArray[una[0].IdOrder].setMap(null)
                            markers = markers.filter(
                                (mark) => mark !== markerArray,
                            )
                        }
                    })

                    lines.forEach((lineArray, index) => {
                        if (lineArray[una[0].IdOrder]) {
                            lineArray[una[0].IdOrder].setVisible(false)
                            lineArray[una[0].IdOrder].setMap(this.map)
                            lineArray[una[0].IdOrder].setMap(null)
                            lines.splice(index, 1)
                        }
                    })
                } else {
                    markers.forEach((markerArray) => {
                        if (markerArray[una.IdOrder]) {
                            markerArray[una.IdOrder].setVisible(false)
                            markerArray[una.IdOrder].setMap(this.map)
                            markerArray[una.IdOrder].setMap(null)
                            markers = markers.filter(
                                (mark) => mark !== markerArray,
                            )
                        }
                    })
                    lines.forEach((lineArray, index) => {
                        if (lineArray[una.IdOrder]) {
                            lineArray[una.IdOrder].setVisible(false)
                            lineArray[una.IdOrder].setMap(this.map)
                            lineArray[una.IdOrder].setMap(null)
                            lines.splice(index, 1)
                        }
                    })
                }
            })

            if (setMarkers === true) {
                this.setUnaffectedMarkers()
            }
        },
        setUnaffectedMarkers() {
            this.processing = true

            const unaffectedMarkers = []

            const symbol = {
                path: 'M 12 0 c -4.198 0 -8 3.403 -8 7.602 c 0 4.198 3.469 9.21 8 16.398 c 4.531 -7.188 8 -12.2 8 -16.398 c 0 -4.199 -3.801 -7.602 -8 -7.602 z z',
                scale: 1.2,
                strokeColor: '#18181b',
                strokeWeight: 1,
                fillColor: '#9DBAD4',
                fillOpacity: 1,
                anchor: new google.maps.Point(10, 22),
                labelOrigin: new google.maps.Point(12, 8),
                labelInBackground: false,
            }
            this.unaffectedSteps.forEach((unaffected, index) => {
                const coordinatesForPolyline = []
                let polyline = null
                const stepsIds = []
                let idOrder = null
                if (unaffected.length) {
                    unaffected.forEach((una, i) => {
                        let isIncomplete = 'complete'
                        if (
                            (unaffected[i - 1] &&
                                unaffected[i - 1].IdOrder === una.IdOrder &&
                                unaffected[i - 1].incomplete &&
                                unaffected[i - 1].incomplete === true) ||
                            (unaffected[i + 1] &&
                                unaffected[i + 1].IdOrder === una.IdOrder &&
                                unaffected[i + 1].incomplete &&
                                unaffected[i + 1].incomplete === true)
                        ) {
                            isIncomplete = 'incomplete'
                        }
                        if (!una.incomplete) {
                            stepsIds.push(una.Id)
                            const title = `${
                                i === 0
                                    ? (index + 1).toString()
                                    : (index + 1).toString()
                            }' - ${una.AddressName}`

                            unaffectedMarkers.push({
                                pos: {
                                    lat: +una.Latitude,
                                    lng: +una.Longitude,
                                },
                                stpNumber: una.StepNumber,
                                type: una.Type,
                                title,
                                address: una.Address1,
                                postalCode: una.PostalCode,
                                city: una.City,
                                incomplete: isIncomplete,
                                moment: una.Moment,
                                beforeTime: una.BeforeDateTime,
                                fromTime: una.FromDateTime,

                                step: una,
                                stepId: una.Id,
                                stepsIds,
                                orderId: una.IdOrder,
                                label:
                                    i === 0
                                        ? (index + 1).toString()
                                        : `${(index + 1).toString()}'`,
                            })

                            coordinatesForPolyline.push({
                                lat: +una.Latitude,
                                lng: +una.Longitude,
                            })
                            // }
                            idOrder = una.IdOrder
                        }
                    })
                    const tourPath = {
                        path: coordinatesForPolyline,
                        geodesic: true,
                        strokeColor: '#999999',
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                    }

                    polyline = new google.maps.Polyline(tourPath)
                    polyline.setMap(this.map)
                    lines.push({ [idOrder]: polyline })
                } else {
                    // todo
                    stepsIds.push(unaffected.Id)
                    if (!unaffected.incomplete) {
                        const title = `1 - ${unaffected.AddressName}`

                        unaffectedMarkers.push({
                            pos: {
                                lat: +unaffected.Latitude,
                                lng: +unaffected.Longitude,
                            },
                            stpNumber: unaffected.StepNumber,
                            title,
                            type: unaffected.Type,
                            address: unaffected.Address1,
                            postalCode: unaffected.PostalCode,
                            city: unaffected.City,
                            moment: unaffected.Moment,
                            beforeTime: unaffected.BeforeDateTime,

                            fromTime: unaffected.FromDateTime,
                            // addressStart: unaffected[0].Address1,
                            // postalCodeStart: unaffected[0].PostalCode,
                            // cityStart: unaffected[0].City,
                            // momentStart: unaffected[0].Moment,
                            // beforeTimeStart:
                            //     unaffected[0].BeforeDateTime,
                            // fromTimeStart: unaffected[0].FromDateTime,
                            // stepStart: unaffected[0],
                            step: unaffected,
                            stepId: unaffected.Id,
                            stepsIds,
                            orderId: unaffected.IdOrder,
                            label: (index + 1).toString(),
                        })
                    }
                    idOrder = unaffected.IdOrder
                }
            })

            unaffectedMarkers.forEach((marker, index) => {
                const mark = new google.maps.Marker({
                    position: marker.pos,
                    title: marker.title,
                    icon: symbol,
                    label: {
                        text: marker.label,
                        // fontWeight: 'bold',
                        fontSize: '9',
                    },
                    optimized: false,
                    // animation: google.maps.Animation.DROP,
                    clickable: true,
                })
                mark.setMap(this.map)
                if (
                    this.$route.meta.isTour === false &&
                    this.canAccess === true
                ) {
                    const eventScreen =
                        'ontouchstart' in document.documentElement
                            ? 'mousedown'
                            : 'click'

                    mark.addListener(eventScreen, (evt) => {
                        // this.resetDraggedElements()

                        evt.domEvent.cancelBubble = true // stop event propagation
                        const mapDiv = document
                            .getElementById('map')
                            .getElementsByTagName('div')[0]
                        const markerDiv = document.createElement('div')
                        markerDiv.style.position = 'absolute'
                        markerDiv.style.left = `${
                            evt.domEvent.pageX +
                            markerDiv.offsetWidth +
                            (index * 10) / 3
                        }px`
                        markerDiv.style.top = `${
                            evt.domEvent.pageY -
                            markerDiv.offsetHeight -
                            (index * 10) / 7
                        }px`

                        markerDiv.style.backgroundColor = '#9DBAD4'
                        markerDiv.style.zIndex = 1000
                        markerDiv.setAttribute(
                            'id',
                            `draggedElement-${marker.stepId}`,
                        )
                        markerDiv.setAttribute(
                            'draggable',
                            this.$route.meta.isTour === false &&
                                this.canAccess === true,
                        )
                        markerDiv.classList.add(
                            'text-zinc-900',
                            'border-2',
                            'rounded',
                            'text-xs',
                            'h-fit',
                            'min-w-[10%]',
                            'w-[10%]',
                        )

                        if (
                            !this.draggedElements.find(
                                (dragged) =>
                                    dragged.draggedItemId === marker.stepId,
                            )
                        ) {
                            this.draggedElements.push({
                                draggedItemStepsIds: marker.stepsIds,
                                // draggedItemId: marker.type === 'delivery' && marker.stpNumber === 2 ? marker.stepId: unaffectedMarkers[index+1].stepId,
                                draggedItemId: marker.stepId,
                                draggedItemList: 'unaffected',
                                draggedItemOrderId: marker.orderId,
                                draggedIncompleteStep: marker.incomplete,
                                toRemove: true,
                            })

                            document.addEventListener('mousemove', (e) => {
                                markerDiv.style.left = `${
                                    e.pageX +
                                    markerDiv.offsetWidth +
                                    (index * 10) / 3
                                }px`
                                markerDiv.style.top = `${
                                    e.pageY -
                                    markerDiv.offsetHeight -
                                    (index * 10) / 7
                                }px`
                            })
                            mapDiv.appendChild(markerDiv)

                            const innerMarkerDiv = document.createElement('div')
                            innerMarkerDiv.setAttribute(
                                'id',
                                'draggedDivContent',
                            )

                            document
                                .getElementById(
                                    `draggedElement-${marker.stepId}`,
                                )
                                .appendChild(innerMarkerDiv)
                            const newContent = document.createTextNode(
                                `${marker.title} ${marker.address} ${
                                    marker.postalCode
                                } ${marker.city} ${this.$t(
                                    'on',
                                )} ${this.formatStepDate(
                                    marker.step,
                                )} ${this.$t(marker.moment)} ${
                                    marker.moment === 'from' ||
                                    marker.moment === 'between'
                                        ? this.formatStepTime(marker.fromTime)
                                        : ' '
                                } ${
                                    marker.moment === 'before' ||
                                    marker.moment === 'between'
                                        ? this.formatStepTime(marker.beforeTime)
                                        : ' '
                                } `,
                            )
                            innerMarkerDiv.classList.add('p-2', 'text-zinc-900')
                            innerMarkerDiv.appendChild(newContent)
                        }
                    })
                }
                this.processing = false

                markers.push({ [marker.orderId]: mark })
            })
        },
        back() {
            const backComponent =
                this.$router.options.history.state.current.includes(
                    'simulations',
                )
                    ? 'simulations'
                    : 'tours'
            this.$router.go(-1)
            this.$emit('back', backComponent)
        },
        formatStepDate(step) {
            let prettyDate = null
            if (step.Moment === 'before') {
                prettyDate = new Date(step.BeforeDateTime).toLocaleString(
                    this.$i18n.locale,
                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    },
                )
            } else {
                prettyDate = new Date(step.FromDateTime).toLocaleString(
                    this.$i18n.locale,
                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    },
                )
            }

            return prettyDate
        },
        formatStepTime(time) {
            if (time) {
                let prettyTime = time.substring(time.indexOf(' ') + 1)
                prettyTime = prettyTime.substring(0, prettyTime.length - 3)
                return prettyTime
            }
            return null
        },

        hoveredCardBorder(elId, color, add = true) {
            if (add === true) {
                document.getElementById(elId).style = `border-color: ${color}`
            } else {
                document.getElementById(elId).style = `border-color: ''`
            }
        },

        validateSimulation() {
            this.validate(this.$route.params.id)
                .then((res) => {
                    this.$router.push(this.$router.options.history.state.back)
                    this.$toast.success(this.$t('sim_integration_ok'))
                    this.$emit('back', 'simulations')
                })
                .catch((err) => {
                    this.showErrorModal = true
                    this.$toast.error(this.$t('sim_integration_ko'))
                })
        },
        showEditTourModal(tour) {
            this.tourToUpdateForm = tour
            this.timeDetermination = tour.start_hour_option

            const timeRadio = this.timeDeterminationOptions.find(
                (radio) => radio.value === this.timeDetermination,
            )
            this.radioStyle(timeRadio.id, timeRadio.value)

            this.tourTime = new Date(
                new Date(tour.date_begin).getTime() -
                    new Date(tour.date_begin).getTimezoneOffset() * 60000,
            )
                .toISOString()
                .substr(0, 16)
            this.minDate = `${new Date(
                new Date(tour.date_begin).getTime() -
                    new Date(tour.date_begin).getTimezoneOffset() * 60000,
            )
                .toISOString()
                .substr(0, 11)}00:00`
            this.maxDate = `${new Date(
                new Date(tour.date_begin).getTime() -
                    new Date(tour.date_begin).getTimezoneOffset() * 60000,
            )
                .toISOString()
                .substr(0, 11)}23:59`
            this.driverOptions = []
            this.availableDriversForDate({
                date: this.current.DateBegin,
                universes: this.universesForDriverSearch.join(),
            }).then((res) => {
                if (res && res.status === 200 && res.data) {
                    res.data.forEach((driver) => {
                        const name = `${driver.FirstName} ${driver.LastName}`
                        const option = {
                            name,
                            value: +driver.Id,
                        }
                        this.driverOptions.push(option)
                    })
                    this.refreshMs += 1
                }
            })

            this.vehicleOptions = []
            this.availableVehiclesForDate(this.current.DateBegin).then(
                (res) => {
                    res.data.forEach((vehicle) => {
                        const name = `${vehicle.Name} - ${this.$t(
                            vehicle.Type,
                        )}`
                        const option = {
                            name,
                            value: vehicle.Id,
                        }
                        this.vehicleOptions.push(option)
                    })
                    this.refreshMs += 1
                },
            )

            this.editTourModal = true
            // ? FOR FULLSCREEN
            const childInterval = setInterval(() => {
                if (document.getElementById('map')) {
                    if (
                        document
                            .getElementById('map')
                            .getElementsByTagName('div')[0]
                    ) {
                        const mapDiv = document
                            .getElementById('map')
                            .getElementsByTagName('div')[0]
                        if (document.getElementById('editTourModal')) {
                            clearInterval(childInterval)
                            mapDiv.appendChild(
                                document.getElementById('editTourModal'),
                            )
                        }
                    }
                }
            }, 10)

            // const date = new Date()
            // const formattedDate = date.replace('-', '')
        },
        submitEditTourModal(tourId) {
            this.processing = true

            const payload = {
                IdTour: tourId,
                IdDriver: this.tourToUpdateForm.driver.id,
                IdCoDriver: this.tourToUpdateForm.co_driver.id,
                IdVehicle: this.tourToUpdateForm.vehicle.id,
                StartHourOption: this.timeDetermination,
                ModificationUserId: this.userStore.current.id,
            }

            if (this.timeDetermination === 'manual') {
                payload.DateBegin = this.tourTime
            }
            this.affectToTour(payload).then((res) => {
                this.editTourModal = false
                this.processing = true
                this.refetch()
            })
        },
        closeEditTourModal() {
            this.tourToUpdateForm = {}
            this.timeDetermination = ''

            this.editTourModal = false
        },
        showEditAddressModal(step, index, stepColors) {
            this.loadGoogleScript('autocomplete')
            this.stepToUpdateForm = step
            this.stepToUpdateForm.ModificationUserId = this.userStore.current.id
            this.stepToUpdateForm.color = stepColors
            this.stepToUpdateForm.Name = step.AddressName

            this.stepToUpdateForm.title = `${index + 1} - ${step.AddressName}`
            const dateToUse =
                step.Moment === 'from' || step.Moment === 'between'
                    ? step.FromDateTime
                    : step.BeforeDateTime
            this.stepToUpdateForm.date = new Date(dateToUse)
                .toISOString()
                .substr(0, 10)
            this.stepToUpdateForm.beforeTime = this.formatStepTime(
                step.BeforeDateTime,
            )
            this.stepToUpdateForm.fromTime = this.formatStepTime(
                step.FromDateTime,
            )
            this.stepToUpdateForm.UpdateRefAddress = false
            this.editAddressModal = true
            const checkboxStyleInterval = setInterval(() => {
                if (document.getElementById('ref-add-update-checkbox')) {
                    clearInterval(checkboxStyleInterval)
                    document
                        .getElementById('ref-add-update-checkbox')
                        .addEventListener('change', (e) => {
                            if (document.querySelector('input:checked')) {
                                document
                                    .getElementById('ref-add-update-checkbox')
                                    .classList.add('checked')
                                document.getElementById(
                                    'ref-add-update-checkbox',
                                ).style.accentColor = stepColors.main.hex
                            } else {
                                document.getElementById(
                                    'ref-add-update-checkbox',
                                ).style.accentColor = ''
                                document
                                    .getElementById('ref-add-update-checkbox')
                                    .classList.remove('checked')
                            }
                        })
                }
            }, 10)
            // ? FOR FULLSCREEN
            const childInterval = setInterval(() => {
                if (document.getElementById('map')) {
                    if (
                        document
                            .getElementById('map')
                            .getElementsByTagName('div')[0]
                    ) {
                        const mapDiv = document
                            .getElementById('map')
                            .getElementsByTagName('div')[0]
                        if (document.getElementById('editAddressModal')) {
                            clearInterval(childInterval)
                            mapDiv.appendChild(
                                document.getElementById('editAddressModal'),
                            )
                        }
                    }
                }
            }, 10)
            // const date = new Date()
            // const formattedDate = date.replace('-', '')
        },
        // submitEditAddressModal(step) {
        //     this.s
        // },
        closeEditAddressModal() {
            this.stepToUpdateForm = {}
            this.editAddressModal = false
        },
        addTourCard() {
            this.processing = true
            const payload = {
                IdSimulation: this.current.Id,
                CreationUserId: this.userStore.current.id,
                ModificationUserId: this.userStore.current.id,
            }

            this.addTourToSimulation(payload).then(() => {
                this.fetchOneSimulation({
                    id: payload.IdSimulation,
                    filter: this.universeFilter,
                }).then(() => {
                    this.processing = false
                    this.setSlider()
                })
            })
        },
        addCalendarCard() {
            this.processing = true
            const payload = {
                IdTour: this.current.Id,
                CreationUserId: this.userStore.current.id,
                ModificationUserId: this.userStore.current.id,
            }

            this.addTourToDaily(payload).then(() => {
                this.fetchDaily({
                    id: payload.IdTour,
                    openedTours: this.openedTours,
                    shownTours: this.shownTours,
                    shownFinished: this.shownFinished,
                    shownTraces: this.shownTraces,
                    filter: this.universeFilter,
                }).then(() => {
                    this.processing = false
                    this.setSlider()
                })
            })
        },
        showTour(tour, fromButton = true, fromReorderTour = false) {
            if (fromButton === true) {
                tour.show = !tour.show
            }

            // ttodo  mettre les steps non exec dans un tableau si showfinished alors on affihce tous les steps si pas show finished on affiche ce nouveau tableau
            const shownIndex = this.shownTours.findIndex((tr) => tr === tour.id)
            // TODO mettre dans le tableau shownTours et openedTours
            if (tour.show === true) {
                if (shownIndex === -1) {
                    this.shownTours.push(tour.id)
                }
                if (fromButton === false) {
                    document.getElementById(
                        `tour-${tour.id}`,
                    ).style.borderColor = ''
                }
                document.getElementById(`tour-${tour.id}`).style.borderColor =
                    tour.color.main.hex
                this.openPanel(tour)
                const exists = this.toursToShow.find((tr) => tr.id === tour.id)
                if (exists) {
                    this.toursToShow[this.toursToShow.indexOf(exists)] = tour
                } else {
                    this.toursToShow.push(tour)
                }
                this.displayBottomBlock = true
                if (fromReorderTour === true) {
                    markers.forEach((markerArray) => {
                        // this.tourCards.forEach((trCrd) => {
                        if (markerArray[tour.id]) {
                            markerArray[tour.id].setVisible(false)
                            // markerArray[tour.id].setMap(this.map)
                            markerArray[tour.id].setMap(null)
                            markers = markers.filter(
                                (mark) => mark !== markerArray,
                            )
                        }
                        // })
                    })
                    if (polygons[tour.id]) {
                        polygons[tour.id].forEach((line) => {
                            if (line) {
                                line.setVisible(false)
                                // line.setMap(this.map)
                                line.setMap(null)
                                polygons[tour.id] = polygons[tour.id].filter(
                                    (p) => p !== line,
                                )
                            }
                        })
                    }
                }
                let polygon = null

                const currentTourMarkers = []

                const coordinatesForPolyline = []

                const colors = [
                    '#FF0000',
                    '#FFC125',
                    '#800000',
                    '#00FF00',
                    '#00FFFF',
                    '#FFFF00',
                    '#0000FF',
                    '#FF00FF',
                    '#8A2BE2',
                    '#FF7F24',
                    '#FF83FA',
                    '#8B8B00',
                ]
                polygons[tour.id] = []
                tour.steps.infos.forEach((info, index) => {
                    let isInUserUniverses = false
                    if (index === 0) {
                        const title = `${(index + 1).toString()} - ${
                            info.OrderStepBegin.AddressName
                        }`
                        // if (info.Status !== 'executed') {
                        currentTourMarkers.push({
                            pos: {
                                lat: +info.OrderStepBegin.Latitude,
                                lng: +info.OrderStepBegin.Longitude,
                            },
                            title,
                            address: info.OrderStepBegin.Address1,
                            postalCode: info.OrderStepBegin.PostalCode,
                            city: info.OrderStepBegin.City,
                            moment: info.OrderStepBegin.Moment,
                            beforeTime: info.OrderStepBegin.BeforeDateTime,
                            fromTime: info.OrderStepBegin.FromDateTime,
                            step: info.OrderStepBegin,
                            stepId: info.OrderStepBegin.Id,
                            label: (index + 1).toString(),
                            executed: info.Status === 'executed',
                            status: info.Status,
                        })
                        // }
                    }
                    if (
                        info.IdOrderStepEnd !== '0' ||
                        index === tour.steps.infos.length - 1
                    ) {
                        if (
                            (info.driver &&
                                info.driver.IdUniverse &&
                                this.universeFilter.value.find(
                                    (univ) =>
                                        +univ.value === +info.driver.IdUniverse,
                                )) ||
                            this.universeFilter.value.find(
                                (univ) =>
                                    +univ.value ===
                                    +info.OrderStepEnd.IdUniverse,
                            )
                        ) {
                            isInUserUniverses = true
                        }

                        const typeString = `${info.OrderStepEnd.Type}_label`
                        let title = `${(index + 2).toString()} - ${this.$t(
                            typeString,
                        )} - ${info.OrderStepEnd.AddressName}`

                        const same =
                            index > 0 &&
                            currentTourMarkers[currentTourMarkers.length - 1]
                                .pos.lat === +info.OrderStepEnd.Latitude &&
                            currentTourMarkers[currentTourMarkers.length - 1]
                                .pos.lng === +info.OrderStepEnd.Longitude
                        if (same === true) {
                            currentTourMarkers[
                                currentTourMarkers.length - 1
                            ].label = ''
                            let substring = currentTourMarkers[
                                currentTourMarkers.length - 1
                            ].title.substring(
                                0,

                                currentTourMarkers[
                                    currentTourMarkers.length - 1
                                ].title.indexOf('-'),
                            )
                            substring = substring.replace('-', '/')
                            title = `${substring}/ ${index + 2} - ${
                                info.OrderStepEnd.AddressName
                            }`
                        }

                        // ?  si premier et dernier mm points
                        const lastEqualsFirst =
                            currentTourMarkers[0].pos.lat ===
                                +info.OrderStepEnd.Latitude &&
                            currentTourMarkers[0].pos.lng ===
                                +info.OrderStepEnd.Longitude
                        if (lastEqualsFirst === true) {
                            currentTourMarkers[0].label = ''
                            let substring =
                                currentTourMarkers[0].title.substring(
                                    0,

                                    currentTourMarkers[0].title.indexOf('-'),
                                )
                            substring = substring.replace('-', '/')
                            title = `${substring}/ ${index + 2} - ${
                                info.OrderStepEnd.AddressName
                            }`
                        }
                        const linkedSteps = []
                        const lnkstps = tour.steps.infos.filter(
                            (stp) =>
                                info.OrderStepEnd.IdOrder ===
                                stp.OrderStepBegin.IdOrder,
                        )
                        lnkstps.forEach((lnkStp) => {
                            if (
                                lnkStp.IdOrderStepBegin.Id !==
                                info.OrderStepEnd.Id
                            ) {
                                const ind = tour.steps.infos.findIndex(
                                    (stp) => stp === lnkStp,
                                )
                                if (index + 2 !== ind + 1) {
                                    linkedSteps.push(ind + 1)
                                }
                            }
                        })

                        currentTourMarkers.push({
                            pos: {
                                lat: +info.OrderStepEnd.Latitude,
                                lng: +info.OrderStepEnd.Longitude,
                            },
                            title,
                            address: info.OrderStepEnd.Address1,
                            postalCode: info.OrderStepEnd.PostalCode,
                            city: info.OrderStepEnd.City,
                            moment: info.OrderStepEnd.Moment,
                            beforeTime: info.OrderStepEnd.BeforeDateTime,
                            fromTime: info.OrderStepEnd.FromDateTime,
                            step: info.OrderStepEnd,
                            stepId: info.OrderStepEnd.Id,
                            executed: info.OrderStepEnd.Status === 'executed',
                            status: info.Status,
                            linkedSteps,
                            orderId: info.OrderStepEnd.IdOrder,
                            isInUserUniverses,
                            label:
                                same === true ||
                                (lastEqualsFirst &&
                                    currentTourMarkers[0].executed === false)
                                    ? '#'
                                    : this.indexToDisplay(
                                          tour.steps.infos,
                                          index,
                                          tour.showFinished,
                                      ).toString(),
                        })
                    }
                })
                if (tour.steps.infos.length > 1) {
                    tour.GeoJSON.coordinates.forEach((coor) => {
                        coordinatesForPolyline.push({
                            lat: +coor[1],
                            lng: +coor[0],
                        })
                    })
                    const tourPath = {
                        path: coordinatesForPolyline,
                        // geodesic: true,
                        strokeColor: tour.color.main.hex,
                        strokeOpacity: 1.0,
                        fillOpacity: 0,
                        strokeWeight: 3,
                    }
                    polygon = new google.maps.Polyline(tourPath)

                    polygon.setMap(this.map)
                    polygons[tour.id].push(polygon)
                }

                currentTourMarkers.forEach((marker, index) => {
                    let symbol = {
                        path: 'M 12 0 c -4.198 0 -8 3.403 -8 7.602 c 0 4.198 3.469 9.21 8 16.398 c 4.531 -7.188 8 -12.2 8 -16.398 c 0 -4.199 -3.801 -7.602 -8 -7.602 z z',
                        scale: 1.2,
                        strokeColor: '#18181b',
                        strokeWeight: 1,
                        fillColor: tour.color.main.hex,
                        fillOpacity: 1,
                        anchor: new google.maps.Point(10, 22),
                        labelOrigin: new google.maps.Point(12, 8),
                        labelInBackground: false,
                    }
                    let label = null
                    if (
                        marker.isInUserUniverses === false &&
                        index !== currentTourMarkers.length - 1
                    ) {
                        label = {
                            text: '\ue897',
                            fontFamily: 'Material Icons',
                            fontWeight: 'bold',
                            color: '#000000',
                            fontSize: '16px',
                        }
                        symbol = {
                            path: 'M 12 0 c -4.198 0 -8 3.403 -8 7.602 c 0 4.198 3.469 9.21 8 16.398 c 4.531 -7.188 8 -12.2 8 -16.398 c 0 -4.199 -3.801 -7.602 -8 -7.602 z z',
                            scale: 1.2,
                            strokeColor: '#18181b',
                            strokeWeight: 1,
                            fillColor: tour.color.main.hex,
                            fillOpacity: 1,
                            anchor: new google.maps.Point(10, 22),
                            labelOrigin: new google.maps.Point(12, 9),
                            labelInBackground: false,
                        }
                        // symbol = {
                        //     path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                        //     scale: 6,
                        //     strokeColor: '#18181b',
                        //     strokeWeight: 1,
                        //     fillColor: tour.color.main.hex,
                        //     fillOpacity: 1,
                        //     // anchor: new google.maps.Point(0, 20),
                        //     labelOrigin: new google.maps.Point(0, -2.4),
                        //     labelInBackground: false,
                        // }
                    }
                    const mark = new google.maps.Marker({
                        position: marker.pos,
                        title: marker.title,
                        icon: symbol,
                        label:
                            label !== null
                                ? label
                                : marker.label !== ''
                                  ? {
                                        text: marker.label,
                                        //   fontWeight: 'bold',
                                        fontSize: '9',
                                    }
                                  : '',
                        optimized: false,
                        animation: google.maps.Animation.DROP,
                        linkedSteps: marker.linkedSteps,
                        orderId: marker.orderId,
                        isInUserUniverses: marker.isInUserUniverses,

                        clickable:
                            index !== 0 &&
                            marker.executed === false &&
                            index !== currentTourMarkers.length - 1,
                    })
                    if (marker.executed === false) {
                        mark.setMap(this.map)
                    }
                    markers.push({ [tour.id]: mark })
                    if (
                        mark.isInUserUniverses === true &&
                        this.$route.meta.isTour === false &&
                        this.canAccess === true &&
                        index !== 0 &&
                        index !== currentTourMarkers.length - 1 &&
                        (marker.status === 'new' ||
                            marker.status === 'affected')
                    ) {
                        const eventScreen =
                            'ontouchstart' in document.documentElement
                                ? 'mousedown'
                                : 'click'

                        mark.addListener(eventScreen, (evt) => {
                            const clickedSymbol = {
                                path: 'M 12 0 c -4.198 0 -8 3.403 -8 7.602 c 0 4.198 3.469 9.21 8 16.398 c 4.531 -7.188 8 -12.2 8 -16.398 c 0 -4.199 -3.801 -7.602 -8 -7.602 z z',
                                scale: 1.2,
                                strokeColor: '#18181b',
                                strokeWeight: 1,
                                fillColor:
                                    colors[
                                        this.colorToUseForClickedMarkerIndex
                                    ],
                                fillOpacity: 1,
                                anchor: new google.maps.Point(10, 22),
                                labelOrigin: new google.maps.Point(12, 8),
                                labelInBackground: false,
                            }
                            // on indique quels markers appartiennent à la même commande
                            // en les changeant de couleur
                            mark.setIcon(clickedSymbol)

                            markers.forEach((markerArray) => {
                                if (
                                    markerArray[tour.id] &&
                                    markerArray[tour.id].orderId ===
                                        mark.orderId
                                ) {
                                    markerArray[tour.id].setIcon(clickedSymbol)
                                }
                            })

                            if (
                                this.colorToUseForClickedMarkerIndex ===
                                colors.length - 1
                            ) {
                                this.colorToUseForClickedMarkerIndex = 0
                            } else {
                                this.colorToUseForClickedMarkerIndex += 1
                            }

                            evt.domEvent.cancelBubble = true // stop event propagation
                            const mapDiv = document
                                .getElementById('map')
                                .getElementsByTagName('div')[0]
                            const markerDiv = document.createElement('div')
                            markerDiv.style.position = 'absolute'
                            markerDiv.style.left = `${
                                evt.domEvent.pageX +
                                markerDiv.offsetWidth +
                                (index * 10) / 3
                            }px`
                            markerDiv.style.top = `${
                                evt.domEvent.pageY -
                                markerDiv.offsetHeight -
                                (index * 10) / 7
                            }px`

                            markerDiv.style.backgroundColor =
                                tour.color.main.hex
                            markerDiv.style.zIndex = 1000
                            markerDiv.setAttribute(
                                'id',
                                `draggedElement-${marker.stepId}`,
                            )
                            markerDiv.setAttribute(
                                'draggable',
                                this.$route.meta.isTour === false &&
                                    this.canAccess === true,
                            )
                            markerDiv.classList.add(
                                'text-zinc-900',
                                'border-2',
                                'rounded',
                                'text-xs',
                                'h-fit',
                                'min-w-[10%]',
                                'w-[10%]',
                            )

                            if (
                                !this.draggedElements.find(
                                    (dragged) =>
                                        dragged.draggedItemId === marker.stepId,
                                )
                            ) {
                                this.draggedElements.push({
                                    draggedItemId: marker.stepId,
                                    draggedItemList: `steps-tour-${tour.id}`,
                                    draggedTourIndex:
                                        this.toursToShow.indexOf(tour),
                                    toRemove: true,
                                })
                                document.addEventListener('mousemove', (e) => {
                                    markerDiv.style.left = `${
                                        e.pageX +
                                        markerDiv.offsetWidth +
                                        (index * 10) / 3
                                    }px`
                                    markerDiv.style.top = `${
                                        e.pageY -
                                        markerDiv.offsetHeight -
                                        (index * 10) / 7
                                    }px`
                                })
                                mapDiv.appendChild(markerDiv)

                                const innerMarkerDiv =
                                    document.createElement('div')
                                innerMarkerDiv.setAttribute(
                                    'id',
                                    'draggedDivContent',
                                )

                                document
                                    .getElementById(
                                        `draggedElement-${marker.stepId}`,
                                    )
                                    .appendChild(innerMarkerDiv)
                                const newContent = document.createTextNode(
                                    `${marker.title} -  ${marker.address} ${
                                        marker.postalCode
                                    } ${marker.city} ${this.$t(
                                        'on',
                                    )} ${this.formatStepDate(
                                        marker.step,
                                    )} ${this.$t(marker.moment)} ${
                                        marker.moment === 'from' ||
                                        marker.moment === 'between'
                                            ? this.formatStepTime(
                                                  marker.fromTime,
                                              )
                                            : ' '
                                    } ${
                                        marker.moment === 'before' ||
                                        marker.moment === 'between'
                                            ? this.formatStepTime(
                                                  marker.beforeTime,
                                              )
                                            : ' '
                                    } - ${this.$t(
                                        'same_order_steps',
                                    )} :  ${marker.linkedSteps.join(', ')}`,
                                )
                                innerMarkerDiv.classList.add(
                                    'p-2',
                                    'text-zinc-900',
                                )
                                innerMarkerDiv.appendChild(newContent)
                            }
                        })
                    }
                })
            } else if (tour.show === false && fromButton === true) {
                if (shownIndex > -1) {
                    this.shownTours.splice(shownIndex, 1)
                }
                document.getElementById(`tour-${tour.id}`).style.borderColor =
                    ''

                markers.forEach((markerArray, index) => {
                    if (markerArray[tour.id]) {
                        markerArray[tour.id].setVisible(false)
                        markerArray[tour.id].setMap(null)
                        markers = markers.filter((mark) => mark !== markerArray)
                    }
                })
                if (polygons[tour.id]) {
                    polygons[tour.id].forEach((polygon, index) => {
                        if (polygon) {
                            polygon.setVisible(false)
                            polygon.setMap(null)

                            polygons[tour.id] = polygons[tour.id].filter(
                                (p) => p !== polygon,
                            )
                        }
                    })
                }

                this.toursToShow = this.toursToShow.filter(
                    (tourToShow) => tour !== tourToShow,
                )

                tour.open = false
                this.openPanel(tour)
            }
            this.toInnerIndex = null
            this.hoveredIndex = null
        },
        showUnaffectedSteps(fromButton = false) {
            if (fromButton === true) {
                this.showUnaffected = !this.showUnaffected
            }

            if (this.showUnaffected === true) {
                this.resetUnaffectedMarkers(true)

                this.toursToShow.push({
                    id: 'unaffected',
                    open: this.openStoreUnaffected,
                    show: this.showStoreUnaffected,

                    color: {
                        main: { bg: 'bg-[#9DBAD4]', hex: '#9DBAD4' },
                        secondary: { bg: 'bg-[#999999]', hex: '#999999' },
                    },
                })
                this.displayBottomBlock = true
                if (this.openStoreUnaffected === true) {
                    const unaffectedIndex = this.toursToShow.findIndex(
                        (tr) => tr.id === 'unaffected',
                    )

                    this.openPanel(this.toursToShow[unaffectedIndex])

                    // this.showUnaffectedSteps()
                }

                // this.setUnaffectedMarkers()

                // this.showUnaffectedSteps()

                // ajouter la carte
            } else {
                // const unafDetail = this.toursToShow.find(
                //     (tour) => tour.id === 'unaffected'
                // )
                // this.toursToShow.splice(this.toursToShow.indexOf(unafDetail), 1)
                this.resetUnaffectedMarkers()
            }
            this.openPanel(null)
        },
        startDrag(
            evt,
            stepId,
            tourId,
            tourIndex,
            orderId = null,
            stpIndex = null,
        ) {
            this.oldIndex = stpIndex
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            let incomplete = 'complete'
            evt.dataTransfer.setData('draggedTourId', tourId)
            this.tourOrigin = tourId
            // add event listener on mousemove to scroll custom-panel-2

            if (stpIndex !== null) {
                evt.dataTransfer.setData('draggedStpIndex', stpIndex)
            }

            if (tourId !== 'unaffected') {
                evt.dataTransfer.setData('draggedItemId', stepId)
                evt.dataTransfer.setData(
                    'draggedItemList',
                    `steps-tour-${tourId}`,
                )
                evt.dataTransfer.setData('draggedTourIndex', tourIndex)
                evt.dataTransfer.setData('draggedIncompleteStep', incomplete)
            } else {
                let idStep = null
                const stepsIds = []
                if (stepId.length) {
                    stepId.forEach((stp) => {
                        stepsIds.push(stp.Id)
                        if (stp.incomplete && stp.incomplete === true) {
                            incomplete = 'incomplete'
                        }
                    })
                    idStep = stepId[stepId.length - 1].Id
                } else {
                    stepsIds.push(stepId.Id)
                    idStep = stepId.Id

                    if (stepId.incomplete && stepId.incomplete === true) {
                        incomplete = 'incomplete'
                    }
                }
                evt.dataTransfer.setData('draggedItemStepsIds', stepsIds)
                evt.dataTransfer.setData('draggedItemId', idStep)
                evt.dataTransfer.setData('draggedIncompleteStep', incomplete)

                evt.dataTransfer.setData('draggedItemList', 'unaffected')
                evt.dataTransfer.setData('draggedItemOrderId', orderId)
            }
        },
        userDeleteConfirmation(confirmDelete) {
            this.confirmDelete = confirmDelete
            this.processing = confirmDelete
            this.showDeleteConfirmModal = false
        },
        deleteTour(tourId, tourIndex) {
            clearInterval(this.dailyInterval)
            this.confirmDelete = null
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_tour_header'
            this.deleteModalText = 'delete_tour_text'

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.processing = true

                        const isIntoursToShow = this.toursToShow.findIndex(
                            (tr) => tr.id === tourId,
                        )
                        if (isIntoursToShow !== -1) {
                            this.toursToShow[isIntoursToShow].show = false
                            this.toursToShow[isIntoursToShow].open = false
                            this.toursToShow.splice(isIntoursToShow, 1)
                        }

                        const deletedTourIsShown = this.shownTours.findIndex(
                            (el) => el === tourId,
                        )

                        if (deletedTourIsShown !== -1) {
                            this.shownTours.splice(deletedTourIsShown, 1)
                        }

                        const deletedTourIsOpen = this.openedTours.findIndex(
                            (el) => el === tourId,
                        )
                        if (deletedTourIsOpen !== -1) {
                            this.openedTours.splice(deletedTourIsOpen, 1)
                        }
                        const deletedTourIsShowFinished =
                            this.shownFinished.findIndex((el) => el === tourId)
                        if (deletedTourIsShowFinished !== -1) {
                            this.shownFinished.splice(
                                deletedTourIsShowFinished,
                                1,
                            )
                        }
                        const deletedTourIsShowTraces =
                            this.shownTraces.findIndex((el) => el === tourId)
                        if (deletedTourIsShowTraces !== -1) {
                            this.shownTrace.splice(deletedTourIsShowTraces, 1)
                        }

                        // this.refresh += 1
                        if (this.$route.meta.daily === false) {
                            this.deleteTourInSimulation(tourId).then(() => {
                                this.refetch()
                            })
                        } else {
                            this.deleteDaily(tourId).then(() => {
                                this.refetch()
                            })
                        }
                        this.confirmDelete = null
                    } else {
                        this.processing = false
                        this.confirmDelete = null
                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        changePosition(tour, stepId, position, index) {
            // setter le currentStep pour corriger
            this.processing = true

            const payload = {
                IdOrderStep: stepId,
                Position: position,
                ModificationUserId: this.userStore.current.id,
                IdTour: tour.id,
            }
            if (this.oldIndex !== position) {
                this.oldIndex = null
                this.toInnerIndex = null
                this.confirmTourOptiAndMove(payload, 'changeStepPositionInTour')
            }
        },
        async onDrop(evt, list, tourId, tourLength = null) {
            this.canReset = false
            // remove mouse event listener

            if (
                this.draggedElements.length === 0 &&
                tourId === +evt.dataTransfer.getData('draggedTourId')
            ) {
                const originalStpIndex =
                    +evt.dataTransfer.getData('draggedStpIndex')
                let newPosition = originalStpIndex

                if (this.toInnerIndex > originalStpIndex) {
                    newPosition += 2
                } else {
                    newPosition = this.toInnerIndex + 1
                }
                if (
                    this.toInnerIndex !== 0 &&
                    this.toInnerIndex !== tourLength
                ) {
                    this.changePosition(
                        { id: tourId },
                        +evt.dataTransfer.getData('draggedItemId'),
                        newPosition,
                    )
                } else {
                    this.processing = false
                    this.resetDraggedElements()
                }
            } else {
                //  boucler sur les draggedElements
                let draggedHasIncomplete = 'complete'
                if (this.draggedElements.length > 0) {
                    this.draggedElements.forEach((draggedEl) => {
                        if (draggedEl.incomplete === 'incomplete') {
                            draggedHasIncomplete = 'incomplete'
                        }
                    })
                }
                let callToUse = null
                const isIncomplete =
                    this.draggedElements.length === 0
                        ? evt.dataTransfer.getData('draggedIncompleteStep')
                        : draggedHasIncomplete
                if (isIncomplete === 'incomplete') {
                    this.$toast.info(this.$t('incomplete_step_not_affectable'))
                    this.processing = false
                } else {
                    this.processing = true
                    let payload = null
                    if (this.draggedElements.length === 0) {
                        const draggedItemList =
                            evt.dataTransfer.getData('draggedItemList')

                        if (draggedItemList !== list) {
                            const tourIdDrop = tourId

                            let draggedItemId =
                                evt.dataTransfer.getData('draggedItemId')
                            if (draggedItemList === 'unaffected') {
                                const stepsIds = evt.dataTransfer
                                    .getData('draggedItemStepsIds')
                                    .split(',')
                                draggedItemId = stepsIds[1]
                                    ? stepsIds[1]
                                    : stepsIds[0]

                                // TODO voir avec fred comment je peux gérer un step solo ?
                            }

                            payload = {
                                IdTourDestination: tourIdDrop,
                                IdOrderStepEnd: draggedItemId,
                                ModificationUserId: this.userStore.current.id,
                                // IdTour: this.current.Id,
                            }

                            if (this.$route.meta.daily === false) {
                                payload.IdSimulation = this.$route.params.id
                                callToUse = 'moveStepToOtherTour'
                            } else {
                                payload.IdTour = this.current.Id
                                callToUse = 'moveDailySteps'
                            }
                        }
                    } else if (this.draggedElements.length > 0) {
                        const tourIdDrop = tourId
                        const stepsToMove = []
                        for (
                            let index = 0;
                            index < this.draggedElements.length;
                            index += 1
                        ) {
                            const { draggedItemList } =
                                this.draggedElements[index]
                            if (draggedItemList !== list) {
                                let { draggedItemId } =
                                    this.draggedElements[index]
                                if (draggedItemList === 'unaffected') {
                                    draggedItemId = this.draggedElements[index]
                                        .draggedItemStepsIds[1]
                                        ? this.draggedElements[index]
                                              .draggedItemStepsIds[1]
                                        : this.draggedElements[index]
                                              .draggedItemStepsIds[0]
                                    // TODO voir avec fred comment je peux gérer un step solo ?
                                }
                                stepsToMove.push(draggedItemId)
                            }
                        }
                        payload = {
                            IdTourDestination: tourIdDrop,
                            IdOrderStepEnd: stepsToMove,
                            ModificationUserId: this.userStore.current.id,
                            // IdTour: this.current.Id,
                        }
                        if (this.$route.meta.daily === false) {
                            payload.IdSimulation = this.$route.params.id
                            callToUse = 'moveManyStepsToOtherTour'
                        } else {
                            payload.IdTour = this.current.Id
                            callToUse = 'moveManyDailySteps'
                        }
                    }
                    this.confirmTourOptiAndMove(payload, callToUse)

                    // else {
                    //     this.processing = false
                    // }
                }
            }
        },
        confirmTourOptiAndMove(
            payload,
            callToUse,
            acceptUnassigned = false,
            force = false,
        ) {
            this.processing = true
            payload.acceptUnassigned = acceptUnassigned
            payload.force = force
            this[callToUse](payload)
                .then(() => {
                    this.refetch()
                    this.resetDraggedElements()
                })
                .catch((err) => {
                    if (
                        err.response.status === 400 &&
                        err.response.data.message !==
                            'tour_opti_error_deliv_before_pickup'
                    ) {
                        this.processing = true
                        this.confirmAction = null
                        this.showConfirmTourOptiModal = true
                        this.confirmModalText = err.response.data.message
                        this.elementsToConfirm = []

                        const confirmInterval = setInterval(async () => {
                            if (this.confirmAction !== null) {
                                clearInterval(confirmInterval)
                                this.elementsToConfirm = []
                                if (this.confirmAction === true) {
                                    this.confirmAction = null
                                    acceptUnassigned =
                                        err.response.data.message ===
                                        'tour_opti_error_steps_not_assigned'
                                    force =
                                        err.response.data.message !==
                                        'tour_opti_error_steps_not_assigned'
                                    this.confirmTourOptiAndMove(
                                        payload,
                                        callToUse,
                                        acceptUnassigned,
                                        force,
                                    )
                                } else {
                                    this.confirmAction = null
                                    this.confirmModalTextOptiErrorInfo = ''
                                    this.confirmModalTextOptiError = ''
                                    this.processing = false
                                    this.resetDraggedElements()

                                    this.$toast.info(this.$t('action_canceled'))
                                }
                            }
                        }, 150)
                    } else {
                        this.processing = false
                        if (
                            err.response.data.message ===
                            'tour_opti_error_deliv_before_pickup'
                        ) {
                            this.$toast.info(
                                this.$t('tour_opti_error_deliv_before_pickup'),
                            )
                        } else {
                            this.$toast.error(this.$t('error_occured'))
                        }
                        this.oldIndex = null
                        this.toInnerIndex = null
                    }
                })
        },
        unaffectStep(stepId) {
            this.confirmDelete = null
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'unaffect_step_header'
            this.deleteModalText = 'unaffect_step_text'

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.processing = true

                        const payload = {
                            IdTourDestination: 0,
                            IdOrderStepEnd: stepId,
                            ModificationUserId: this.userStore.current.id,
                            // IdTour: this.current.Id,
                        }
                        if (this.$route.meta.daily === false) {
                            payload.IdSimulation = this.$route.params.id
                            this.moveStepToOtherTour(payload).then(() => {
                                this.refetch()
                            })
                        } else {
                            payload.IdTour = this.current.Id

                            this.moveDailySteps(payload).then(() => {
                                this.refetch()
                            })
                        }
                        this.confirmDelete = null
                    } else {
                        this.processing = false
                        this.confirmDelete = null
                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        resetDraggedElements() {
            if (this.draggedElements.length > 0) {
                this.draggedElements.forEach((draggedElement) => {
                    if (draggedElement.toRemove === true) {
                        document
                            .getElementById(
                                `draggedElement-${draggedElement.draggedItemId}`,
                            )
                            .remove()
                    }
                })
                this.canReset = true
                this.draggedElements = []

                // on remet les markers à la bonne couleur quand on arrete de drag un marker
                this.shownTours.forEach((tour) => {
                    const trDetail = this.toursToShow.find(
                        (tr) => tr.id === tour,
                    )
                    if (trDetail) {
                        markers.forEach((markerArray) => {
                            if (markerArray[tour]) {
                                let symbol = {
                                    path: 'M 12 0 c -4.198 0 -8 3.403 -8 7.602 c 0 4.198 3.469 9.21 8 16.398 c 4.531 -7.188 8 -12.2 8 -16.398 c 0 -4.199 -3.801 -7.602 -8 -7.602 z z',
                                    scale: 1.2,
                                    strokeColor: '#18181b',
                                    strokeWeight: 1,
                                    fillColor: trDetail.color.main.hex,
                                    fillOpacity: 1,
                                    anchor: new google.maps.Point(10, 22),
                                    labelOrigin: new google.maps.Point(12, 8),
                                    labelInBackground: false,
                                }
                                if (
                                    markerArray[tour].isInUserUniverses ===
                                    false
                                ) {
                                    const label = {
                                        text: '\ue897',
                                        fontFamily: 'Material Icons',
                                        fontWeight: 'bold',
                                        color: '#000000',
                                        fontSize: '16px',
                                    }
                                    symbol = {
                                        path: 'M 12 0 c -4.198 0 -8 3.403 -8 7.602 c 0 4.198 3.469 9.21 8 16.398 c 4.531 -7.188 8 -12.2 8 -16.398 c 0 -4.199 -3.801 -7.602 -8 -7.602 z z',
                                        scale: 1.2,
                                        strokeColor: '#18181b',
                                        strokeWeight: 1,
                                        fillColor: trDetail.color.main.hex,
                                        fillOpacity: 1,
                                        anchor: new google.maps.Point(10, 22),
                                        labelOrigin: new google.maps.Point(
                                            12,
                                            9,
                                        ),
                                        labelInBackground: false,
                                    }
                                    // symbol = {
                                    //     path: google.maps.SymbolPath
                                    //         .BACKWARD_CLOSED_ARROW,
                                    //     scale: 6,
                                    //     strokeColor: '#18181b',
                                    //     strokeWeight: 1,
                                    //     fillColor: trDetail.color.main.hex,
                                    //     fillOpacity: 1,
                                    //     // anchor: new google.maps.Point(0, 20),
                                    //     labelOrigin: new google.maps.Point(
                                    //         0,
                                    //         -2.4
                                    //     ),
                                    //     labelInBackground: false,
                                    // }
                                    markerArray[tour].setLabel(label)
                                }
                                markerArray[tour].setIcon(symbol)
                            }
                        })
                    }
                })
                this.processing = false
            }
            this.oldIndex = null
            this.toInnerIndex = null
        },
        openPanel(panel) {
            // mettre dans le tableau opendTours
            if (panel !== null) {
                // const traceDate = now.replaceAll('-', '')
                const openedIndex = this.openedTours.findIndex(
                    (tour) => tour === panel.id,
                )
                if (panel.open === true) {
                    if (openedIndex === -1) {
                        this.openedTours.push(panel.id)
                    }
                    const styleInt = setInterval(() => {
                        if (document.getElementById(`panel-${panel.id}`)) {
                            clearInterval(styleInt)

                            document.getElementById(
                                `panel-${panel.id}`,
                            ).style.borderColor = panel.color.main.hex
                            document
                                .getElementById(`panel-${panel.id}`)
                                .classList.remove('hidden')
                        }
                    }, 10)
                } else {
                    if (openedIndex > -1) {
                        this.openedTours.splice(openedIndex, 1)
                    }
                    const styleInt = setInterval(() => {
                        if (document.getElementById(`panel-${panel.id}`)) {
                            clearInterval(styleInt)
                            document.getElementById(
                                `panel-${panel.id}`,
                            ).style.borderColor = 'unset'
                            document
                                .getElementById(`panel-${panel.id}`)
                                .classList.add('hidden')
                        }
                    }, 10)
                }
            } else if (this.showUnaffected === true) {
                const styleInt = setInterval(() => {
                    if (document.getElementById(`panel-unaffected`)) {
                        clearInterval(styleInt)

                        document.getElementById(
                            `panel-unaffected`,
                        ).style.borderColor = '#9DBAD4'
                        document
                            .getElementById(`panel-unaffected`)
                            .classList.remove('hidden')
                    }
                }, 10)
            } else {
                const styleInt = setInterval(() => {
                    if (document.getElementById(`panel-unaffected`)) {
                        clearInterval(styleInt)
                        document.getElementById(
                            `panel-unaffected`,
                        ).style.borderColor = 'unset'
                        document
                            .getElementById(`panel-unaffected`)
                            .classList.add('hidden')
                    }
                }, 10)
            }
        },
        refetch() {
            this.confirmAction = null
            this.confirmModalTextOptiErrorInfo = ''
            this.confirmModalTextOptiError = ''
            // const openedTours = []
            // const this.shownTours = []

            markers.forEach((markerArray) => {
                this.tourCards.forEach((trCrd) => {
                    if (markerArray[trCrd.id] && trCrd.id !== 'unaffected') {
                        markerArray[trCrd.id].setVisible(false)
                        markerArray[trCrd.id].setMap(this.map)
                        markerArray[trCrd.id].setMap(null)
                        markers = markers.filter((mark) => mark !== markerArray)
                    }
                })
            })
            this.tourCards.forEach((trCrd) => {
                if (polygons[trCrd.id] && trCrd.id !== 'unaffected') {
                    polygons[trCrd.id].forEach((polygon) => {
                        if (polygon) {
                            polygon.setVisible(false)
                            // polygon.setMap(this.map)
                            polygon.setMap(null)
                            polygons[trCrd.id] = polygons[trCrd.id].filter(
                                (p) => p !== polygon,
                            )
                        }
                    })
                }
            })
            // this.toursToShow.forEach((tourToShow) => {
            //     this.shownTours.push(tourToShow.id)
            //     if (tourToShow.open === true) {
            //         this.openedTours.push(tourToShow.id)
            //     }
            // })

            this.resetUnaffectedMarkers()
            if (this.$route.name === 'tour_view') {
                this.fetchOneTour({
                    id: this.$route.params.id,
                    openedTours: this.openedTours,
                    shownTours: this.shownTours,
                    filter: this.universeFilter,
                }).then(() => {
                    this.toursToShow = []
                    this.shownTours = []
                    this.openedTours = []
                    this.tourCards.forEach((card) => {
                        if (card.id !== 'unaffected') {
                            this.showTour(card, false, true)

                            this.openPanel(card)
                        }
                    })
                    if (this.showUnaffected === true) {
                        this.showUnaffectedSteps(false)
                    }
                    this.processing = false
                    this.refresh += 1
                })
            } else if (this.$route.name === 'simulation_view') {
                this.fetchOneSimulation({
                    id: this.$route.params.id,
                    openedTours: this.openedTours,
                    shownTours: this.shownTours,
                    filter: this.universeFilter,
                }).then(() => {
                    this.toursToShow = []
                    this.shownTours = []
                    this.openedTours = []
                    this.tourCards.forEach((card) => {
                        if (card.id !== 'unaffected') {
                            this.showTour(card, false, true)

                            this.openPanel(card)
                        }
                    })
                    if (this.showUnaffected === true) {
                        this.showUnaffectedSteps(false)
                    }
                    this.processing = false
                    this.refresh += 1
                })
            } else {
                clearInterval(this.dailyInterval)
                this.fetchDaily({
                    openedTours: this.openedTours,
                    shownTours: this.shownTours,
                    shownFinished: this.shownFinished,
                    shownTraces: this.shownTraces,
                    filter: this.universeFilter,
                }).then(() => {
                    this.toursToShow = []
                    this.shownTours = []
                    this.openedTours = []
                    this.tourCards.forEach((card) => {
                        if (card.id !== 'unaffected') {
                            this.showTour(card, false, true)

                            this.openPanel(card)
                        }
                    })

                    if (this.showUnaffected === true) {
                        this.showUnaffectedSteps(false)
                    }
                    this.processing = false

                    this.refresh += 1
                    this.dailyInterval = setInterval(
                        () => {
                            this.fetchDaily({
                                openedTours: this.openedTours,
                                shownTours: this.shownTours,
                                shownFinished: this.shownFinished,
                                shownTraces: this.shownTraces,
                                filter: this.universeFilter,
                            })
                        },
                        2 * 60 * 1000,
                    )
                })
            }
        },
        refreshMap() {
            this.refresh += 1
        },
        radioStyle(id, selected) {
            const displayIntervel = setInterval(() => {
                if (document.getElementById(id)) {
                    clearInterval(displayIntervel)
                    const el = document.getElementById(id)

                    if (this.timeDetermination === selected) {
                        el.style.backgroundColor = `${this.tourToUpdateForm.color.main.hex}`
                    } else {
                        el.style.backgroundColor = ''
                    }
                }
            }, 10)
        },
        async updateStp(recalculate = false) {
            const isFormCorrect = await this.v$.stepToUpdateForm.$validate()
            this.badTimeRange = false
            if (
                (this.stepToUpdateForm.fromTime >
                    this.stepToUpdateForm.beforeTime ||
                    this.stepToUpdateForm.fromTime ===
                        this.stepToUpdateForm.beforeTime) &&
                this.stepToUpdateForm.Moment === 'between'
            ) {
                this.badTimeRange = true
            }
            if (isFormCorrect === true && this.badTimeRange === false) {
                this.stepToUpdateForm.AddressName = this.stepToUpdateForm.Name
                this.stepToUpdateForm.FromDateTime = `${this.stepToUpdateForm.date} ${this.stepToUpdateForm.fromTime}`
                this.stepToUpdateForm.BeforeDateTime = `${this.stepToUpdateForm.date} ${this.stepToUpdateForm.beforeTime}`

                const params = {
                    id: this.stepToUpdateForm.Id,
                    form: this.stepToUpdateForm,
                }
                try {
                    const response = await this.updateStep(params)
                    if (response.status === 200) {
                        this.$toast.success(this.$t('address_update_ok'))
                        this.editAddressModal = false
                        // mettre le relaunch avec un check de l'état du relaunch + indicateur recalcul en cours
                        if (recalculate === true) {
                            this.calculationInProgress = true
                            markers.forEach((markerArray) => {
                                this.tourCards.forEach((trCrd) => {
                                    if (
                                        markerArray[trCrd.id] &&
                                        trCrd.id !== 'unaffected'
                                    ) {
                                        markerArray[trCrd.id].setVisible(false)
                                        // markerArray[trCrd.id].setMap(this.map)
                                        markerArray[trCrd.id].setMap(null)
                                        markers = markers.filter(
                                            (mark) => mark !== markerArray,
                                        )
                                    }
                                })
                            })
                            this.tourCards.forEach((trCrd) => {
                                if (
                                    polygons[trCrd.id] &&
                                    trCrd.id !== 'unaffected'
                                ) {
                                    polygons[trCrd.id].forEach((polygon) => {
                                        if (polygon) {
                                            polygon.setVisible(false)
                                            // polygon.setMap(this.map)
                                            polygon.setMap(null)
                                            polygons[trCrd.id] = polygons[
                                                trCrd.id
                                            ].filter((p) => p !== polygon)
                                        }
                                    })
                                }
                            })
                            this.toursToShow = []
                            this.displayTopBlock = false

                            this.relaunch(this.$route.params.id)
                                .then((res) => {
                                    this.$toast.success(
                                        this.$t('simulation_generation_ok'),
                                    )
                                    const interval = setInterval(() => {
                                        this.fetchOneSimulation({
                                            id: this.$route.params.id,
                                            filter: this.universeFilter,
                                        }).then((fetchRes) => {
                                            if (
                                                fetchRes.data.Status ===
                                                'finished'
                                            ) {
                                                clearInterval(interval)
                                                this.displayTopBlock = true

                                                this.calculationInProgress = false
                                            }
                                        })
                                    }, 4000)
                                })
                                .catch((err) => {
                                    this.$toast.error(
                                        this.$t('simulation_generation_ko'),
                                    )
                                })
                        } else {
                            this.refetch()
                        }
                    } else {
                        this.processing = false

                        this.$toast.error(this.$t('address_update_ko'))
                    }
                } catch (err) {
                    this.processing = false

                    this.$toast.error(`${this.$t('address_update_ko')}`)
                    // this.$toast.error(err.data.message)
                }
            }
        },
        fillInAddress(autocomplete) {
            const addressToFillIn = this.stepToUpdateForm

            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            addressToFillIn.Address2 = ''
            addressToFillIn.PostalCode = ''
            addressToFillIn.City = ''

            const userInput = addressToFillIn.Address1
            addressToFillIn.Latitude = place.geometry.location.lat()
            addressToFillIn.Longitude = place.geometry.location.lng()
            addressToFillIn.Address1 = ''
            // console.log(userInput)
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    addressToFillIn.PostalCode = val
                } else if (addressType === 'locality') {
                    addressToFillIn.City = val
                } else if (addressType === 'street_number') {
                    addressToFillIn.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    addressToFillIn.Address1 =
                        addressToFillIn.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${addressToFillIn.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    addressToFillIn.Address2 = `${val}`
                }

                // TODO voir ce que ça casse dans la génération des adresses rm le 4 nov
                // console.log(place.types[0] )

                //                 console.log(val)
                //                 console.log(addressType)
                //                 console.log('------------------------')
                // if (
                //     (place.types[0] !== 'street_number' ||
                //         place.types[0] !== 'route') &&
                //     addressType === 'locality' &&
                //     addressToFillIn.Address1 === userInput
                // ) {
                //     addressToFillIn.Address1 = ` ${val}`
                // }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    addressToFillIn.Address2 === '' &&
                    place.types[0] !== 'premise'
                ) {
                    if (addressToFillIn.Address1 === '') {
                        addressToFillIn.Address1 = place.name
                    } else {
                        addressToFillIn.Address2 = place.name
                    }
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    addressToFillIn.Name === ''
                ) {
                    // if (!modal) {
                    addressToFillIn.Name = place.name
                    // }
                }
                // if (
                //     place.types[0] !== 'street_address' &&
                //     place.types[0] !== 'route' &&
                //     place.types[0] !== 'locality' &&
                //     place.types[0] !== 'premise' &&
                //     addressToFillIn.AddressName === ''
                // ) {
                //     if (modal) {
                //         addressToFillIn.AddressName = place.name
                //     }
                // }
            })
        },
    },
    unmounted() {
        this.eventBus.all.clear()
        clearInterval(this.driverPosInt)
        clearInterval(this.dailyInterval)

        this.currentTour = null
        this.calendarCards = null
        this.calendarUnaffected = null
        this.countCalendarUnaffected = null
        this.daily = null
        this.dailyCards = null
        this.dailyUnaffected = null
        this.countDailyUnaffected = null
        this.currentSim = null
        this.simCards = null
        this.simUnaffected = null
        this.countSimUnaffected = null
    },
}
</script>

<style lang="scss" scoped>
#wrapper {
    position: relative;
    overflow: hidden;
}

.tour-opti {
    position: relative;
    top: 10px;
    z-index: 24;
}
.over-map {
    position: relative;
    top: 10px;
    z-index: 24;
    background-color: rgba(7, 2, 2, 0.5);
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.light-over-map {
    position: relative;
    top: 10px;
    z-index: 24;
    height: 75%;
    background-color: unset !important;
    /* Hide scrollbar for IE, Edge and Firefox */
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
}
.light-in-over-map {
    z-index: 25;
    // opacity: 0.2 !important;
    height: auto;

    background-color: rgba(236, 240, 241, 0.95) !important;
    /* Hide scrollbar for IE, Edge and Firefox */
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
}
.in-over-map {
    z-index: 25;
    // opacity: 0.2 !important;
    background-color: rgba(7, 2, 2, 0.75) !important;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
#map {
    width: 100%;
    // position: relative;
    overflow: hidden;
}
#map ::v-deep(.gm-style button) {
    border: 2 !important;
    opacity: 0.5 !important;
    cursor: pointer;
    background-color: black !important;
    color: rgba(161, 161, 170, 0.5) !important;
}
#map ::v-deep(.gm-style .gmnoprint) {
    margin: unset;
    margin-left: 1.25rem !important;
}
#map ::v-deep(.gm-svpc) {
    opacity: 0.5 !important;
    background-color: black !important;
}
#map ::v-deep(ul) {
    opacity: 0.5 !important;
    background-color: black !important;
}
#map ::v-deep(li) {
    background-color: black !important;

    color: rgba(161, 161, 170, 1) !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.over-map::-webkit-scrollbar {
    display: none;
}
.in-over-map::-webkit-scrollbar {
    display: none;
}

// .light-over-map::-webkit-scrollbar {
//     height: 24px;
//     width:20px;
// }
.ms-width ::v-deep(.multiselect) {
    // height: auto;
    // width: 18rem;
    border-radius: unset;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.input-custom-round {
    border-radius: unset;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
</style>

const actionsAccess = {
    areas: {
        update: ['AREAS_UPDATE', 'AREAS_FULL', 'FULL_ACCESS'],
        activate: [
            'AREAS_ACTIVATE',
            'AREAS_FULL',
            'FULL_ACCESS',
            'AREAS_UPDATE',
        ],
        create: ['AREAS_CREATE', 'AREAS_FULL', 'FULL_ACCESS'],
    },
    billings: {
        update: ['BILLINGS_UPDATE', 'BILLINGS_FULL', 'FULL_ACCESS'],
        delete: ['BILLINGS_DELETE', 'BILLINGS_FULL', 'FULL_ACCESS'],
        create: ['BILLINGS_CREATE', 'BILLINGS_FULL', 'FULL_ACCESS'],
    },
    clients: {
        update: ['CLIENTS_UPDATE', 'CLIENTS_FULL', 'FULL_ACCESS'],
        delete: ['CLIENTS_DELETE', 'CLIENTS_FULL', 'FULL_ACCESS'],
        create: ['CLIENTS_CREATE', 'CLIENTS_FULL', 'FULL_ACCESS'],
        contacts: {
            list: ['CONTACTS_LIST', 'CONTACTS_FULL', 'FULL_ACCESS'],
            create: ['CONTACTS_CREATE', 'CONTACTS_FULL', 'FULL_ACCESS'],
            update: ['CONTACTS_UPDATE', 'CONTACTS_FULL', 'FULL_ACCESS'],
            delete: ['CONTACTS_DELETE', 'CONTACTS_FULL', 'FULL_ACCESS'],
        },
        contracts: {
            list: [
                'CONTRACT_LIST',
                'CLIENTS_FULL',
                'CONTRACT_FULL',
                'FULL_ACCESS',
            ],
            create: ['CONTRACT_CREATE', 'CONTRACT_FULL', 'FULL_ACCESS'],
            update: ['CONTRACT_UPDATE', 'CONTRACT_FULL', 'FULL_ACCESS'],
            delete: ['CONTRACT_DELETE', 'CONTRACT_FULL', 'FULL_ACCESS'],
        },
        addresses: {
            list: [
                'DELIVERY-ADDRESSES_LIST',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
            create: [
                'DELIVERY-ADDRESSES_CREATE',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'DELIVERY-ADDRESSES_UPDATE',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'DELIVERY-ADDRESSES_DELETE',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
        },
        contactClientHistory: {
            list: [
                'CONTACT-CLIENT-HISTORY_FULL',
                'CONTACT-CLIENT-HISTORY_LIST',
                'FULL_ACCESS',
            ],
            create: [
                'CONTACT-CLIENT-HISTORY_CREATE',
                'CONTACT-CLIENT-HISTORY_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'CONTACT-CLIENT-HISTORY_UPDATE',
                'CONTACT-CLIENT-HISTORY_FULL',
                'FULL_ACCESS',
            ],
            archive: [
                'CONTACT-CLIENT-HISTORY_ARCHIVE',
                'CONTACT-CLIENT-HISTORY_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'CONTACT-CLIENT-HISTORY_DELETE',
                'CONTACT-CLIENT-HISTORY_FULL',
                'FULL_ACCESS',
            ],
        },
    },
    dashboard: {
        operator: ['DASHBOARD_OPERATOR', 'FULL_ACCESS'],
        client: ['DASHBOARD_CLIENT', 'FULL_ACCESS'],
    },
    roles: {
        update: ['ROLES_UPDATE', 'ROLES_FULL', 'FULL_ACCESS'],
        delete: ['ROLES_DELETE', 'ROLES_FULL', 'FULL_ACCESS'],
        create: ['ROLES_CREATE', 'ROLES_FULL', 'FULL_ACCESS'],
        assignAuthToRole: ['ROLES_ADDAUTH', 'ROLES_FULL', 'FULL_ACCESS'],
    },
    universes: {
        list: ['UNIVERSES_LIST', 'UNIVERSES_FULL', 'USERS_FULL', 'FULL_ACCESS'],
        create: [
            'UNIVERSES_CREATE',
            'UNIVERSES_FULL',
            'USERS_FULL',
            'FULL_ACCESS',
        ],
        update: [
            'UNIVERSES_UPDATE',
            'UNIVERSES_FULL',
            'USERS_FULL',
            'FULL_ACCESS',
        ],
        delete: [
            'UNIVERSES_DELETE',
            'UNIVERSES_FULL',
            'USERS_FULL',
            'FULL_ACCESS',
        ],
    },
    users: {
        update: ['USERS_UPDATE', 'USERS_FULL', 'FULL_ACCESS'],
        delete: ['USERS_DELETE', 'USERS_FULL', 'FULL_ACCESS'],
        create: ['USERS_CREATE', 'USERS_FULL', 'FULL_ACCESS'],
        assignRole: ['ROLES_ASSIGN', 'USERS_FULL', 'FULL_ACCESS'],
        assignUniverse: ['UNIVERSES_ASSIGN', 'USERS_FULL', 'FULL_ACCESS'],

        resetPass: ['USERS_SENDRPLINK', 'USERS_FULL', 'FULL_ACCESS'],
    },
    drivers: {
        list: ['DRIVERS_LIST', 'DRIVERS_FULL', 'FULL_ACCESS'],
        create: ['DRIVERS_CREATE', 'DRIVERS_FULL', 'FULL_ACCESS'],
        update: ['DRIVERS_UPDATE', 'DRIVERS_FULL', 'FULL_ACCESS'],
        // delete: ['DRIVERS_DELETE', 'DRIVERS_FULL', 'FULL_ACCESS'],

        activate: [
            'DRIVERS_ACTIVATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
            'DRIVERS_UPDATE',
        ],
    },
    driverPlanning: {
        read: [
            'PLANNING-BASE_READ',
            'DRIVERS_FULL',
            'FULL_ACCESS',
            'WEEKLY_FULL',
        ],
        update: [
            'PLANNING-BASE_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
            'WEEKLY_FULL',
        ],
    },
    weeklyPlanning: {
        read: ['WEEKLY_READ', 'WEEKLY_FULL', 'DRIVERS_FULL', 'FULL_ACCESS'],
        create: [
            'WEEKLY_CREATEACTIVITY',
            'WEEKLY_FULL',
            'DRIVERS_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
        ],
        updateActivity: [
            'WEEKLY_UPDATEACTIVITY',
            'WEEKLY_FULL',
            'DRIVERS_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
        ],
        update: [
            'WEEKLY_UPDATE',
            'WEEKLY_FULL',
            'DRIVERS_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
        ],
    },
    suppliers: {
        list: ['SUPPLIERS_LIST', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
        create: ['SUPPLIERS_CREATE', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
        update: ['SUPPLIERS_UPDATE', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
        delete: ['SUPPLIERS_DELETE', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
    },
    vehicles: {
        list: ['VEHICLES_LIST', 'VEHICLES_FULL', 'FULL_ACCESS'],
        create: ['VEHICLES_CREATE', 'VEHICLES_FULL', 'FULL_ACCESS'],
        update: ['VEHICLES_UPDATE', 'VEHICLES_FULL', 'FULL_ACCESS'],
        // delete: ['VEHICLES_DELETE', 'VEHICLES_FULL', 'FULL_ACCESS'],
        activate: [
            'VEHICLES_ACTIVATE',
            'VEHICLES_FULL',
            'FULL_ACCESS',
            'VEHICLES_UPDATE',
        ],
        maintenances: {
            list: [
                'VEHICLES_FULL',
                'FULL_ACCESS',
                'VEHICLES_MAINTENANCES_LIST',
                'VEHICLES_MAINTENANCES_FULL',
            ],
            create: [
                'VEHICLES_MAINTENANCES_CREATE',
                'VEHICLES_MAINTENANCES_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'VEHICLES_MAINTENANCES_UPDATE',
                'VEHICLES_MAINTENANCES_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'VEHICLES_MAINTENANCES_DELETE',
                'VEHICLES_MAINTENANCES_FULL',
                'FULL_ACCESS',
            ],
        },
    },
    roundTripOrder: ['O_ROUNDTRIP_ALLOWED', 'FULL_ACCESS'],
    roundTripRec: ['REC_ROUNDTRIP_ALLOWED', 'FULL_ACCESS'],
    orders: {
        livePrice: ['ORDERS_LIVEPRICE', 'FULL_ACCESS'],
        updatePrice: ['ORDERS_UPDATEPRICE', 'FULL_ACCESS'],
        forcePrice: ['ORDERS_FORCEPRICE', 'FULL_ACCESS'],
        orderPricing: ['ORDERS_PRICINGSCENARIO', 'FULL_ACCESS'],
        tourPricing: ['ORDERS_TOURPRICINGSCENARIO', 'FULL_ACCESS'],
        createMultipacks: ['PACKAGES_CREATE_MULTIPACKS', 'FULL_ACCESS'],
        deleteMultipacks: ['PACKAGES_DELETE_MULTIPACKS', 'FULL_ACCESS'],
        list: ['ORDERS_LIST', 'ORDERS_FULL', 'FULL_ACCESS'],
        create: ['ORDERS_CREATE', 'ORDERS_FULL', 'FULL_ACCESS'],
        update: ['ORDERS_UPDATE', 'ORDERS_FULL', 'FULL_ACCESS'],
        updateStatus: ['ORDERS_STEPSSTATUS', 'ORDERS_FULL', 'FULL_ACCESS'],
        assign: {
            driver: ['ORDERS_ASSIGNDRIVER', 'ORDERS_FULL', 'FULL_ACCESS'],
            vehicle: ['ORDERS_ASSIGNVEHICLE', 'ORDERS_FULL', 'FULL_ACCESS'],
        },
        refunds: {
            list: ['REFUNDS_LIST', 'REFUNDS_FULL', 'FULL_ACCESS'],
            create: ['REFUNDS_CREATE', 'REFUNDS_FULL', 'FULL_ACCESS'],
            validate: ['REFUNDS_VALIDATE', 'REFUNDS_FULL', 'FULL_ACCESS'],
            cancel: ['REFUNDS_CANCEL', 'REFUNDS_FULL', 'FULL_ACCESS'],
        },
        webPayment: ['ORDERS_WEB_PAYMENT', 'FULL_ACCESS'],
        delete: ['ORDERS_DELETE', 'ORDERS_FULL', 'FULL_ACCESS'],
        signatures: ['ORDERS_SIGNATURES', 'ORDERS_FULL', 'FULL_ACCESS'],
        trackings: ['ORDERS_TRACKINGS', 'ORDERS_FULL', 'FULL_ACCESS'],
        imports: {
            list: ['ORDERS-IMPORT_LIST', 'ORDERS-IMPORT_FULL', 'FULL_ACCESS'],
            create: [
                'ORDERS-IMPORT_CREATE',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'ORDERS-IMPORT_DELETE',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
            download: [
                'ORDERS-IMPORT_DOWNLOAD',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
        },
        containers: {
            list: ['PACKAGES_LIST', 'PACKAGES_FULL', 'FULL_ACCESS'],
            create: ['PACKAGES_CREATE', 'PACKAGES_FULL', 'FULL_ACCESS'],
            update: ['PACKAGES_UPDATE', 'PACKAGES_FULL', 'FULL_ACCESS'],
            activate: [
                'PACKAGES_ACTIVATE',
                'PACKAGES_FULL',
                'FULL_ACCESS',
                'PACKAGES_UPDATE',
            ],
            updateDefault: [
                'PACKAGES_UPDATE_DEFAULT',
                'PACKAGES_FULL',
                'FULL_ACCESS',
            ],
        },
        recurrents: {
            list: ['RECURRENTS_LIST', 'RECURRENTS_FULL', 'FULL_ACCESS'],
            create: ['RECURRENTS_CREATE', 'RECURRENTS_FULL', 'FULL_ACCESS'],
            update: ['RECURRENTS_UPDATE', 'RECURRENTS_FULL', 'FULL_ACCESS'],
            delete: ['RECURRENTS_DELETE', 'RECURRENTS_FULL', 'FULL_ACCESS'],

            activate: [
                'RECURRENTS_ACTIVATE',
                'RECURRENTS_FULL',
                'FULL_ACCESS',
                'RECURRENTS_UPDATE',
            ],
        },
        unpaid: {
            list: ['UNPAID_LIST', 'UNPAID_FULL', 'FULL_ACCESS'],
            update: ['UNPAID_UPDATE', 'UNPAID_FULL', 'FULL_ACCESS'],
            delete: ['UNPAID_DELETE', 'UNPAID_FULL', 'FULL_ACCESS'],
            sendMail: ['UNPAID_SENDMAIL', 'UNPAID_FULL', 'FULL_ACCESS'],
        },
        sales: {
            view: [
                'FULL_ACCESS',
                'SALES_FULL',
                'SALES_CREATE',
                'SALES_UPDATE',
                'SALES_DELETE',
            ],
            create: ['FULL_ACCESS', 'SALES_FULL', 'SALES_CREATE'],
            delete: ['SALES_DELETE', 'SALES_FULL', 'FULL_ACCESS'],
            update: ['SALES_UPDATE', 'SALES_FULL', 'FULL_ACCESS'],
        },
        groups:  {
            list: ['ORDERGROUPS_LIST', 'ORDERGROUPS_FULL', 'FULL_ACCESS'],
            create: ['ORDERGROUPS_CREATE', 'ORDERGROUPS_FULL', 'FULL_ACCESS'],
            update: ['ORDERGROUPS_UPDATE', 'ORDERGROUPS_FULL', 'FULL_ACCESS'],
            delete: ['ORDERGROUPS_DELETE', 'ORDERGROUPS_FULL', 'FULL_ACCESS'],
        },
    },
    settings: {
        orders: ['SETTINGS_ORDERSCODES', 'FULL_ACCESS', 'SETTINGS_FULL'],

        notifications: [
            'SETTINGS_NOTIFICATIONS',
            'FULL_ACCESS',
            'SETTINGS_FULL',
        ],

        billings: ['SETTINGS_BILLINGS', 'FULL_ACCESS', 'SETTINGS_FULL'],
    },
    tours: {
        map: ['TOURS_MAPVIEW', 'FULL_ACCESS', 'TOURS_FULL'],
        create: ['TOURS_CREATE', 'FULL_ACCESS', 'TOURS_FULL'],
        delete: ['TOURS_DELETE', 'FULL_ACCESS', 'TOURS_FULL'],
    },
    simulations: {
        map: [
            'SIMULATIONS_MAPVIEW',
            'ORDERS_FULL',
            'FULL_ACCESS',
            'SIMULATIONS_FULL',
        ],
        update: [
            'ORDERS_FULL',
            'ORDERS_UPDATE',
            'FULL_ACCESS',
            'SIMULATIONS_FULL',
        ],
        create: ['SIMULATIONS_GENERATE', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
        delete: ['SIMULATIONS_DELETE', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
        integrate: ['SIMULATIONS_INTEGRATE', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
    },
    daily: {
        map: ['DAILY_MAPVIEW', 'ORDERS_FULL', 'FULL_ACCESS', 'DAILY_FULL'],
        update: ['DAILY_FULL', 'ORDERS_FULL', 'ORDERS_UPDATE', 'FULL_ACCESS'],
    },
}

export default actionsAccess
